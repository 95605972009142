import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import TextInput from './text-input';
import ValidateMessage from './validate-msg';
import CommonHelper from '../../helpers/common';

function InputTextUnit(props) {
    const { onChange, onBlur, value, name, label, required, errorMsg, ...other } = props;
    const [state, setState] = useState(value);
    const [{ msg, isShow }, setSelected] = useState({
        msg: "required",
        isShow: other.isError
    })
    const handle_change = (e) => {
        const { name, value } = e.target;
        setState(value);
        onChange(e);
    }
    const handle_blur = (e) => {
        const { name, value } = e.target;
        if (onBlur != undefined) {
            onBlur(e);
        }
    }
    useEffect(() => {
        var flag = other.isError
        var msg = "required";
        if (errorMsg != undefined && CommonHelper.is_not_empty(errorMsg)) {
            msg = errorMsg;
        }
        setSelected((prev) => ({
            ...prev,
            isShow: flag,
            msg
        }))
    }, [other.isError])
    useEffect(() => {
        setState(value);
    }, [value])
    return (
        <>
            <label className={'block font-semibold ' + (required ? " required" : "")}>{label}</label>
            <TextInput name={name} value={state} onChange={handle_change} onBlur={handle_blur}
                className={(isShow ? "border-red-600" : "")} disabled={other.disabled} placeholder={other.placeholder} />
            {required && (
                <ValidateMessage msgType={msg} isShow={isShow} />
            )}
        </>
    )
}

InputTextUnit.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
}

export default InputTextUnit
