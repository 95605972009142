import React from 'react'
import PropTypes from 'prop-types'

const FloorPlan = () => {
  return (
      <div className='w-full p-4'>
          <iframe src='https://vme.hawaexpo.com/floorplan/8' style={{ height: "750px", width: "100%" }}></iframe>
      </div>
  )
}

FloorPlan.propTypes = {}

export default FloorPlan