import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Dialog, Transition } from '@headlessui/react'

function VisitorDeleteConfirmation(props) {
    const { data, onClose, isShow, ...other } = props
    const [state, setState] = useState(null);
    let [isOpen, setIsOpen] = useState(true)
    useEffect(() => {
        console.log(data)
        setIsOpen(isShow)
        if (isShow) {
            setState(data);
        } else {
            setState(null);
        }
    }, [isShow])
    const handle_close = (flag) => {
        onClose(flag)
    }
    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => { handle_close(false) }}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        Xác nhận
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            {state != null && (
                                                <>Bạn chắc chắn muốn xòa người đi cùng?</>
                                            )}

                                        </p>
                                    </div>

                                    <div className="mt-4">
                                        <button type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent text-black bg-red-300 px-4 py-2 text-sm font-medium  hover:bg-red-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                                            onClick={() => { handle_close(true) }}
                                        >
                                            Đồng ý
                                        </button>
                                        <button type="button"
                                            className="inline-flex ml-5 justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={() => { handle_close(false) }}
                                        >
                                            Hủy
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
        // <Dialog open={isOpen} onClose={() => handle_close(false)}>
        //     <Dialog.Panel>
        //         <Dialog.Title>Confirmation</Dialog.Title>
        //         <Dialog.Description>
        //             {state != null && (
        //                 <p>
        //                     Do you want to delete exhibitor (<b>{state.companyNameEn}</b>)
        //                 </p>
        //             )}
        //         </Dialog.Description>
        //         <button onClick={() => handle_close(true)}>Agree</button>
        //         <button onClick={() => handle_close(false)}>Cancel</button>
        //     </Dialog.Panel>
        // </Dialog>
    )
}

VisitorDeleteConfirmation.propTypes = {}

export default VisitorDeleteConfirmation
