import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

const initial = {
    curr_page: 1,
    page_size: 10,
    total_records: 0,
    total_pages: 0,
};

const PageList = (props) => {
    const { total_pages, curr_page, onGoTo, ...other } = props;
    if (total_pages > 0) {
        var arr = [];
        for (var i = 1; i <= total_pages; i++) {
            arr.push(i);
        }
        const lst = arr.map((item, i) => {
            return (
                <>
                    {item == curr_page && (
                        <a
                            aria-current="page"
                            className="relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >{item}</a>
                    )}
                    {item != curr_page && (
                        <a key={i} onClick={() => { onGoTo(item) }}
                            className="relative cursor-pointer inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                        >{item}</a>
                    )}
                </>
            )
        })
        return (<>{lst}</>)
    }
    return (<></>)
}

function NewsPagination(props) {
    const { data, onGoTo, ...other } = props;
    const [state, setState] = useState(initial);
    useEffect(() => {
        if (data != null && data.total_records > data.page_size) {
            var total_pages = Math.ceil(data.total_records / data.page_size);
            setState((prev) => ({
                ...prev,
                curr_page: data.curr_page,
                total_records: data.total_records,
                total_pages
            }))
        }
    }, [data])
    const handle_prev = () => {
        onGoTo(state.curr_page - 1);
    }
    const handle_next = () => {
        onGoTo(state.curr_page + 1);
    }
    return (
        <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
            {state.total_pages > 1 && (
                <>
                    <div className="flex flex-1 justify-between sm:hidden">
                        {state.curr_page > 1 && (
                            <a onClick={handle_prev}
                                className="relative inline-flex cursor-pointer items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                            >Previous</a>
                        )}
                        {state.curr_page < state.total_pages && (
                            <a onClick={handle_next}
                                className="relative ml-3 inline-flex cursor-pointer items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                            >Next</a>
                        )}

                    </div>
                    <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                        <div className='hidden'>
                            <p className="text-sm text-gray-700">
                                Showing <span className="font-medium">1</span> to <span className="font-medium">10</span> of{' '}
                                <span className="font-medium">97</span> results
                            </p>
                        </div>
                        <div>
                            <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                                {state.curr_page > 1 && (
                                    <a onClick={handle_prev}
                                        className="relative cursor-pointer inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                    >
                                        <span className="sr-only">Previous</span>
                                        <FontAwesomeIcon icon={faChevronLeft} className="h-5 w-5" aria-hidden="true" />
                                    </a>
                                )}
                                {state.curr_page == 1 && (
                                    <a className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                                        <span className="sr-only">Previous</span>
                                        <FontAwesomeIcon icon={faChevronLeft} className="h-5 w-5" aria-hidden="true" />
                                    </a>
                                )}
                                <PageList total_pages={state.total_pages} curr_page={state.curr_page} onGoTo={onGoTo} />
                                {state.curr_page == state.total_pages && (
                                    <a className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                                        <span className="sr-only">Next</span>
                                        <FontAwesomeIcon icon={faChevronRight} className="h-5 w-5" aria-hidden="true" />
                                    </a>
                                )}
                                {state.curr_page < state.total_pages && (
                                    <a onClick={handle_next}
                                        className="relative cursor-pointer inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                    >
                                        <span className="sr-only">Next</span>
                                        <FontAwesomeIcon icon={faChevronRight} className="h-5 w-5" aria-hidden="true" />
                                    </a>
                                )}
                                
                            </nav>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

NewsPagination.propTypes = {

}

export default NewsPagination
