import React, { useState, useEffect } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor';
//import { GeneralHtmlSupport } from '@ckeditor/ckeditor5-html-support';
import exhibitorApi from '../../api/exhibitorApi';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
/*
ClassicEditor.create(document.querySelector('#editor'), {
  plugins: [GeneralHtmlSupport],
  htmlSupport: {
    allow: [
      {
        attributes: true,
        classes: true,
        styles: true
      }
    ],
  }
})*/

function AdminLandingPage() {
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then((file) => {
            console.log(file)
            var _data = {
              exhibitor_id: "",
              file
            };
            var response = exhibitorApi.upload_logo(_data);
            response.then((res) => {
              if (res.status === 200) {
                if (res.data.code === 202 && res.data.data != null) {
                  resolve({ default: `${process.env.REACT_APP_EXHIBITOR_IMAGE}${res.data.data.img_url}` })
                }
              }
            }).catch((err) => {
              reject(err);
            })
          })
        })
      }
    }
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    }
  }
  const [state, setState] = useState("<p>Hello from CKEditor&nbsp;5!</p>")
  return (
    <div>
      <CKEditor
        config={{
          extraPlugins: [uploadPlugin],
          htmlSupport: {
            allow: [
              { attributes: true, classes: true, styles: true }
            ]
          }
        }}
        editor={Editor}
        data={state}
        onReady={editor => {
          // You can store the "editor" and use when it is needed.
          console.log(editor);
        }}
        onChange={(event, editor) => {
          console.log(editor.getData());
        }}
      />
    </div>
  )
}

export default AdminLandingPage