import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import CommonHelper from '../../../helpers/common'
import InputTextUnit from '../../../components/controls/input-text-unit'
import ValidateMessage from '../../../components/controls/validate-msg'
import img14 from '../../../assets/image-14.jpg' //'../../assets/image-14.jpg'
import { UseSelector, useDispatch } from 'react-redux'
import { authActions } from '../../../_store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBackspace, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import Authentication from "../../../api/authApi"

function LoginForm(props) {
    const dispatch = useDispatch();
    const { onSubmit, ...other } = props;
    const [state, setState] = useState({
        "SeasonId": 0,
        "AccountId": "",
        "Password": ""
    });
    const [{ required_field, isError, isSuccess, error_msg }, setSelected] = useState({
        required_field: {
            AccountId: false,
            Password: false,
        },
    });
    const handle_change = (e) => {
        const { name, value } = e.target;
        setState((prev) => ({ ...prev, [name]: value }));

        validate_required(e);
    }
    const validate_required = (e) => {
        const { name, value } = e.target;
        var _arr = [];
        Object.keys({ ...required_field }).map((item) => {
            _arr.push(item);
        })
        var _idx = _arr.indexOf(name);
        if (_idx >= 0) {
            setSelected((prev) => ({
                ...prev,
                required_field: { ...required_field, [name]: CommonHelper.is_empty(value) },
            }))
        }
    }
    const handle_enter = (e) => {
        if (e.key === "Enter") {
            handle_submit();
        }
    }
    const validate_form = () => {
        var flag = true;
        var _arr = [];
        Object.keys({ ...required_field }).map((item) => {
            _arr.push(item);
        })
        var _data = { ...state };
        var _objError = { ...required_field };
        _arr.forEach((item, i) => {
            if (CommonHelper.is_empty(_data[item])) {
                _objError[item] = true;
                flag = false;
            }
        })
        setSelected((prev) => ({
            ...prev,
            required_field: _objError,
        }))
        return flag;
    }
    const handle_submit = () => {
        var flag = validate_form();
        if (flag) {
            const data = { ...state };
            if (data != null) {
                //data.SeasonId = season_id;
                dispatch(authActions.set_loading())
                var response = Authentication.organizer_login(data);
                response.then((res) => {
                    setSelected((prev) => ({ ...prev, show_loading: false }));
                    if (res.code == 208) {
                        setSelected((prev) => ({
                            ...prev,
                            isSuccess: true,
                            isError: false,
                            error_msg: "Đăng nhập thành công!"
                        }))
                        var obj_user = res.data;
                        if (obj_user != null && obj_user.role != null
                            && obj_user.role.access_Rights != null && obj_user.role.access_Rights.length > 0
                            && obj_user.role.access_Rights[0].page != null) {
                            var _page = obj_user.role.access_Rights[0].page;
                            window.location.href = _page.uiText;
                        }
                    } else {
                        dispatch(authActions.set_loading())
                        setSelected((prev) => ({
                            ...prev,
                            isSuccess: false,
                            isError: true,
                            error_msg: "Đăng nhập thất bại. Vui lòng kiểm tra tài khoản/mật khẩu!"
                        }))
                    }
                })
            }
            /*dispatch(authActions.set_loading())
            //onSubmit({ ...state });
            const response = Authentication.exhibitor_login({ ...state });
            response.then((res) => {
                console.log(res)
                if (res != null) {
                    var _code = res.code;
                    if (_code == 208) {
                        setSelected((prev) => ({
                            ...prev,
                            isSuccess: true,
                            isError: false,
                            error_msg: "Đăng nhập thành công!"
                        }))
                        window.location.href = "/exhibitor-form"
                    } else {
                        dispatch(authActions.set_loading())
                        setSelected((prev) => ({
                            ...prev,
                            isSuccess: false,
                            isError: true,
                            error_msg: "Đăng nhập thất bại. Vui lòng kiểm tra tài khoản/mật khẩu!"
                        }))
                    }
                }
            })*/
        }
    }
    return (
        <>
            <div className='flex-1 p-3'>
                <h1 className='font-bold text-lg text-center p-5'>Sign in</h1>
                <p >Enter your account and password to sign in</p>
                <div className='mt-3'>
                    <InputTextUnit onChange={handle_change} name='AccountId' value={state.AccountId}
                        label='Account' required={true} isError={required_field.AccountId} />
                </div>
                <div className='mt-3'>
                    <label className={'block font-semibold alt-font required'}>Password</label>
                    <input type='password' name='Password' value={state.Password} onChange={handle_change} onKeyDown={handle_enter}
                        className={'w-full h-8 py-1 px-3 rounded border-gray-400 border hover:border-cyan-900 active:border-cyan-900 focus:border-cyan-900 focus:outline-none ' + (required_field.Password ? "border-red-600" : "")} />
                    <ValidateMessage msgType='required' isShow={required_field.Password} />
                    {/* <label className="block">
                        <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium ">
                            Password
                        </span>
                        <input type="password" name="password" className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" placeholder="Password" />
                    </label> */}
                </div>
                <div className='mt-3 text-center'>
                    <button type='button' className='mt-5 w-2/3 rounded-2xl text-white py-1 px-2 box-border text-lg bg-orange-500 hover:bg-orange-400 disabled:bg-slate-500 transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110  duration-300'
                        onClick={handle_submit}>
                        LOGIN</button>
                </div>
                <div className='mt-2'>
                    {isError && (
                        <span className='text-red-600 py-3 block'>{error_msg}</span>
                    )}
                    {isSuccess && (
                        <span className='text-green-600 py-3 block'>{error_msg}</span>
                    )}
                </div>
                <div className='mt-2 text-center'>
                    <a href='/forgot' className='hover:text-orange-500'>Forgot ID or Password?</a>
                </div>
                <div className='mt-3 text-center'>
                    <a href='/' className='hover:text-orange-500'>
                        <FontAwesomeIcon icon={faChevronLeft} /> Back to Vibe
                    </a>
                </div>
            </div>
            <div className='flex-1 overflow-hidden '>
                <img className='w-full rounded-lg' src={img14} />
            </div>
        </>
    )
}

LoginForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
}

export default LoginForm