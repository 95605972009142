import React from 'react'
import ForgotForm from './form'

function Forgot() {
    return (
        <>
            <div className='inset-0 fixed'>
                <div className='mx-auto md:w-6/12 sm:w-full shadow-2xl md:mt-10 rounded-lg'>
                    <div className='block sm:flex'>
                        <ForgotForm />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Forgot