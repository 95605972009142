import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useLoaderData } from 'react-router-dom'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CommonHelper from '../../../../helpers/common';
import InputTextUnit from '../../../../components/controls/input-text-unit';
import SelectUnit from '../../../../components/controls/select-unit';
import TextAreaInput from '../../../../components/controls/textarea-input';
import AutocompleteInput from '../../../../components/controls/autocomplete-input';
import ValidateMessage from '../../../../components/controls/validate-msg';
import { organizerActions } from '../../../../_store/organizer.slice';
import PropTypes from 'prop-types'
import { faHome } from '@fortawesome/free-solid-svg-icons';

export async function loader({ params }) {
    // var response = await exhibitorApi.get_exhibitor(params.id);
    // if (response != null) {
    //     return response;
    // }
    return params;
}
const initial = {
    "id": 0,
    "pageId": '',
    "title": '',
    "sectionId": 0,
    "type": 0,
    "content": '',
    "largeImage": '',
    "thumbImage": '',
    "fromDate": '',
    "toDate": '',
    "order": 0,
    "status": 0,
    "createdBy": '',
    "createdDate": '',
    "modifiedBy": '',
    "modifiedDate": '',
}
function AdminPageContent(props) {
    const dispatch = useDispatch();
    const fileUpload = useRef(null);
    function uploadAdapter(loader) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    loader.file.then((file) => {
                        console.log(file)
                        var _data = {
                            exhibitor_id: "",
                            file
                        };
                        // var response = exhibitorApi.upload_logo(_data);
                        // response.then((res) => {
                        //     if (res.status === 200) {
                        //         if (res.data.code === 202 && res.data.data != null) {
                        //             resolve({ default: `${process.env.REACT_APP_EXHIBITOR_IMAGE}${res.data.data.img_url}` })
                        //         }
                        //     }
                        // }).catch((err) => {
                        //     reject(err);
                        // })
                    })
                })
            }
        }
    }
    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        }
    }
    const obj_params = useLoaderData();
    const [state, setState] = useState(initial)
    const [{ stateError, errorMsg, isLoading, isError, isSuccess, isEdit,
        onSearch, lst_venues }, setCommonState] = useState({
            stateError: {
                title: false,
                content: false,
            },
            errorMsg: {
                title: "",
                content: "",
            },
            isLoading: true,
            isError: false,
            isSuccess: false,
            isEdit: false,
            onSearch: false,
        })
    useEffect(() => {
        console.log(obj_params)
    }, [])
    const handle_change = (e) => {
        const { name, value } = e.target;
        setState((prev) => ({
            ...prev,
            [name]: value,
        }))
        validate_required(e);
    }
    const validate_required = (e) => {
        const { name, value } = e.target;
        var _arr = [];
        Object.keys({ ...stateError }).map((item) => {
            _arr.push(item);
        })
        var _idx = _arr.indexOf(name);
        if (_idx >= 0) {
            setCommonState((prev) => ({
                ...prev,
                stateError: { ...stateError, [name]: CommonHelper.is_empty(value) },
            }))
        }
    }
    return (
        <>
            <div className='p-3 bg-white'>
                <div className='breadscum w-full'>
                    <div className='w-1/2'>
                        <ul className='flex flex-wrap'>
                            <li className='text-sm '>
                                <Link to={"/admin"}><FontAwesomeIcon icon={faHome} /> </Link>
                            </li>
                            <li className='text-sm pl-2 leading-normal before:float-left before:pr-2 before:content-["/"]'>
                                <Link to={"/admin/landing-page"}>Landing page </Link>
                            </li>
                            <li className='text-sm pl-2 leading-normal before:float-left before:pr-2 before:content-["/"]'>
                                <Link to={"/admin/landing-page/content"}>Content </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='flex'>
                    <div className='w-2/3'>
                        <h6 className='text-color-primary font-semibold text-xl'>{state.title}</h6>
                    </div>
                    <div className='w-1/3'></div>
                </div>
                <div className='block sm:flex sm:flex-wrap'>
                    <div className='w-full sm:w-3/4 mt-4'>
                        <div className='w-full mt-2 px-2 sm:px-4'>
                            <InputTextUnit onChange={handle_change} value={state.title} name='title'
                                label='Title' required={true} isError={stateError.title}
                                errorMsg={errorMsg.title} />
                        </div>
                    </div>
                </div>
                {/* <CKEditor
                config={{
                    extraPlugins: [uploadPlugin],
                    htmlSupport: {
                        allow: [
                            { attributes: true, classes: true, styles: true }
                        ]
                    }
                }}
                editor={Editor}
                data={state}
                onReady={editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log(editor);
                }}
                onChange={(event, editor) => {
                    console.log(editor.getData());
                }}
            /> */}
            </div>
        </>
    )
}

AdminPageContent.propTypes = {}

export default AdminPageContent
