import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import logo from "../../../assets/vibeep.png";
import Authentication from '../../../api/authApi';
import { useSelector, useDispatch } from 'react-redux';
import { organizerActions } from '../../../_store/organizer.slice';

var lst_menu = [
    {
        title: "Dashboard",
        url: "/admin/dashboard",
    },
    {
        title: "Account Manager",
        url: "/admin/account-manager",
    },
    {
        title: "Master Data",
        url: "/admin/master-data",
    },
    {
        title: "Exhibitor",
        url: "/admin/exhibitor",
    },
    {
        title: "Buyer/Visitor",
        url: "/admin/visitor",
    },
    {
        title: "Landing page",
        url: "/admin/landing-page",
    },
    {
        title: "Setting",
        url: "/admin/setting",
    },
];

const MenuBar = (rights) => {
    var lst_right = [];
    rights.forEach((item) => {
        if (item.activated && item.page != null) {
            lst_right.push({
                title: item.page.uiTitle,
                url: item.page.uiText,
            });
        }
    })
    const lst = lst_right.map((item, i) => {
        return (
            <li className="items-center" key={i}>
                <a className="text-xs pl-1 uppercase p-2 font-bold block hover:bg-orange-300 text-color-primary"
                    href={item.url}>
                    {item.title}
                </a>
            </li>
        );
    })
    return (<ul className="navbar-nav">{lst}</ul>);
}

function Sidebar(props) {
    const dispatch = useDispatch();
    const [state, setState] = useState([]);
    const { rights, ...other } = props;
    const [collapseShow, setCollapseShow] = useState("hidden");
    useEffect(() => {
        setState(rights);
    }, [rights])
    const handle_logout = () => {
        dispatch(organizerActions.set_loading())
        var response = Authentication.organizer_logout();
        response.then((res) => {
            if (res != null && res.code == 506) {
                window.location.href = "/admin/login"
            }
        })
    }
    return (
        <>
            <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 p-2">
                <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
                    {/* Toggler */}
                    <button
                        className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                        type="button"
                        onClick={() => setCollapseShow("bg-white m-2")}
                    >
                        <FontAwesomeIcon icon={faBars} />
                    </button>
                    {/* Brand */}
                    <a href="/" target='_blank' className="block w-3/5 mx-auto">
                        <img src={logo} alt="VIBE" />
                    </a>
                    {/* User */}
                    <ul className="md:hidden items-center flex flex-wrap list-none">
                        <li className="inline-block relative">

                        </li>
                        <li className="inline-block relative">

                        </li>
                    </ul>
                    {/* Collapse */}
                    <div className={
                        "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 " +
                        collapseShow
                    }
                    >
                        {/* Collapse header */}
                        <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
                            <div className="flex flex-wrap">
                                <div className="w-6/12">
                                    <Link
                                        className="md:block text-left md:pb-2 text-blueGray-600 whitespace-nowrap "
                                        to="/">
                                        <img src={logo} alt="VIBE" />
                                        {/* <span className='block' style={{ fontFamily: "'DM Sans', sans-serif", fontSize: "11px" }}>Vietnam Interior & Build Exhibition</span> */}
                                    </Link>
                                </div>
                                <div className="w-6/12 flex justify-end">
                                    <button
                                        type="button"
                                        className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                                        onClick={() => setCollapseShow("hidden")}>
                                        <FontAwesomeIcon icon={faTimes} />
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* Navigation */}
                        <div >
                            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                                {MenuBar(state)}
                                <li className="items-center" >
                                    <a className="text-xs pl-1 uppercase p-2 font-bold block hover:bg-orange-300 text-color-primary cursor-pointer"
                                        onClick={handle_logout}>
                                        Logout
                                    </a>
                                </li>
                            </ul>
                        </div>


                    </div>
                </div>
            </nav>
        </>
    )
}

export default Sidebar