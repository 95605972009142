import React from 'react'
import CommonHelper from '../../helpers/common'

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// import required modules
import { Autoplay, Keyboard, Navigation, Pagination } from 'swiper/modules';

const data = [
    {
        "img": process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/v1/Co-van-Vibe-08.jpg",
        "name": "Ông Nguyễn Quốc Khanh",
        "title": "Chủ tịch AA Corporation",
    },
    {
        "img": process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/v1/Co-van-Vibe-06.jpg",
        "name": "Ông Hoàng Thúc Hào",
        "title": "Kiến trúc sư",
    },
    {
        "img": process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/v1/Co-van-Vibe-03.jpg",
        "name": "Ông Nguyễn Hoàng Mạnh",
        "title": "Kiến trúc sư - CEO Mia Design",
    },
    {
        "img": process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/v1/Co-van-Vibe-04.jpg",
        "name": "Ông Đinh Hồng Kỳ",
        "title": "Chủ tịch HĐQT Cty CP Secoin<br/>Phó chủ tịch SACA",
    },
    {
        "img": process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/v1/Co-van-Vibe-07.jpg",
        "name": "Bà Nguyễn Phan Thùy Dương",
        "title": "Chủ biên tập Tạp chí ELLE Decoration Việt Nam",
    },
    {
        "img": process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/v1/Co-van-Vibe-02.jpg",
        "name": "Ông Vương Đạo Hoàng",
        "title": "Kiến trúc sư, Giám Đốc Kiến Việt Media",
    },
    {
        "img": process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/v1/Co-van-Vibe-01.jpg",
        "name": "Ông Nguyễn Chánh Phương",
        "title": "Phó chủ tịch Cty AKA Furniture<br/>Phó chủ tịch HAWA",
    },
    {
        "img": process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/v1/Co-van-Vibe-05.jpg",
        "name": "Ông Nguyễn Thu Phong",
        "title": "Chủ tịch HĐQT Nhà Vui Group",
    },
    {
        "img": process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/v1/nguyen-cao-do-01.jpg",
        "name": "Ông Nguyễn Cao Đô",
        "title": "Chuyên gia truyền thông - Nhà sáng lập Clipper Ocean",
    },
]

function Testimonials() {
    return (
        <>
            <div>
                {/* <div className="hidden xl:block">
                    <div className='flex'>
                        {data.map((item, i) => {
                            return (
                                <div className='flex-1' key={i}>
                                    <div className='mx-auto w-32 h-32 overflow-hidden mt-10' >
                                        <img className='object-fill' src={item.img} />
                                    </div>
                                    <p className='mt-4 px-2 text-xs'>{item.name}</p>
                                    <p className='px-2 text-xs'>{item.title}</p>
                                </div>
                            )
                        })}
                    </div>
                </div> */}
                <div className='hidden md:block'>
                    <div className='section-customers'>
                        <Swiper watchSlidesProgress={true} slidesPerView={4}
                            navigation={true} autoplay={{
                                delay: 4000,
                                disableOnInteraction: false,
                            }}
                            modules={[Keyboard, Pagination, Navigation, Autoplay]} className="mySwiper">
                            {data.map((item, i) => {
                                return (
                                    <SwiperSlide key={i}>
                                        <div className='mx-auto w-5/6 overflow-hidden mt-10' >
                                            <img className='object-cover' src={item.img} />
                                        </div>
                                        <p className='mt-4 px-2'>{item.name}</p>
                                        <p className='px-2'>{CommonHelper.convertStringToHTML(item.title)}</p>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                </div>
                <div className="block md:hidden">
                    <div className='section-customers'>
                        <Swiper watchSlidesProgress={true} slidesPerView={2}
                            navigation={true} autoplay={{
                                delay: 3000,
                                disableOnInteraction: false,
                            }}
                            modules={[Autoplay, Keyboard, Pagination, Navigation]} className="mySwiper">
                            {data.map((item, i) => {
                                return (
                                    <SwiperSlide key={i}>
                                        <div className='mx-auto w-32 h-32 overflow-hidden mt-10' >
                                            <img className='object-fill' src={item.img} />
                                        </div>
                                        <p className='mt-4 px-2 '>{item.name}</p>
                                        <p className='px-2 '>{CommonHelper.convertStringToHTML(item.title)}</p>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Testimonials