import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import required modules
import { Autoplay, Keyboard, Navigation, Pagination } from 'swiper/modules';

function HomeNews(props) {
    const { list, ...other } = props;
    const [state, setState] = useState([]);
    useEffect(() => {
        setState(list)
    }, [list])
    return (
        <div className='mt-10 home-news-panel'>
            {state != null && state.length > 0 && (
                <>
                    <div className='hidden md:block'>
                        <Swiper watchSlidesProgress={true} slidesPerView={3}
                            navigation={true} pagination={{
                                clickable: true,
                            }} autoplay={{
                                delay: 3000,
                                disableOnInteraction: false,
                            }}
                            modules={[Autoplay, Keyboard, Pagination, Navigation]} className="myWork">
                            {state.map((item, i) => {
                                return (
                                    <SwiperSlide key={i}>
                                        <a className='block section-featured-exhibitor m-2 rounded-md'
                                            href={process.env.REACT_APP_NEWS_DOMAIN_NAME + "/news/" + item.url}>
                                            <div className=''>
                                                <a className='block w-full' href={process.env.REACT_APP_NEWS_DOMAIN_NAME + "/news/" + item.url}>
                                                    <div className='flex items-center rounded-md h-60 w-full overflow-hidden'>
                                                        <img className='w-full rounded-md'
                                                            src={process.env.REACT_APP_EXHIBITOR_IMAGE + item.image} title={item.title} />
                                                    </div>
                                                </a>
                                            </div>
                                            <div className='px-3 mt-2 pb-3'>
                                                <a className="block text-color-primary h-10 text-left hover:text-orange-500 overflow-hidden"
                                                    title={item.title}
                                                    href={process.env.REACT_APP_NEWS_DOMAIN_NAME + "/news/" + item.url}>
                                                    <h3 className='font-semibold text-sm pb-2'>{item.title}</h3>
                                                </a>
                                                <a title={item.title}
                                                    href={process.env.REACT_APP_NEWS_DOMAIN_NAME + "/news/" + item.url}>
                                                    <p className='text-left h-12 leading-4 text-sm overflow-hidden'>{item.shortContent}</p>
                                                </a>
                                            </div>
                                        </a>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                    <div className='md:hidden'>
                        <Swiper watchSlidesProgress={true} slidesPerView={2}
                            navigation={true} pagination={{
                                clickable: true,
                            }}
                            modules={[Autoplay, Keyboard, Pagination, Navigation]} className="myWork">
                            {state.map((item, i) => {
                                return (
                                    <SwiperSlide key={i}>
                                        <a className='block section-featured-exhibitor m-2 rounded-md'
                                            href={process.env.REACT_APP_NEWS_DOMAIN_NAME + "/news/" + item.url}>
                                            <div className=''>
                                                <a className='block w-full' href={process.env.REACT_APP_NEWS_DOMAIN_NAME + "/news/" + item.url}>
                                                    <div className='flex items-center rounded-md h-36 sm:h-60 w-full overflow-hidden'>
                                                        <img className='w-full rounded-md'
                                                            src={process.env.REACT_APP_EXHIBITOR_IMAGE + item.image} title={item.title} />
                                                    </div>
                                                </a>
                                            </div>
                                            <div className='px-3 mt-2 pb-3'>
                                                <a className="block text-color-primary h-10 text-left hover:text-orange-500 overflow-hidden" href={process.env.REACT_APP_NEWS_DOMAIN_NAME + "/news/" + item.url}>
                                                    <h3 className='font-semibold text-sm pb-2'>{item.title}</h3>
                                                </a>
                                                <a title={item.title}
                                                    href={process.env.REACT_APP_NEWS_DOMAIN_NAME + "/news/" + item.url}>
                                                    <p className='text-left text-sm leading-4 h-12 overflow-hidden'>{item.shortContent}</p>
                                                </a>
                                            </div>
                                        </a>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                </>
            )}
        </div>
    )
}

HomeNews.propTypes = {}

export default HomeNews
