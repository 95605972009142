import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

function Loading() {
    return (
        <div className='fixed inset-0 bg-neutral-700 z-40 opacity-75'>
            <div className='p-5 bg-white rounded-sm absolute left-1/2 top-1/2'>
                <FontAwesomeIcon icon={faSpinner} className='animate-spin text-2xl' />
            </div>
        </div>
    )
}

export default Loading