import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

// import required modules
import { Autoplay, Keyboard, Navigation, EffectFade, Pagination } from 'swiper/modules';

const data = [
    {
        "img_url": process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/logo-vnia.jpg",
        "title": "Vietnam Timber & Forest Products Association (VIFORES)",
    },
    {
        "img_url": process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/elle-decoration-vector-logo.png",
        "title": "Binh Duong Furniture Association (BIFA)",
    },
    {
        "img_url": process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/kienviet-media.png",
        "title": "Dong Nai Wood and Handicraft Association (DOWA)",
    },
    {
        "img_url": process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/ashui.png",
        "title": "FOREST PRODUCTS ASSOCIATION OF BINH DINH (FPA)",
    },
    {
        "img_url": process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/logo-consmedia.jpg",
        "title": "Handicraft & Wood Industry Association of HCMC (HAWA)",
    },
    {
        "img_url": process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/v1/logo-GALLERY-19.png",
        "title": "Handicraft & Wood Industry Association of HCMC (HAWA)",
    },
]

function Organizer() {
    return (
        <>
            <div className='flex flex-wrap'>
                {data.map((item, i) => (
                    <div key={i} className='w-1/2 lg:w-1/3 flex items-center'>
                        <img src={item.img_url} className='object-cover w-5/6 mx-auto lg:w-1/2 lg:my-3 sm:my-0' />
                    </div>
                ))}
                {/* <Swiper
                    slidesPerView={1}
                    // autoHeight={true}
                    height={144}
                    spaceBetween={60}
                    keyboard={{
                        enabled: true,
                    }}
                    effect={'fade'}
                    autoplay={{
                        delay: 3500,
                        disableOnInteraction: false,
                    }}
                    navigation={true}
                    modules={[EffectFade, Autoplay, Keyboard, Navigation]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <div className='flex items-center h-40'>
                            <div className='w-1/2 p-3'>
                                <div className='w-2/3 xl:w-1/3 mx-auto'>
                                    <img src={logo4} className='w-full object-cover' />
                                </div>
                            </div>
                            <div className='w-1/2 p-3'>
                                <div className='w-2/3 xl:w-1/3 mx-auto'><img src={logo5} className='w-full object-cover' /></div>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper> */}
            </div>
        </>
    )
}

export default Organizer