import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faHome, faGrip, faList } from '@fortawesome/free-solid-svg-icons';
import CommonHelper from '../../../helpers/common'
import Loading from '../../../components/controls/loading';
import exhibitorApi from '../../../api/exhibitorApi';
import AutocompleteInput from '../../../components/controls/autocomplete-input';
import { Disclosure } from '@headlessui/react';
import ExhibitorPagination from '../../component/pagination';

const certificates = ['FSC CoC', 'FSC FM', 'PEFC CoC', 'PEFC FM', 'ISO 9001', 'ISO 14001', 'IOS 45001', 'BSCI', 'SMETA', 'SA 8000',
    'CARB EPA P2', 'CTPAT', 'CITES', 'GREENGUARD', 'IAQ', 'ZWL', 'O%VOC', 'Fit for Food', 'Toys standard', 'Others'];
/*const product_type = ['Office Furniture', 'Living Room Furniture', 'Dining Room Furniture', 'Bedroom Furniture', 'Kitchen Furniture', 'Bathroom Furniture',
    'Outdoor Garden Furniture', 'Children’s Furniture', 'Hospitalities & Project Furniture', 'Interior Decoration', 'Handicraft',
    'Bamboo Rattan Product', 'Upholstery Leather', 'Carpet & Flooring', 'Hardware & Accessories', 'Gift', 'Wood material Panels', 'Sawn timber KD & Logs',
    'Oil-based Coating', 'Others']*/
const product_type = ['Nội thất phòng khách', 'Nội thất phòng ăn & nhà bếp', 'Nội thất phòng tắm', 'Nội thất văn phòng', 'Ngoại thất & sân vườn', 'Nội thất dành cho trẻ em',
    'Nội thất dành cho trẻ em', 'Nội thất khách sạn và dự án', 'Đồ thủ công mỹ nghệ', 'Phụ kiện trang trí nội thất']
const product_type_2 = ['Hệ thống & công nghệ xây dựng', 'Giải pháp và phần mềm công nghệ cao', 'Dịch vụ chứng chỉ chuyển đổi số',
    'Hệ thống an ninh', 'Nhà thông minh, Nhà máy thông minh',]
/* const main_material = ['Acacia', 'Rubber Wood', 'Cedar', 'Teak', 'Walnut', 'Oak', 'Ash', 'Pine', 'Poplar', 'Birch', 'Beech', 'Hemlock',
     'Particle Boad', 'MFC', 'MDF', 'HDF', 'Plywood', 'Melamine', 'Laminate', 'Acrylic', 'Veener', 'Solid surface', 'Compact HPL', 'PVC',
     'Foam', 'Textile Fabric', 'Leather', 'Silicon fiber', 'Bamboo', 'Rattan, Seagrass', 'PE fiber', 'PP fiber', 'PVC fiber', 'HDPE fiber',
     'Rope', 'Fiber', 'Brass, Bronze, Copper', 'Steel', 'Chrome', 'Iron', 'Aluminum', 'Inox', 'Fiberglass', 'Stone', 'Metal', 'Eucalyptus',
     'Clay', 'Glaze', 'Cement', 'Poly', 'Oil-based Coating', 'Western Hemlock', 'Spruce-Pine-FIR', 'Douglas-Fir', 'Western Red Cedar', 'Yellow Cedar', 'Others'];*/
const main_material = ["Kính, cửa, cửa sổ", "Gạch lát nền, đá ốp lát", "Vật liệu mái, tường, trần, sàn", "Sơn nước", "Vật tư ngoại thất: Thép, sắt, gạch, gỗ, vữa, nhôm", "Thiết bị vệ sinh, thiết bị phòng tắm",
    "Thiết bị ngoài trời và hồ bơi", "Ống và phụ tùng, dây điện", "Hóa chất, phụ gia", "Công cụ, dụng cụ"]
const markets = ['Vietnam', 'USA', 'Japan', 'China', 'South Korean', 'UK', 'Canada', 'Australia', 'France', 'Germany', 'Holland', 'Taiwan', 'Malaysia', 'Europe',
    'North America', 'Middle East', 'New Zealand', 'Others'];

const GridView = (props) => {
    const { list, ...other } = props
    const [state, setState] = useState([]);
    useEffect(() => {
        setState(list);
    }, [list])

    const lst = state.map((item, i) => {
        return (
            <li className="w-1/2 px-2 pb-4" key={i}>
                <a className='block w-full' href={"/exhibitor/" + (CommonHelper.is_not_empty(item.extraValue5) ? item.extraValue5 : item.companyNameVn)}>
                    <div className='flex'>
                        <div className='flex-auto align-middle lg:h-60 w-full overflow-hidden rounded-md'>
                            <img className='object-cover' src={process.env.REACT_APP_EXHIBITOR_IMAGE + item.imageUrl} title={item.companyNameVn} />
                        </div>
                    </div>
                </a>
                <a className="block p-1 text-color-primary hover:fs-color-secondary" href={"/exhibitor/" + (CommonHelper.is_not_empty(item.extraValue5) ? item.extraValue5 : item.companyNameVn)}>
                    <h3 className='font-semibold text-xl py-2'>{item.companyNameVn}</h3>
                </a>
                <p className='p-1'>{item.country}</p>
                <p className='p-1'>{item.venueName + " - " + item.boothNo}</p>
            </li>
        );
    })
    return (<ul className='flex flex-wrap'>{lst}</ul>)
}
const ListView = (props) => {
    const { list, ...other } = props
    const [state, setState] = useState([]);
    useEffect(() => {
        setState(list);
    }, [list])

    const lst = state.map((item, i) => {
        return (
            <li className="w-full sm:w-1/2 px-2 pb-4" key={i}>
                <div className='w-full block sm:flex'>
                    <div className='w-full sm:w-1/3'>
                        <a className='block w-full' href={"/exhibitor/" + (CommonHelper.is_not_empty(item.extraValue5) ? item.extraValue5 : item.companyNameVn)}>
                            <div className='flex'>
                                <div className='flex-auto align-middle lg:h-32 w-full overflow-hidden rounded-md'>
                                    <img className='object-cover' src={process.env.REACT_APP_EXHIBITOR_IMAGE + item.imageUrl} title={item.companyNameVn} />
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className='w-full px-3 pb-3 sm:w-2/3'>
                        <a className="block text-color-primary hover:fs-color-secondary" href={"/exhibitor/" + (CommonHelper.is_not_empty(item.extraValue5) ? item.extraValue5 : item.companyNameVn)}>
                            <h3 className='font-semibold text-xl pb-2'>{item.companyNameVn}</h3>
                        </a>
                        <p className='p-1'>{item.country}</p>
                        <p className='p-1'>{item.venueName + " - " + item.boothNo}</p>
                    </div>
                </div>
            </li>
        );
    })
    return (<ul className='flex flex-wrap'>{lst}</ul>)
}
function ExhibitorHome() {
    const [{ view, search_text, ex_data, lst_result, lst_exhibitor, sel_exhibitor,
        lst_proType, lst_material, lst_cert, lst_market, pagination }, setSelected] = useState({
            view: "list",
            search_text: "",
            ex_data: [],
            lst_result: [],
            lst_exhibitor: [],
            sel_exhibitor: [],
            lst_proType: [],
            lst_material: [],
            lst_cert: [],
            lst_market: [],
            pagination: {
                curr_page: 1,
                page_size: 10,
                total_records: 0,
            },
        })
    const handle_change = (e) => {
        const { name, value } = e.target;
        var lst = [];
        if (CommonHelper.is_not_empty(value)) {
            var lst = product_type.filter((item) => {
                if (item.indexOf(value) >= 0) {
                    return item;
                }
            })
        }
        /*setSelected((prev) => ({
            ...prev,
            lst_result: lst,
            search_text: value,
        }))*/
    }
    useEffect(() => {
        get_list()
    }, [])
    useEffect(() => {
        var _lst = [...ex_data];
        var _filter = [];
        if (lst_proType.length > 0) {
            lst_proType.forEach((item) => {
                _filter = _filter.concat(_lst.filter(x => x.productTypes != null && x.productTypes.length > 0 && x.productTypes.indexOf(item) >= 0))
            })
        }
        if (lst_material.length > 0) {
            lst_material.forEach((item) => {
                _filter = _filter.concat(_lst.filter(x => x.mainMaterial != null && x.mainMaterial.length > 0 && x.mainMaterial.indexOf(item) >= 0))
            })
        }
        if (lst_market.length > 0) {
            lst_market.forEach((item) => {
                _filter = _filter.concat(_lst.filter(x => x.exportMarkets != null && x.exportMarkets.length > 0 && x.exportMarkets.indexOf(item) >= 0))
            })
        }
        if (lst_cert.length > 0) {
            lst_cert.forEach((item) => {
                _filter = _filter.concat(_lst.filter(x => x.certificates != null && x.certificates.length > 0 && x.certificates.indexOf(item) >= 0))
            })
        }
        if (sel_exhibitor.length > 0) {
            sel_exhibitor.forEach((item) => {
                _filter = _filter.concat(_lst.filter(x => x.companyNameVn == item));
            })
        }

        if (sel_exhibitor.length == 0
            && lst_proType.length == 0
            && lst_material.length == 0
            && lst_market.length == 0
            && lst_cert.length == 0) {
            _filter = _lst;
        }
        var obj_page = { ...pagination };
        obj_page.curr_page = 1;
        obj_page.total_records = _filter.length;
        setSelected((prev) => ({ ...prev, pagination: obj_page }));
        set_data(_filter, 1);

    }, [lst_proType, lst_material, lst_market, lst_cert, sel_exhibitor])
    const get_list = () => {
        var response = exhibitorApi.get_list();
        response.then((res) => {
            if (res != null && res.code == 200) {
                var lst_exhibitor = res.data.map(x => x.companyNameVn);
                var obj_page = { ...pagination };
                obj_page.curr_page = 1;
                obj_page.total_records = res.totalRecords;
                setSelected((prev) => ({
                    ...prev,
                    ex_data: res.data,
                    pagination: obj_page,
                    lst_exhibitor
                }))
                set_data(res.data, 1);
            }
        })
    }
    const set_data = (data, curr_page) => {
        if (data != null && data.length > 0) {
            var lst_result = [];
            var _idx = (curr_page - 1) * pagination.page_size;
            data.forEach((item, i) => {
                if (i >= _idx && i < (curr_page * pagination.page_size)) {
                    lst_result.push(item);
                }
            })
            setSelected((prev) => ({ ...prev, lst_result }));
        }
    }
    const handle_filter = (e) => {
        const { name, value, checked } = e.target;
        var _lst_proType = [...lst_proType];
        var _lst_material = [...lst_material];
        var _lst_cert = [...lst_cert];
        var _lst_market = [...lst_market];
        var cate = name;
        switch (cate) {
            case "pro-type":
                if (checked) {
                    _lst_proType.push(value);
                } else {
                    _lst_proType = _lst_proType.filter(x => x != value);
                }
                break;
            case "material":
                //_lst_material.push(value);
                if (checked) {
                    _lst_material.push(value);
                } else {
                    _lst_material = _lst_material.filter(x => x != value);
                }
                break;
            case "certificate":
                //_lst_cert.push(value);
                if (checked) {
                    _lst_cert.push(value);
                } else {
                    _lst_cert = _lst_cert.filter(x => x != value);
                }
                break;
            case "market":
                //_lst_market.push(value);
                if (checked) {
                    _lst_market.push(value);
                } else {
                    _lst_market = _lst_market.filter(x => x != value);
                }
                break;
        }
        setSelected((prev) => ({
            ...prev,
            lst_proType: _lst_proType,
            lst_material: _lst_material,
            lst_cert: _lst_cert,
            lst_market: _lst_market,
        }))
    }
    const goto_page = (e) => {
        var obj_page = { ...pagination };
        obj_page.curr_page = e;
        setSelected((prev) => ({
            ...prev,
            pagination: obj_page,
        }))
        const data = [...ex_data]
        set_data(data, e);
    }
    return (
        <div className='mx-auto max-w-7xl py-5 px-4 sm:px-6 lg:px-8'>
            <div className='breadscum w-full'>
                <ul className='block md:flex md:flex-wrap'>
                    <li className='text-sm '>
                        <a href={"/"}><FontAwesomeIcon icon={faHome} /> </a>
                    </li>
                    <li className='text-sm pl-2 leading-normal before:float-left before:pr-2 before:content-["/"]'>
                        <a >Danh sách nhà triển lãm </a>
                    </li>
                </ul>
            </div>
            <div className='h-12 border-b border-solid border-black mt-5'>
                <h1 className='font-semibold text-2xl text-color-primary'>Danh sách nhà triển lãm 2024</h1>
            </div>
            <div className='h-3'></div>
            <div className='w-full block sm:flex'>
                <div className='w-full sm:w-1/4'>
                    <div className='my-5'>
                        <h3 className='text-xl font-semibold text-color-primary'>Tìm nhà triển lãm</h3>
                    </div>
                    <div className='w-full my-2 px-3'>
                        <AutocompleteInput multiple options={lst_exhibitor} id="search_exhibitor" placeholder='Search Exhibitor' clearButton
                            selected={sel_exhibitor} onChange={(e) => { setSelected((prev) => ({ ...prev, sel_exhibitor: e })) }} />
                    </div>
                    <div className="w-full">
                        <div className="mx-auto w-full max-w-md rounded-2xl bg-white p-2">
                            <Disclosure>
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className="flex w-full justify-between rounded-lg bg-orange-100 px-4 py-2 text-left text-sm font-medium text-orange-900 hover:bg-orange-200 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/75">
                                            <span>TRANG TRÍ NỘI THẤT</span>
                                            <FontAwesomeIcon icon={faChevronDown}
                                                className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-orange-500`} />
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="p-4 mt-2 text-gray-500 max-h-80 overflow-y-scroll rounded-md">
                                            {product_type != null && product_type.length > 0 && (
                                                <ul className='block relative'>
                                                    {product_type.map((item, i) => {
                                                        return (
                                                            <li key={i} className='px-3 p-1 block relative rounded-md hover:bg-orange-200'>
                                                                {/* <a onClick={() => { handle_filter('pro-type', item) }} className='cursor-pointer'>{item}</a> */}
                                                                <label >
                                                                    <input type='checkbox' className='mr-2' value={item} name='pro-type' onClick={handle_filter} /> {item}
                                                                </label>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            )}
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                            <Disclosure as="div" className="mt-2">
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className="flex w-full justify-between rounded-lg bg-orange-100 px-4 py-2 text-left text-sm font-medium text-orange-900 hover:bg-orange-200 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/75">
                                            <span>CÔNG NGHỆ VÀ DỊCH VỤ HỖ TRỢ</span>
                                            <FontAwesomeIcon icon={faChevronDown}
                                                className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-orange-500`} />
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="p-4 mt-2 text-gray-500 max-h-80 overflow-y-scroll rounded-md">
                                            {product_type_2 != null && product_type_2.length > 0 && (
                                                <ul className='block relative'>
                                                    {product_type_2.map((item, i) => {
                                                        return (
                                                            <li key={i} className='px-3 p-1 block relative rounded-md hover:bg-orange-200'>
                                                                {/* <a onClick={() => { handle_filter('pro-type', item) }} className='cursor-pointer'>{item}</a> */}
                                                                <label >
                                                                    <input type='checkbox' className='mr-2' value={item} name='pro-type' onClick={handle_filter} /> {item}
                                                                </label>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            )}
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                            <Disclosure as="div" className="mt-2">
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className="flex w-full justify-between rounded-lg bg-orange-100 px-4 py-2 text-left text-sm font-medium text-orange-900 hover:bg-orange-200 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/75">
                                            <span>VẬT LIỆU XÂY DỰNG</span>
                                            <FontAwesomeIcon icon={faChevronDown}
                                                className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-orange-500`} />
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="p-4 mt-2 text-gray-500 max-h-80 overflow-y-scroll rounded-md">
                                            {main_material != null && main_material.length > 0 && (
                                                <ul className='block relative'>
                                                    {main_material.map((item, i) => {
                                                        return (
                                                            <li key={i} className='px-3 p-1 block relative rounded-md hover:bg-orange-200'>
                                                                {/* <a onClick={() => { handle_filter('material', item) }} className='cursor-pointer'>{item}</a> */}
                                                                <label >
                                                                    <input type='checkbox' className='mr-2' value={item} name='material' onClick={handle_filter} /> {item}
                                                                </label>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            )}
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                            <Disclosure as="div" className="mt-2 hidden">
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className="flex w-full justify-between rounded-lg bg-orange-100 px-4 py-2 text-left text-sm font-medium text-orange-900 hover:bg-orange-200 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/75">
                                            <span>Certificates</span>
                                            <FontAwesomeIcon icon={faChevronDown}
                                                className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-orange-500`} />
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="p-4 mt-2 text-gray-500 max-h-80 overflow-y-scroll rounded-md">
                                            {certificates != null && certificates.length > 0 && (
                                                <ul className='block relative'>
                                                    {certificates.map((item, i) => {
                                                        return (
                                                            <li key={i} className='px-3 p-1 block relative rounded-md hover:bg-orange-200'>
                                                                {/* <a onClick={() => { handle_filter('certificate', item) }} className='cursor-pointer'>{item}</a> */}
                                                                <label >
                                                                    <input type='checkbox' className='mr-2' value={item} name='certificate' onClick={handle_filter} /> {item}
                                                                </label>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            )}
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                            <Disclosure as="div" className="mt-2 hidden">
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className="flex w-full justify-between rounded-lg bg-orange-100 px-4 py-2 text-left text-sm font-medium text-orange-900 hover:bg-orange-200 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/75">
                                            <span>Export Markets</span>
                                            <FontAwesomeIcon icon={faChevronDown}
                                                className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-orange-500`} />
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="p-4 mt-2 text-gray-500 max-h-80 overflow-y-scroll rounded-md">
                                            {markets != null && markets.length > 0 && (
                                                <ul className='block relative'>
                                                    {markets.map((item, i) => {
                                                        return (
                                                            <li key={i} className='px-3 p-1 block relative rounded-md hover:bg-orange-200'>
                                                                {/* <a onClick={() => { handle_filter('market', item) }} className='cursor-pointer'>{item}</a> */}
                                                                <label >
                                                                    <input type='checkbox' className='mr-2' value={item} name='market' onClick={handle_filter} /> {item}
                                                                </label>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            )}
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        </div>
                    </div>
                </div>
                <div className='w-full sm:w-3/4'>
                    <div className='my-5 text-right'>
                        <a onClick={() => { setSelected((prev) => ({ ...prev, view: "grid" })) }} className='inline-block p-3 text-3xl cursor-pointer'>
                            <FontAwesomeIcon icon={faGrip} />
                        </a>
                        <a onClick={() => { setSelected((prev) => ({ ...prev, view: "list" })) }} className='inline-block p-3 text-3xl cursor-pointer'>
                            <FontAwesomeIcon icon={faList} />
                        </a>
                    </div>
                    <div>
                        {view === "grid" && (
                            <>
                                {lst_result.length > 0 && (<GridView list={lst_result} />)}
                            </>
                        )}
                        {view === "list" && (<>
                            {lst_result.length > 0 && (<ListView list={lst_result} />)}
                        </>
                        )}
                    </div>
                    <div>
                        <ExhibitorPagination data={pagination} onGoTo={goto_page} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExhibitorHome