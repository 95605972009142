import React, { useState, useEffect } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


// import required modules
import { Autoplay, Keyboard, Navigation, Pagination } from 'swiper/modules';
import CommonHelper from '../../helpers/common';


function FeaturedExhibitors(props) {
    const { list, ...other } = props
    const [{ state_top, state_bottom }, setState] = useState({
        state_top: [],
        state_bottom: []
    });
    useEffect(() => {
        if (list != null && list.length > 0) {
            var state_top = list.filter(x => x.obj_position != null && x.obj_position.top);
            var state_bottom = list.filter(x => x.obj_position != null && x.obj_position.bottom);
            setState((prev) => ({
                ...prev,
                state_top, state_bottom
            }))
        }
    }, [list])
    return (
        <>
            {state_top != null && state_top.length > 0 && (
                <div className=''>
                    <div className='flex flex-col'>
                        <div className="brand-slider-container">
                            <div className="brand-slider-1 flex-nowrap whitespace-pre-wrap">
                                {state_top.map((item, i) => {
                                    return (
                                        <div key={i}>
                                            <a className='block section-featured-exhibitor m-2 w-72 relative'
                                                href={"/exhibitor/" + (CommonHelper.is_not_empty(item.extraValue5) ? item.extraValue5 : item.companyNameVn)}>
                                                <div className='w-full h-52 flex items-center overflow-hidden'>
                                                    <img className='object-cover' src={process.env.REACT_APP_EXHIBITOR_IMAGE + item.imageUrl} title={item.companyNameVn} />
                                                </div>
                                                <div className='p-content w-full text-left text-color-primary absolute bottom-0' style={{ background: "#00154fe3" }}>
                                                    <h5 className='text-white text-sm h-10 overflow-hidden leading-4 pt-1 pl-1'>{item.companyNameVn} </h5>
                                                </div>
                                            </a>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="brand-slider-2 flex-nowrap whitespace-pre-wrap">
                                {state_top.map((item, i) => {
                                    return (
                                        <div key={i}>
                                            <a className='block section-featured-exhibitor m-2 w-72 relative'
                                                href={"/exhibitor/" + (CommonHelper.is_not_empty(item.extraValue5) ? item.extraValue5 : item.companyNameVn)}>
                                                <div className='w-full h-52 flex items-center overflow-hidden'>
                                                    <img className='object-cover' src={process.env.REACT_APP_EXHIBITOR_IMAGE + item.imageUrl} title={item.companyNameVn} />
                                                </div>
                                                <div className='p-content w-full text-left text-color-primary absolute bottom-0' style={{ background: "#00154fe3" }}>
                                                    <h5 className='text-white text-sm h-10 overflow-hidden leading-4 pt-1 pl-1'>{item.companyNameVn} </h5>
                                                </div>
                                            </a>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col'>
                        <div className="brand-slider-container">
                            <div className="brand-slider-3 flex flex-nowrap whitespace-pre-wrap">
                                {state_bottom.map((item, i) => {
                                    return (
                                        <div key={i}>
                                            <a className='block section-featured-exhibitor m-2 w-80 relative'
                                                href={"/exhibitor/" + (CommonHelper.is_not_empty(item.extraValue5) ? item.extraValue5 : item.companyNameVn)}>
                                                <div className='w-full h-52 flex items-center overflow-hidden'>
                                                    <img className='object-cover' src={process.env.REACT_APP_EXHIBITOR_IMAGE + item.imageUrl} title={item.companyNameVn} />
                                                </div>
                                                <div className='p-content w-full text-left text-color-primary absolute bottom-0' style={{ background: "#00154fe3" }}>
                                                    <h5 className='text-white text-sm h-10 overflow-hidden leading-4 pt-1 pl-1'>{item.companyNameVn} </h5>
                                                </div>
                                            </a>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="brand-slider-4 flex flex-nowrap whitespace-pre-wrap">
                                {state_bottom.map((item, i) => {
                                    return (
                                        <div key={i}>
                                            <a className='block section-featured-exhibitor m-2 w-80 relative'
                                                href={"/exhibitor/" + (CommonHelper.is_not_empty(item.extraValue5) ? item.extraValue5 : item.companyNameVn)}>
                                                <div className='w-full h-52 flex items-center overflow-hidden'>
                                                    <img className='object-cover' src={process.env.REACT_APP_EXHIBITOR_IMAGE + item.imageUrl} title={item.companyNameVn} />
                                                </div>
                                                <div className='p-content w-full text-left text-color-primary absolute bottom-0' style={{ background: "#00154fe3" }}>
                                                    <h5 className='text-white text-sm h-10 overflow-hidden leading-4 pt-1 pl-1'>{item.companyNameVn} </h5>
                                                </div>
                                            </a>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </>
    )
}

export default FeaturedExhibitors