import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import CommonHelper from '../../helpers/common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InputTextUnit from '../../components/controls/input-text-unit'
import SelectUnit from '../../components/controls/select-unit'
import AutocompleteInput from '../../components/controls/autocomplete-input'
import ValidateMessage from '../../components/controls/validate-msg'
import visitorApi from '../../api/visitorApi'
import { organizerActions } from '../../_store/organizer.slice'
import { faHome, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons'
import AdminVisitorSearch from './search'

const lst_purpose = ['Place order',
  'Collect market and trends information',
  'Look for new suppliers / agents',
  'Meet and network with current suppliers / clients',
  'Evaluate show for future participation',
  'Look for new products / technologies / services',
  'Attend seminar',
  'Others']
const lst_material = ['Bathroom Furniture', 'Bedroom furniture', 'Children & Nursery furniture',
  'Contract Furniture', 'Dining room furniture', 'Gift & Handicraft', 'Hardware',
  'Home Accessories', 'Hotel Furniture', 'Interior fitting', 'Living room furniture',
  'Office furniture', 'Outdoor & Garden furniture', 'Services', 'Wood material & Panels', 'Other']
const lst_product = ['For Upholstery', 'Matt Black Metal Legs', 'Metals', 'Natural Oak', 'Acacia', 'Solid Wood',
  'Panel Wood / Engineered Wood', 'Wicker', 'Others']
const lst_channel = ['Invitation from Exhibitor', 'E-Newsletter', 'Official Website',
  'Friends/ Relatives/ Colleague', 'Social Media (Facebook, Linkedin)',
  'Invitation from Organizer or Co-organizer', 'Newspaper/ Magazine',
  'Outdoor Advertisement (Street Banner, Airport Billboard)', 'Online Advertising (Google Ads)',
  'Search Engine (Google)']
const countries = ['Vietnam', 'Afghanistan', 'Albania', 'Algeria', 'American Samoa', 'Andorra', 'Angola', 'Anguilla', 'Antarctica', 'Antigua and Barbuda', 'Argentina',
  'Armenia', 'Aruba', 'Australia', 'Austria', 'Azerbaijan', 'Bahamas', 'Bahrain', 'Bangladesh', 'Barbados', 'Belarus', 'Belgium', 'Belize', 'Benin',
  'Bermuda', 'Bhutan', 'Bolivia (Plurinational State of)', 'Bonaire, Saint Eustatius and Saba', 'Bosnia and Herzegovina', 'Botswana', 'Bouvet Island',
  'Brazil', 'British Indian Ocean Territory', 'Brunei Darussalam', 'Bulgaria', 'Burkina Faso', 'Burundi', 'Cabo Verde', 'Cambodia', 'Cameroon',
  'Canada', 'Cayman Islands', 'Central African Republic', 'Chad', 'Chile', 'China', 'Christmas Island', 'Cocos (Keeling) Islands', 'Colombia',
  'Comoros', 'Congo', 'Congo (Democratic Republic of the)', 'Cook Islands', 'Costa Rica', 'Croatia', 'Cuba', 'Curaçao', 'Cyprus', 'Czech Republic',
  "Côte d'Ivoire", 'Denmark', 'Djibouti', 'Dominica', 'Dominican Republic', 'Ecuador', 'Egypt', 'El Salvador', 'Equatorial Guinea', 'Eritrea',
  'Estonia', 'Eswatini (Kingdom of)', 'Ethiopia', 'Falkland Islands (Malvinas)', 'Faroe Islands', 'Fiji', 'Finland', 'France', 'French Guiana',
  'French Polynesia', 'French Southern Territories', 'Gabon', 'Gambia', 'Georgia', 'Germany', 'Ghana', 'Gibraltar', 'Greece', 'Greenland', 'Grenada',
  'Guadeloupe', 'Guam', 'Guatemala', 'Guernsey', 'Guinea', 'Guinea-Bissau', 'Guyana', 'Haiti', 'Heard Island and McDonald Islands', 'Honduras', 'Hong Kong',
  'Hungary', 'Iceland', 'India', 'Indonesia', 'Iran (Islamic Republic of)', 'Iraq', 'Ireland (Republic of)', 'Isle of Man', 'Israel', 'Italy', 'Jamaica',
  'Japan', 'Jersey', 'Jordan', 'Kazakhstan', 'Kenya', 'Kiribati', "Korea (Democratic People's Republic of)", 'Korea (Republic of)', 'Kosovo', 'Kuwait',
  'Kyrgyzstan', "Lao People's Democratic Republic", 'Latvia', 'Lebanon', 'Lesotho', 'Liberia', 'Libya', 'Liechtenstein', 'Lithuania', 'Luxembourg',
  'Macao', 'Madagascar', 'Malawi', 'Malaysia', 'Maldives', 'Mali', 'Malta', 'Marshall Islands', 'Martinique', 'Mauritania', 'Mauritius', 'Mayotte',
  'Mexico', 'Micronesia (Federated States of)', 'Moldova (Republic of)', 'Monaco', 'Mongolia', 'Montenegro', 'Montserrat', 'Morocco', 'Mozambique',
  'Myanmar', 'Namibia', 'Nauru', 'Nepal', 'Netherlands', 'New Caledonia', 'New Zealand', 'Nicaragua', 'Niger', 'Nigeria', 'Niue', 'Norfolk Island',
  'North Macedonia (Republic of)', 'Northern Mariana Islands', 'Norway', 'Oman', 'Pakistan', 'Palau', 'Palestine (State of)', 'Panama', 'Papua New Guinea',
  'Paraguay', 'Peru', 'Philippines', 'Pitcairn', 'Poland', 'Portugal', 'Puerto Rico', 'Qatar', 'Romania', 'Russian Federation', 'Rwanda', 'Réunion',
  'Saint Barthélemy', 'Saint Helena, Ascension and Tristan da Cunha', 'Saint Kitts and Nevis', 'Saint Lucia', 'Saint Martin (French part)',
  'Saint Pierre and Miquelon', 'Saint Vincent and the Grenadines', 'Samoa', 'San Marino', 'Sao Tome and Principe', 'Saudi Arabia', 'Senegal',
  'Serbia', 'Seychelles', 'Sierra Leone', 'Singapore', 'Sint Maarten (Dutch part)', 'Slovakia', 'Slovenia', 'Solomon Islands', 'Somalia',
  'South Africa', 'South Georgia and the South Sandwich Islands', 'South Sudan', 'Spain', 'Sri Lanka', 'Sudan', 'Suriname', 'Svalbard and Jan Mayen',
  'Sweden', 'Switzerland', 'Syrian Arab Republic', 'Taiwan, Republic of China', 'Tajikistan', 'Tanzania (United Republic of)', 'Thailand', 'Timor-Leste',
  'Togo', 'Tokelau', 'Tonga', 'Trinidad and Tobago', 'Tunisia', 'Turkmenistan', 'Turks and Caicos Islands', 'Tuvalu', 'Türkiye', 'Uganda', 'Ukraine',
  'United Arab Emirates', 'United Kingdom of Great Britain and Northern Ireland', 'United States Minor Outlying Islands', 'United States of America',
  'Uruguay', 'Uzbekistan', 'Vanuatu', 'Vatican City State', 'Venezuela (Bolivarian Republic of)', 'Virgin Islands (British)',
  'Virgin Islands (U.S.)', 'Wallis and Futuna', 'Western Sahara', 'Yemen', 'Zambia', 'Zimbabwe', 'Åland Islands',];
const lst_job_title = ['Buyer', 'Interior Designer/ Architect', 'General Management', 'Marketing', 'Merchandising',
  'Operations', 'Owner', 'President/ CEO', 'Other'];

const initialState = {
  "AppSite": process.env.REACT_APP_CODE,
  "SeasonId": "0",
  "VisitorId": "",
  "VisitorType": "",
  "VIP": "false",
  "VipDefined": "0",
  "Sponsor": "",
  "RequestType": "",
  "Individual": "true",
  "ShortName": "",
  "CompanyNameEn": "",
  "AddressEn": "",
  "CompanyNameVn": "",
  "AddressVn": "",
  "Country": "",
  "Website": "",
  "ContactName": "",
  "ContactTitle": "",
  "ContactPosition": "",
  "ContactEmail": "",
  "ContactPhone": "",
  "SearchProductCategories": "",
  "SearchProducts": "",
  "Purposes": "",
  "Channels": "",
  "Materials": "",
  VisitorMembers: [
    /*{
      "FullName1": "",
      "Country1": "",
      "Phone1": "",
      "Email1": "",
    }*/
  ],
};

function AdminBuyerVisitor() {
  const dispatch = useDispatch();
  const [state, setState] = useState(initialState);
  const [{ stateError, isLoading, isError, isSuccess, onSearch, msg,
    lst_vip, lst_visitor_type }, setCommonState] = useState({
      stateError: {
        ContactName: false,
        Country: false,
        ContactEmail: false,
        ContactPhone: false,
        CompanyNameEn: false,
        ContactPosition: false,
        Purposes: false,
        SearchProducts: false,
        Materials: false,
        Channels: false,
      },
      isLoading: false,
      isError: false,
      isSuccess: false,
      msg: "",
      onSearch: true,
      lst_vip: [{ "code": "0", "name": "No" }, { "code": "1", "name": "Yes" },],
      lst_visitor_type: [{ "code": "1", "name": "Individual" }, { "code": "0", "name": "Organization" },],
    })
  const [{ Purposes, SearchProducts, Materials, Channels }, setSelected] = useState({
    Purposes: [],
    SearchProducts: [],
    Materials: [],
    Channels: []
  })

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      Purposes: JSON.stringify(Purposes),
      SearchProducts: JSON.stringify(SearchProducts),
      Materials: JSON.stringify(Materials),
      Channels: JSON.stringify(Channels),
    }))
  }, [Purposes, SearchProducts, Materials, Channels])
  const handle_change = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }))
    validate_required(e);
  }
  const validate_required = (e) => {
    const { name, value } = e.target;
    var _arr = [];
    Object.keys({ ...stateError }).map((item) => {
      _arr.push(item);
    })
    var _idx = _arr.indexOf(name);
    if (_idx >= 0) {
      setCommonState((prev) => ({
        ...prev,
        stateError: { ...stateError, [name]: CommonHelper.is_empty(value) },
      }))
    }
  }
  const validate_form = () => {
    var flag = true;
    var _arr = [];
    Object.keys({ ...stateError }).map((item) => {
      _arr.push(item);
    })
    var _data = { ...state };
    console.log(_data);
    var _objError = { ...stateError };
    _arr.forEach((item, i) => {
      if (CommonHelper.is_empty(_data[item])) {
        _objError[item] = true;
        flag = false;
      }
      if (['Purposes', 'SearchProducts', 'Materials', 'Channels'].indexOf(item) >= 0) {
        if (_data[item] === "[]") {
          _objError[item] = true;
        }
      }
    })
    setCommonState((prev) => ({
      ...prev,
      stateError: _objError,
    }))
    return flag;
  }
  const handle_change_sub = (e, id) => {
    const { name, value } = e.target;
    var _lst = [...state.VisitorMembers];
    var _idx = _lst.findIndex(x => x.Id == id);
    if (_idx >= 0) {
      _lst[_idx][name] = value;
    }
    setState((prev) => ({ ...prev, VisitorMembers: _lst }));
  }
  const handle_add_person = () => {
    var max = 1
    var list = [...state.VisitorMembers];
    if (list.length > 0) {
      var maxObj = Math.max.apply(Math, list.map(o => o.Id));
      max = maxObj + 1;
    }
    var _model = {
      "Id": max,
      "MemberName": "",
      "Country": "",
      "Phone": "",
      "Email": "",
    }
    list.push(_model);
    setState((prev) => ({
      ...prev,
      VisitorMembers: list
    }))
  }
  const handle_search = () => { setCommonState((prev) => ({ ...prev, onSearch: true })) }
  const handle_submit = () => {
    var flag = validate_form();
    if (flag) {
      var data = { ...state };
      data.VIP = data.VIP == "true";
      data.Individual = data.Individual == "true";
      dispatch(organizerActions.set_loading());
      var response = visitorApi.update_visitor(data);
      response.then((res) => {
        dispatch(organizerActions.set_loading());
        if (res != null && res.code == 202) {
          setCommonState((prev) => ({
            ...prev,
            isSuccess: true,
            isError: false,
            msg: "Update successful!"
          }));
        } else if (res.code == 400) {
          setCommonState((prev) => ({
            ...prev,
            isSuccess: false,
            isError: true,
            msg: "No data"
          }));
        } else if (res.code == 506) {
          dispatch(organizerActions.set_loading());
          alert("Session is expired. Please sign in again!")
          const timer = setTimeout(() => {
            window.location.href = "/admin/login"
          }, 4000);
        } else {
          setCommonState((prev) => ({
            ...prev,
            isSuccess: false,
            isError: true,
            msg: "Registration failed!"
          }));
        }
      })
    } else {
      setCommonState((prev) => ({ ...prev, msg: "Please check required fields!", isError: true }))
    }
  }
  const handle_edit = (e) => {
    setCommonState((prev) => ({ ...prev, onSearch: false }))
    if (e != null) {
      dispatch(organizerActions.set_loading());
      const response = visitorApi.get(e.visitorId)
      response.then((res) => {
        dispatch(organizerActions.set_loading());
        if (res != null) {
          if (res.code == 200) {
            set_data(res.data);
          } else if (res.code == 400) {

          } else {
            dispatch(organizerActions.set_loading());
            alert("Session is expired. Please sign in again!")
            const timer = setTimeout(() => {
              window.location.href = "/admin/login"
            }, 4000);
          }
        }
      })
    }
  }
  const set_data = (data) => {
    console.log(data)
    if (data != null) {
      var VisitorMembers = [];
      if (data.visitorMembers != null && data.visitorMembers.length > 0) {
        VisitorMembers = data.visitorMembers.map((item) => {
          return {
            "MemberName": item.memberName,
            "Email": item.email,
            "Phone": item.phone,
            "Country": item.country,
          }
        })
      }
      var Purposes = [];
      if (CommonHelper.is_not_empty(data.purposes)) {
        Purposes = JSON.parse(data.purposes);
        console.log(Purposes);
      }
      var Channels = [];
      if (CommonHelper.is_not_empty(data.channels)) {
        Channels = JSON.parse(data.channels);
        console.log(Channels);
      }
      var Materials = [];
      if (CommonHelper.is_not_empty(data.materials)) {
        Materials = JSON.parse(data.materials);
        console.log(Materials);
      }
      var SearchProducts = [];
      if (CommonHelper.is_not_empty(data.searchProducts)) {
        SearchProducts = JSON.parse(data.searchProducts);
        console.log(SearchProducts);
      }
      setSelected((prev) => ({
        ...prev,
        Purposes,
        Channels,
        SearchProducts,
        Materials
      }))
      setState((prev) => ({
        ...prev,
        "SeasonId": "0",
        "VisitorId": data.visitorId,
        "VisitorType": data.visitorType,
        "VIP": data.vip + "",
        "VipDefined": data.vipDefined,
        "Sponsor": data.sponsor,
        "RequestType": data.requestType,
        "Individual": data.individual + "",
        "ShortName": data.shortName,
        "CompanyNameEn": data.companyNameEn,
        "AddressEn": data.addressEn,
        "CompanyNameVn": data.companyNameVn,
        "AddressVn": data.addressVn,
        "Country": data.country,
        "Website": data.website,
        "ContactName": data.contactName,
        "ContactTitle": data.contactTitle,
        "ContactPosition": data.contactPosition,
        "ContactEmail": data.contactEmail,
        "ContactPhone": data.contactPhone,
        "SearchProductCategories": data.searchProductCategories,
        "SearchProducts": data.searchProducts,
        "Purposes": data.purposes,
        "Channels": data.channels,
        "Materials": data.materials,
        VisitorMembers
      }))
    }
  }
  return (
    <>
      <div className='p-3 bg-white'>
        <div className='breadscum w-full'>
          <div className='w-1/2'>
            <ul className='flex flex-wrap'>
              <li className='text-sm '>
                <Link to={"/"}><FontAwesomeIcon icon={faHome} /> </Link>
              </li>
              <li className='text-sm pl-2 leading-normal before:float-left before:pr-2 before:content-["/"]'>
                <Link to={"/admin/exhibitor"}>Visitor </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className='flex'>
          <div className='w-2/3'>
            <h6 className='alt-font text-color-primary font-semibold text-xl'>Visitor</h6>
          </div>
          <div className='w-1/3 text-right'>
            <a className='cursor-pointer' onClick={handle_search}>
              <FontAwesomeIcon icon={faSearch} />
            </a>
          </div>
        </div>
        <div className='mt-5'>
          <div className='block sm:flex sm:flex-wrap'>
            <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
              <div className='flex'>
                <label className='font-semibold inline-block mr-10'>VIP</label>
                <label className='inline-block mr-3'>
                  <input type='radio' name="VIP" onClick={handle_change} className='mr-1' value="true" checked={state.VIP == "true"} /> Yes
                </label>
                <label className='inline-block'>
                  <input type='radio' name="VIP" onClick={handle_change} className='mr-1' value="false" checked={state.VIP == "false"} /> No
                </label>
              </div>
            </div>
            <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
              <div className='flex'>
                <label className='inline-block mr-5'>
                  <input type='radio' name="Individual" onClick={handle_change} className='mr-1' value="true" checked={state.Individual == "true"} /> Individual
                </label>
                <label className='inline-block'>
                  <input type='radio' name="Individual" onClick={handle_change} className='mr-1' value="false" checked={state.Individual == "false"} /> Organization
                </label>
              </div>
            </div>
            <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
              <InputTextUnit onChange={handle_change} value={state.ContactName} name='ContactName'
                label='Contact Name' required={true} isError={stateError.ContactName} />
            </div>
            <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
              <SelectUnit onChange={handle_change} value={state.Country} name='Country'
                label='Country' required={true} isError={stateError.Country} options={countries} />
            </div>
            <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
              <InputTextUnit onChange={handle_change} value={state.ContactEmail} name='ContactEmail'
                label='Email' required={true} isError={stateError.ContactEmail} />
            </div>
            <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
              <InputTextUnit onChange={handle_change} value={state.ContactPhone} name='ContactPhone'
                label='Phone' required={true} isError={stateError.ContactPhone} />
            </div>
            <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
              <InputTextUnit onChange={handle_change} value={state.CompanyNameEn} name='CompanyNameEn'
                label='Company Name' required={true} isError={stateError.CompanyNameEn} />
            </div>
            <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
              <SelectUnit onChange={handle_change} value={state.ContactPosition} name='ContactPosition'
                label='Job title' required={true} isError={stateError.ContactPosition} options={lst_job_title} />
            </div>
            <div className='w-full mt-2 px-2 sm:px-4'>
              <label className='required font-semibold alt-font'>Purposes of visit</label>
              <AutocompleteInput multiple options={lst_purpose} id="purpose_of_visit" className={(stateError.Purposes ? "border-red-600" : "")}
                selected={Purposes} onChange={(e) => { setSelected((prev) => ({ ...prev, Purposes: e })) }} />
              <ValidateMessage msgType="required" isShow={stateError.Purposes} />
            </div>
            <div className='w-full mt-2 px-2 sm:px-4'>
              <label className='required font-semibold alt-font'>Main area of product interest</label>
              <AutocompleteInput multiple options={lst_product} id="product_interest" className={(stateError.SearchProducts ? "border-red-600" : "")}
                selected={SearchProducts} onChange={(e) => { setSelected((prev) => ({ ...prev, SearchProducts: e })) }} />
              <ValidateMessage msgType="required" isShow={stateError.SearchProducts} />
            </div>
            <div className='w-full mt-2 px-2 sm:px-4'>
              <label className='required font-semibold alt-font'>The material types interest</label>
              <AutocompleteInput multiple options={lst_material} id="material_interest" className={(stateError.Materials ? "border-red-600" : "")}
                selected={Materials} onChange={(e) => { setSelected((prev) => ({ ...prev, Materials: e })) }} />
              <ValidateMessage msgType="required" isShow={stateError.Materials} />
            </div>
            <div className='w-full mt-2 px-2 sm:px-4'>
              <label className='required font-semibold alt-font'>How did you find out our exhibition?</label>
              <AutocompleteInput multiple options={lst_channel} id="lst_channel" className={(stateError.Channels ? "border-red-600" : "")}
                selected={Channels} onChange={(e) => { setSelected((prev) => ({ ...prev, Channels: e })) }} />
              <ValidateMessage msgType="required" isShow={stateError.Channels} />
            </div>
            <div className='w-full mt-2 px-2 sm:px-4'>
              <div className='flex items-center'>
                <h3 className='w-2/3 text-2xl font-bold my-4 text-gray-800'>ACCOMPANY PERSONS</h3>
                <div className='w-1/3 text-right'>
                  <button type='button' onClick={handle_add_person}>
                    <span className='inline-block p-5 fs-color-secondary text-2xl'><FontAwesomeIcon icon={faPlus} /></span>
                  </button>
                </div>
              </div>
            </div>
            {state.VisitorMembers.length > 0 && (
              <>
                {state.VisitorMembers.map((item, i) => {
                  return (
                    <div className='accompay-person w-full block sm:flex sm:flex-wrap'>
                      <div className='line-devide w-full px-2 sm:px-4 hidden'>
                        <div className='my-5 border-t border-solid border-gray-500'></div>
                      </div>
                      <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
                        <InputTextUnit onChange={(e) => { handle_change_sub(e, item.Id) }} value={item.MemberName} name={"MemberName"}
                          label='Full Name' required={false} isError={false} />
                      </div>
                      <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
                        <SelectUnit onChange={(e) => { handle_change_sub(e, item.Id) }} value={item.Country} name={"Country"}
                          label='Country' required={false} isError={false} options={countries} />
                      </div>
                      <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
                        <InputTextUnit onChange={(e) => { handle_change_sub(e, item.Id) }} value={item.Phone} name={"Phone"}
                          label='Phone' required={false} isError={false} />
                      </div>
                      <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
                        <InputTextUnit onChange={(e) => { handle_change_sub(e, item.Id) }} value={item.Email} name={"Email"}
                          label='Email' required={false} isError={false} />
                      </div>
                      {/* <div className='w-full px-2 sm:px-4'>
                          <div className='my-5 border-t border-solid border-gray-500'></div>
                        </div> */}
                    </div>
                  )
                })}
              </>
            )}
            <div className='w-full mt-2 px-2 sm:px-4'>
              <div className='my-5 border-t border-solid border-gray-500'></div>
              <div >
                <button type='button' onClick={handle_submit} disabled={isSuccess}
                  className='inline-flex w-32 justify-center rounded-2xl text-white py-1 px-2 box-border text-lg bg-orange-500 hover:bg-orange-400 disabled:bg-slate-500'>Submit</button>
              </div>
              {isError && (
                <span className='text-red-600 py-3 block'>{msg}</span>
              )}
              {isSuccess && (
                <span className='text-green-600 py-3 block'>{msg}</span>
              )}
            </div>
          </div>
        </div>
      </div>

      {onSearch && (
        <div className='absolute inset-0 p-2 bg-white'>
          <AdminVisitorSearch onEdit={handle_edit} is_show={onSearch} />
        </div>
      )}
    </>
  )
}

export default AdminBuyerVisitor