import React, { useState, useEffect, useRef } from 'react'
import { redirect, useLoaderData } from 'react-router-dom';
import InputTextUnit from '../../../components/controls/input-text-unit'
import SelectUnit from '../../../components/controls/select-unit';
import CommonHelper from '../../../helpers/common';
import AutocompleteInput from '../../../components/controls/autocomplete-input';
import ValidateMessage from '../../../components/controls/validate-msg';
import TextAreaInput from '../../../components/controls/textarea-input';
import Authentication from '../../../api/authApi';
import Loading from '../../../components/controls/loading';
import exhibitorApi from '../../../api/exhibitorApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlug, faPlus, faTimes, faUpload } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { authActions } from '../../../_store';
import { exhibitorActions } from '../../../_store';


const certificates = ['FSC CoC', 'FSC FM', 'PEFC CoC', 'PEFC FM', 'ISO 9001', 'ISO 14001', 'IOS 45001', 'BSCI', 'SMETA', 'SA 8000',
  'CARB EPA P2', 'CTPAT', 'CITES', 'GREENGUARD', 'IAQ', 'ZWL', 'O%VOC', 'Fit for Food', 'Toys standard', 'Others'];
const product_type = [
  /*'Nội thất phòng khách', 'Nội thất phòng ăn & nhà bếp', 'Nội thất phòng tắm', 'Nội thất văn phòng', 'Ngoại thất & sân vườn', 'Nội thất dành cho trẻ em',
  'Nội thất dành cho trẻ em', 'Nội thất khách sạn và dự án', 'Đồ thủ công mỹ nghệ', 'Phụ kiện trang trí nội thất',
  'Hệ thống & công nghệ xây dựng', 'Giải pháp và phần mềm công nghệ cao', 'Dịch vụ chứng chỉ chuyển đổi số',
  'Hệ thống an ninh', 'Nhà thông minh, Nhà máy thông minh', "Kính, cửa, cửa sổ", "Gạch lát nền, đá ốp lát", "Vật liệu mái, tường, trần, sàn", "Sơn nước", "Vật tư ngoại thất: Thép, sắt, gạch, gỗ, vữa, nhôm", "Thiết bị vệ sinh, thiết bị phòng tắm",
  "Thiết bị ngoài trời và hồ bơi", "Ống và phụ tùng, dây điện", "Hóa chất, phụ gia", "Công cụ, dụng cụ",*/
  'Sản phẩm nội thất/ Furniture',
  'Cảnh quan, sân vườn/ Landscape, Garden',
  'Trang trí nội thất/ Interior Decoration',
  'Gỗ & Ván công nghiệp/ Lumber & Panel',
  'Thiết bị vệ sinh/ Bath products and accessories',
  'Thiết bị và phụ kiện bếp', 'Đèn, Giải pháp chiếu sáng/ Lamps and Lighting Fixtures',
  'Thảm, rèm, giấy dán tường/ Carpets, Curtains & Wallcoverings',
  'Thủ công mỹ nghệ & Quà tặng/ Handicrafts & Gifts',
  'Gạch, đá ốp lát/ Stone & Tiles',
  'Vật liệu mái, tường, trần, sàn/ Floor, Wall, Laminates & Ceiling Materials',
  'Công cụ, dụng cụ/ Tools and Hardware',
  'Sơn, hoàn thiện bề mặt/ Coatings and Surface Finishes',
  'Keo, hóa chất, phụ gia/ Adhesives, Chemicals, and Additives',
  'Kính, cửa, cửa sổ/ Window, Door & Glass',
  'Ống và phụ tùng, dây điện/ Pipes and Fittings, Electrical Wires',
  'Vật liệu thô: Xi mặng, sắt thép/ Construction materials: Cement, Steel',
  'Nhà thầu xây dựng, Văn phòng kiến trúc/ Building Contractors & Architectural Firms ',
  'Dự án bất động sản phong cách/ Real estate',
  'Công nghệ/ Technology',
  'Others/ Khác',

]
/*['Office Furniture', 'Living Room Furniture', 'Dining Room Furniture', 'Bedroom Furniture', 'Kitchen Furniture', 'Bathroom Furniture',
'Outdoor Garden Furniture', 'Children’s Furniture', 'Hospitalities & Project Furniture', 'Interior Decoration', 'Handicraft',
'Bamboo Rattan Product', 'Upholstery Leather', 'Carpet & Flooring', 'Hardware & Accessories', 'Gift', 'Wood material Panels', 'Sawn timber KD & Logs',
'Oil-based Coating', 'Others']*/
const main_material = ['Acacia', 'Rubber Wood', 'Cedar', 'Teak', 'Walnut', 'Oak', 'Ash', 'Pine', 'Poplar', 'Birch', 'Beech', 'Hemlock',
  'Particle Boad', 'MFC', 'MDF', 'HDF', 'Plywood', 'Melamine', 'Laminate', 'Acrylic', 'Veener', 'Solid surface', 'Compact HPL', 'PVC',
  'Foam', 'Textile Fabric', 'Leather', 'Silicon fiber', 'Bamboo', 'Rattan, Seagrass', 'PE fiber', 'PP fiber', 'PVC fiber', 'HDPE fiber',
  'Rope', 'Fiber', 'Brass, Bronze, Copper', 'Steel', 'Chrome', 'Iron', 'Aluminum', 'Inox', 'Fiberglass', 'Stone', 'Metal', 'Eucalyptus',
  'Clay', 'Glaze', 'Cement', 'Poly', 'Oil-based Coating', 'Western Hemlock', 'Spruce-Pine-FIR', 'Douglas-Fir', 'Western Red Cedar', 'Yellow Cedar', 'Others'];
const markets = ['Vietnam', 'USA', 'Japan', 'China', 'South Korean', 'UK', 'Canada', 'Australia', 'France', 'Germany', 'Holland', 'Taiwan', 'Malaysia', 'Others'];
// 'Europe', 'North America', 'Middle East', 'New Zealand',
const countries = ['Vietnam', 'Afghanistan', 'Albania', 'Algeria', 'American Samoa', 'Andorra', 'Angola', 'Anguilla', 'Antarctica', 'Antigua and Barbuda', 'Argentina',
  'Armenia', 'Aruba', 'Australia', 'Austria', 'Azerbaijan', 'Bahamas', 'Bahrain', 'Bangladesh', 'Barbados', 'Belarus', 'Belgium', 'Belize', 'Benin',
  'Bermuda', 'Bhutan', 'Bolivia (Plurinational State of)', 'Bonaire, Saint Eustatius and Saba', 'Bosnia and Herzegovina', 'Botswana', 'Bouvet Island',
  'Brazil', 'British Indian Ocean Territory', 'Brunei Darussalam', 'Bulgaria', 'Burkina Faso', 'Burundi', 'Cabo Verde', 'Cambodia', 'Cameroon',
  'Canada', 'Cayman Islands', 'Central African Republic', 'Chad', 'Chile', 'China', 'Christmas Island', 'Cocos (Keeling) Islands', 'Colombia',
  'Comoros', 'Congo', 'Congo (Democratic Republic of the)', 'Cook Islands', 'Costa Rica', 'Croatia', 'Cuba', 'Curaçao', 'Cyprus', 'Czech Republic',
  "Côte d'Ivoire", 'Denmark', 'Djibouti', 'Dominica', 'Dominican Republic', 'Ecuador', 'Egypt', 'El Salvador', 'Equatorial Guinea', 'Eritrea',
  'Estonia', 'Eswatini (Kingdom of)', 'Ethiopia', 'Falkland Islands (Malvinas)', 'Faroe Islands', 'Fiji', 'Finland', 'France', 'French Guiana',
  'French Polynesia', 'French Southern Territories', 'Gabon', 'Gambia', 'Georgia', 'Germany', 'Ghana', 'Gibraltar', 'Greece', 'Greenland', 'Grenada',
  'Guadeloupe', 'Guam', 'Guatemala', 'Guernsey', 'Guinea', 'Guinea-Bissau', 'Guyana', 'Haiti', 'Heard Island and McDonald Islands', 'Honduras', 'Hong Kong',
  'Hungary', 'Iceland', 'India', 'Indonesia', 'Iran (Islamic Republic of)', 'Iraq', 'Ireland (Republic of)', 'Isle of Man', 'Israel', 'Italy', 'Jamaica',
  'Japan', 'Jersey', 'Jordan', 'Kazakhstan', 'Kenya', 'Kiribati', "Korea (Democratic People's Republic of)", 'Korea (Republic of)', 'Kosovo', 'Kuwait',
  'Kyrgyzstan', "Lao People's Democratic Republic", 'Latvia', 'Lebanon', 'Lesotho', 'Liberia', 'Libya', 'Liechtenstein', 'Lithuania', 'Luxembourg',
  'Macao', 'Madagascar', 'Malawi', 'Malaysia', 'Maldives', 'Mali', 'Malta', 'Marshall Islands', 'Martinique', 'Mauritania', 'Mauritius', 'Mayotte',
  'Mexico', 'Micronesia (Federated States of)', 'Moldova (Republic of)', 'Monaco', 'Mongolia', 'Montenegro', 'Montserrat', 'Morocco', 'Mozambique',
  'Myanmar', 'Namibia', 'Nauru', 'Nepal', 'Netherlands', 'New Caledonia', 'New Zealand', 'Nicaragua', 'Niger', 'Nigeria', 'Niue', 'Norfolk Island',
  'North Macedonia (Republic of)', 'Northern Mariana Islands', 'Norway', 'Oman', 'Pakistan', 'Palau', 'Palestine (State of)', 'Panama', 'Papua New Guinea',
  'Paraguay', 'Peru', 'Philippines', 'Pitcairn', 'Poland', 'Portugal', 'Puerto Rico', 'Qatar', 'Romania', 'Russian Federation', 'Rwanda', 'Réunion',
  'Saint Barthélemy', 'Saint Helena, Ascension and Tristan da Cunha', 'Saint Kitts and Nevis', 'Saint Lucia', 'Saint Martin (French part)',
  'Saint Pierre and Miquelon', 'Saint Vincent and the Grenadines', 'Samoa', 'San Marino', 'Sao Tome and Principe', 'Saudi Arabia', 'Senegal',
  'Serbia', 'Seychelles', 'Sierra Leone', 'Singapore', 'Sint Maarten (Dutch part)', 'Slovakia', 'Slovenia', 'Solomon Islands', 'Somalia',
  'South Africa', 'South Georgia and the South Sandwich Islands', 'South Sudan', 'Spain', 'Sri Lanka', 'Sudan', 'Suriname', 'Svalbard and Jan Mayen',
  'Sweden', 'Switzerland', 'Syrian Arab Republic', 'Taiwan, Republic of China', 'Tajikistan', 'Tanzania (United Republic of)', 'Thailand', 'Timor-Leste',
  'Togo', 'Tokelau', 'Tonga', 'Trinidad and Tobago', 'Tunisia', 'Turkmenistan', 'Turks and Caicos Islands', 'Tuvalu', 'Türkiye', 'Uganda', 'Ukraine',
  'United Arab Emirates', 'United Kingdom of Great Britain and Northern Ireland', 'United States Minor Outlying Islands', 'United States of America',
  'Uruguay', 'Uzbekistan', 'Vanuatu', 'Vatican City State', 'Venezuela (Bolivarian Republic of)', 'Virgin Islands (British)',
  'Virgin Islands (U.S.)', 'Wallis and Futuna', 'Western Sahara', 'Yemen', 'Zambia', 'Zimbabwe', 'Åland Islands',];
const type_of_business = ['Manufacturer', 'Trader', 'Hospitality Contractors', 'Supplier (Materials & Hardware)', 'Service Provider', 'Other',];
const initial = {
  "AppSite": process.env.REACT_APP_CODE,
  SeasonId: 0,
  ExhibitorId: "",
  CompanyNameEn: "",
  AddressEn: "",
  FactoryAddressEn: "",
  Website: "",
  Country: "",
  ShortName: "",
  CompanyNameVn: "",
  CompanyPhone: "",
  CompanyEmail: "",
  AddressVn: "",
  Logo: "",
  ContactName: "",
  ContactTitle: "",
  ContactPosition: "",
  ContactEmail: "",
  ContactPhone: "",
  NumberOfEmployees: 0,
  TypeOfBusiness: "",
  ProductTypes: "",
  KeyProducts: "",
  Certificates: "",
  ProductionFacilities: "",
  MainMaterial: "",
  ExportMarkets: "",
  CapacityOfFactory: "",
  MOQ: "",
  Description: "",
  PromotionAtFair: "0",
  SocialNetwork: "",
  //CustomOrder: "",
  //UseCustomOrder: "",
  Activated: false,
  "Area": 0,
  "Avenue": "1",
  "BoothNo": "",
  "Hall": "",
  //AccountId: "",
  //Password: "",
  //QRCode: "",
  "CompanyFax": "",
  "Properties": 0,
  "ExtraValue1": "",
  "ExtraValue2": "",
  "ExtraValue3": "",
  "ExtraValue4": "",
  "ExtraValue5": "",
  Hall: "",
  FasciaBoard: "",
  SocialContact: [
    { label: "Trang facebook/ Facebook fanpage address", name: "Facebook", content: "" },
    { label: "Số điện thoại Viber/ Viber company number", name: "Viber", content: "" },
    { label: "Số điện thoại Whatsapp/ Whatsapp company number", name: "Whatsapp", content: "" },
    { label: "ID Skype/ Skype company ID", name: "Skype", content: "" },
    { label: "Số Wechat/ Wechat ID", name: "Wechat", content: "" },
    { label: "Địa chỉ Linkedin/ LinkedIn profile address", name: "Linkedin", content: "" },
  ],
  ArgeeTerm: false,
  ExhibitorImages: [],
}
function ExhibitorRegister() {
  const dispatch = useDispatch();
  const [state, setState] = useState(initial);
  //const exhibitorData = useLoaderData();
  const fileUpload = useRef(null);
  const uploadProductImage = useRef(null);

  //dispatch(authActions.check_session());
  const [{ ProductTypes, Certificates, MainMaterials, ExportMarkets }, setSelected] = useState({
    ProductTypes: [],
    Certificates: [],
    MainMaterials: [],
    ExportMarkets: []
  })
  const [{ stateError, isLoading, isError, isSuccess, error_msg, errorMsg, lst_venues }, setCommonState] = useState({
    stateError: {
      CompanyNameEn: false,
      CompanyNameVn: false,
      CompanyPhone: false,
      CompanyEmail: false,
      ContactPhone: false,
      ContactEmail: false,
      ContactName: false,
      AddressEn: false,
      //FactoryAddressEn: false,
      //Country: false,
      //TypeOfBusiness: false,
      ContactEmail: false,
      ProductTypes: false,
      Certificates: false,
      KeyProducts: false,
      //ProductionFacilities: false,
      //MainMaterial: false,
      //ExportMarkets: false,
      //CapacityOfFactory: false,
      //MOQ: false,
      Description: false,
      //PromotionAtFair: false,
      BoothNo: false,
    },
    errorMsg: {
      CompanyNameEn: "",
      CompanyNameVn: "",
      AccountId: "",
    },
    lst_venues: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    error_msg: ""
  })
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      ProductTypes: JSON.stringify(ProductTypes),
      Certificates: JSON.stringify(Certificates),
      MainMaterial: JSON.stringify(MainMaterials),
      ExportMarkets: JSON.stringify(ExportMarkets),
    }))
    /*setCommonState((prev) => ({
      ...prev,
      stateError: {
        ...stateError,
        ProductTypes: ProductTypes.length == 0,
        Certificates: Certificates.length == 0,
        MainMaterial: MainMaterials.length == 0,
        ExportMarkets: ExportMarkets.length == 0
      }
    }))*/
  }, [ProductTypes, Certificates, MainMaterials, ExportMarkets])
  useEffect(() => {
    //setCommonState((prev) => ({ ...prev, isLoading: true }));
    /*var get_user = CommonHelper.get_user()
    get_user.then((res) => {
      if (res != null) {
        var response = exhibitorApi.get({
          exhibitorId: res.exhibitorId,
        });
        response.then((res) => {
          if (res != null) {
            if (res.code == 200) {
              setData(res.data);
            } else {
              window.location.href = "/login"
            }
          } else {
            window.location.href = "/login"
          }
        })
      } else {
        window.location.href = "/login"
      }
    })*/
    //get_venues(process.env.REACT_APP_CODE)
  }, []);

  /*const get_venues = () => {
    setCommonState((prev) => ({ ...prev, isLoading: true }));
    var response = exhibitorApi.get_venues();
    response.then((res) => {
      var _lst = [...lst_venues]
      if (res != null && res.code == 200) {
        res.data.forEach((item) => {
          _lst.push({ code: item.venueId, name: item.locationAbbr });
        })
      }
      setCommonState((prev) => ({
        ...prev,
        isLoading: false,
        lst_venues: _lst,
      }));
    })
  }*/
  const handle_change = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }))
    validate_required(e);
    if (["Facebook", "Viber", "Whatsapp", "Linkedin", "Wechat",].indexOf(name) >= 0) {
      var _lst = [...state.SocialContact];
      _lst.forEach((item) => {
        if (item.name == name) {
          item.content = value;
        }
      })
      //console.log(_lst);
      setState((prev) => ({
        ...prev,
        SocialContact: _lst,
      }))
    }
  }
  const validate_required = (e) => {
    const { name, value } = e.target;
    var _arr = [];
    Object.keys({ ...stateError }).map((item) => {
      _arr.push(item);
    })
    var _idx = _arr.indexOf(name);
    if (_idx >= 0) {
      setCommonState((prev) => ({
        ...prev,
        stateError: { ...stateError, [name]: CommonHelper.is_empty(value) },
      }))
    }
  }
  const validate_form = () => {
    var flag = true;
    var _arr = [];
    Object.keys({ ...stateError }).map((item) => {
      _arr.push(item);
    })
    var _data = { ...state };
    //console.log(_data);
    var _objError = { ...stateError };
    _arr.forEach((item, i) => {
      if (CommonHelper.is_empty(_data[item])) {
        _objError[item] = true;
        flag = false;
      }
      //, 'ExportMarkets'
      if (['ProductTypes', 'Certificates', 'MainMaterial'].indexOf(item) >= 0) {
        if (_data[item] === "[]") {
          _objError[item] = true;
        }
      }
    })
    setCommonState((prev) => ({
      ...prev,
      stateError: _objError,
    }))
    return flag;
  }
  const handle_submit = () => {
    var _data = { ...state };
    _data.SocialNetwork = JSON.stringify(_data.SocialContact);
    //console.log(_data);
    if (validate_form()) {
      var booths = [{
        "venueId": CommonHelper.convertToInt(_data.VenueId),
        "boothNo": _data.BoothNo,
      }];
      _data = { ..._data, booths };
      setCommonState((prev) => ({ ...prev, isLoading: true }));
      var response = exhibitorApi.register(_data);
      response.then((res) => {
        setCommonState((prev) => ({ ...prev, isLoading: false }));
        if (res != null) {
          if (res.code == 202) {
            setCommonState((prev) => ({
              ...prev,
              error_msg: "Đăng ký thành công.",
              isSuccess: true,
              isError: false,
            }));
          } else if (res.code == 402) {
            setCommonState((prev) => ({
              ...prev,
              error_msg: "Đăng ký thất bại. Vui lòng liên hệ VIBE để được hỗ trợ!",
              isError: true,
              isSuccess: false,
            }));
            /*} else {
              setCommonState((prev) => ({ ...prev, isLoading: true }));
              alert("Session is expired. Please sign in again!")
              const timer = setTimeout(() => {
                window.location.href = "/login"
              }, 4000);
              return () => clearTimeout(timer);*/
          }
        }
      })
    } else {
      setCommonState((prev) => ({
        ...prev,
        error_msg: "Please check required fields!",
        isError: true
      }));
    }
  }
  const upload_logo = (e) => {
    const { name, files } = e.target;
    if (files != null && files.length > 0) {
      var _data = {
        exhibitor_id: state.ExhibitorId,
        file: files[0]
      };
      var response = exhibitorApi.upload_logo(_data);
      response.then((res) => {
        if (res != null && res.status === 200) {
          if (res.data.code === 202 && res.data.data != null) {
            setState((prev) => ({ ...prev, Logo: res.data.data.img_url }))
          }
        }
      })
    }
  };
  const handleUpload = () => {
    fileUpload.current.click()
  };
  const remove_logo = () => {
    setState((prev) => ({ ...prev, Logo: "" }))
  }
  const upload_product_image = (e) => {
    const { name, files } = e.target;
    if (files != null && files.length > 0) {
      var _lst = [...state.ExhibitorImages];
      var _data = {
        is_present: _lst.length > 0 ? 0 : 1,
        exhibitor_id: state.ExhibitorId,
        file: files[0]
      };
      setCommonState((prev) => ({ ...prev, isLoading: true }));
      var response = exhibitorApi.upload_image(_data);
      response.then((res) => {
        setCommonState((prev) => ({ ...prev, isLoading: false }));
        if (res.status === 200) {
          if (res.data.code === 202 && res.data.data != null) {
            //console.log(res.data.data);
            _lst.push({ id: res.data.data.img_id, url: res.data.data.img_url });
            setState((prev) => ({ ...prev, ExhibitorImages: _lst }));
          }
        }
      })
    }
  };
  const handleUploadProduct = () => {
    uploadProductImage.current.click()
  };
  const remove_product_image = (e) => {
    if (e != null) {
      var response = exhibitorApi.del_product_image(e.id);
      response.then((res) => {
        if (res != null && res.code == 203) {
          var _lst = [...state.ExhibitorImages].filter((item) => item.id != e.id);
          setState((prev) => ({ ...prev, ExhibitorImages: _lst }));
        }
      })
    }
  }
  const check_existing_name = (e) => {
    const { name, value } = e.target;
    var companyNameEn = state.CompanyNameEn;
    var companyNameVn = state.CompanyNameVn;
    if (CommonHelper.is_not_empty(value)) {
      if (name == "CompanyNameEn") {
        companyNameEn = value;
      } else if (name == "CompanyNameVn") {
        companyNameVn = value;
      }
      //dispatch(organizerActions.set_loading());
      var response = exhibitorApi.check_existing_exhibitor_name({ companyNameEn, companyNameVn });
      response.then((res) => {
        //console.log(res)
        //dispatch(organizerActions.set_loading());
        if (res != null) {
          if (res.code == 405) {
            setCommonState((prev) => ({
              ...prev,
              stateError: { ...stateError, [name]: true },
              errorMsg: { ...errorMsg, [name]: "Tên doanh nghiệp đã tồn tại. Vui lòng nhập tên khác!" }
            }))
          } else {
            setCommonState((prev) => ({
              ...prev,
              stateError: { ...stateError, [name]: false },
              errorMsg: { ...errorMsg, [name]: "" }
            }))
          }
        }
      })
    }
  }
  return (
    <div className='mx-auto max-w-7xl py-6 sm:px-6 lg:px-8'>
      <div className='w-full'>
        <img src={process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/banner.jpg"} className='mx-auto object-cover' />
      </div>
      <h1 className='text-center text-3xl font-bold text-color-primary mt-3 mb-2'>HỒ SƠ DOANH NGHIỆP/ EXHIBITOR’S PROFILE</h1>
      <h3 className='text-center text-2xl text-color-primary'>TRIỂN LÃM NỘI THẤT & XÂY DỰNG VIỆT NAM – VIETNAM INTERIOR & BUILD EXPO</h3>
      <div className='mt-5'>
        <p className='italic'>Vui lòng điền vào biểu mẫu bằng tiếng Việt/ Please fill in the form in Vietnamese</p>
        <div className='block sm:flex sm:flex-wrap'>
          <div className='w-full mt-2 px-2 sm:px-4'>
            <InputTextUnit onChange={handle_change} value={state.CompanyNameEn} name='CompanyNameEn'
              label='Tên Doanh nghiệp tiếng Anh/ Company name in English' required={true} isError={stateError.CompanyNameEn}
              onBlur={check_existing_name} errorMsg={errorMsg.CompanyNameEn} />
          </div>
          <div className='w-full mt-2 px-2 sm:px-4'>
            <InputTextUnit onChange={handle_change} value={state.CompanyNameVn} name='CompanyNameVn'
              label='Tên Doanh nghiệp tiếng Việt/ Company name in Vietnamese' required={true} isError={stateError.CompanyNameVn} />
          </div>
          <div className='w-full hidden mt-2 px-2 sm:px-4'>
            <h3 className='text-2xl font-bold my-4 text-gray-800'>Booth Information:</h3>
          </div>
          <div className='w-full hidden mt-2 px-2 sm:px-4 sm:w-1/2'>
            <SelectUnit onChange={handle_change} value={state.VenueId} name='VenueId'
              label='Venues' required={false} options={lst_venues} />
          </div>
          <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
            <InputTextUnit onChange={handle_change} value={state.BoothNo} name='BoothNo'
              label='Vị trí gian hàng/ Booth No. (Hall – Số gian)' required={true} isError={stateError.BoothNo} />
          </div>
          <div className='w-full mt-2 px-2 sm:px-4'>
            <InputTextUnit onChange={handle_change} value={state.AddressEn} name='AddressEn'
              label='Địa chỉ trụ sở chính/ Headquarter address' required={true} isError={stateError.AddressEn} />
            <span className='inline-block italic'>(Số, tên đường, huyện, quận, tỉnh, thành phố, quốc gia)</span>
          </div>
          <div className='w-full mt-2 px-2 sm:px-4'>
            <InputTextUnit onChange={handle_change} value={state.CompanyPhone} name='CompanyPhone'
              label='Số điện thoại doanh nghiệp' required={true} isError={stateError.CompanyPhone} />
          </div>
          <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
            <InputTextUnit onChange={handle_change} value={state.CompanyEmail} name='CompanyEmail'
              label='Địa chỉ email/ Email' required={true} isError={stateError.CompanyEmail} />
          </div>
          <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
            <InputTextUnit onChange={handle_change} value={state.Website} name='Website'
              label='Website' isError={false} />
          </div>
          <div className='w-full mt-2 px-2 sm:px-4'>
            <InputTextUnit onChange={handle_change} value={state.FasciaBoard} name='FasciaBoard'
              label='Bảng tên gian hàng tiêu chuẩn/ Standard Booth fascia board' isError={false} />
          </div>
          <div className='w-full hidden mt-2 px-2 sm:px-4 sm:w-1/2'>
            <InputTextUnit onChange={handle_change} value={state.ProductionFacilities} name='ProductionFacilities'
              label='Production facilities (m2)' required={false} isError={false} />
          </div>
          <div className='w-full hidden mt-2 px-2 sm:px-4'>
            <InputTextUnit onChange={handle_change} value={state.FactoryAddressEn} name='FactoryAddressEn'
              label='Factory address' required={false} isError={false} />
          </div>
          <div className='w-full mt-2 px-2 sm:px-4'>
            <label className='required font-semibold'>Ngành hàng/ Industry information</label>
            <AutocompleteInput multiple options={product_type} id="product_types" className={(stateError.ProductTypes ? "border-red-600" : "")}
              selected={ProductTypes} onChange={(e) => { setSelected((prev) => ({ ...prev, ProductTypes: e })) }} />
            <ValidateMessage msgType="required" isShow={stateError.ProductTypes} />
          </div>
          <div className='w-full mt-2 px-2 sm:px-4'>
            <InputTextUnit onChange={handle_change} value={state.KeyProducts} name='KeyProducts'
              label='Sản phẩm chính/ Main product' required={true} isError={stateError.KeyProducts} />
          </div>
          <div className='w-full hidden mt-2 px-2 sm:px-4 sm:w-1/2'>
            <label className='required font-semibold'>Thị trường chính</label>
            <AutocompleteInput multiple options={markets} id="markets"
              selected={ExportMarkets} onChange={(e) => { setSelected((prev) => ({ ...prev, ExportMarkets: e })) }}
              className={(stateError.ExportMarkets ? "border-red-600" : "")} />
            {/* <ValidateMessage msgType="required" isShow={stateError.ExportMarkets} /> */}
          </div>
          <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
            <label className='font-semibold'>Chứng nhận/ Certificates: (Nếu có/If any)</label>
            <AutocompleteInput multiple options={certificates} id="certificates"
              selected={Certificates} onChange={(e) => { setSelected((prev) => ({ ...prev, Certificates: e })) }} />
            {/* <ValidateMessage msgType="required" isShow={stateError.Certificates} /> */}
          </div>
          <div className='w-full mt-2 px-2 sm:px-4'>
            <label className='required font-semibold'>Giới thiệu về công ty/ Company description</label>
            <TextAreaInput name="Description" className={"h-32 resize-none " + (stateError.Description ? "border-red-600" : "")}
              value={state.Description} onChange={handle_change} />
            <ValidateMessage msgType="required" isShow={stateError.Description} />
            <span className='text-gray-700 text-xs font-semibold'> (Tối đa 100 từ/ maximum 100 words)</span>
          </div>
          <div className='w-full hidden mt-2 px-2 sm:px-4 sm:w-1/2'>
            <SelectUnit onChange={handle_change} value={state.Country} name='Country'
              label='Country' required={false} isError={false} options={countries} />
          </div>
          <div className='w-full hidden mt-2 px-2 sm:px-4 sm:w-1/2'>
            <SelectUnit onChange={handle_change} value={state.TypeOfBusiness} name='TypeOfBusiness'
              label='Type of business' required={false} isError={false} options={type_of_business} />
          </div>
          <div className='w-full hidden mt-2 px-2 sm:px-4 sm:w-1/2'>
            <label className={'block font-semibold required'}>Promotion at the Fair</label>
            <div className='flex items-center'>
              <label className='inline-block mr-3'>
                <input type='radio' name='PromotionAtFair' value="1" checked={state.PromotionAtFair === "1"} onClick={handle_change} /> Yes
              </label>
              <label className='inline-block'>
                <input type='radio' name='PromotionAtFair' value="2" checked={state.PromotionAtFair === "2"} onClick={handle_change} /> No
              </label>
            </div>
            <ValidateMessage msgType="required" isShow={stateError.PromotionAtFair} />
          </div>
          <div className='w-full hidden mt-2 px-2 sm:px-4 sm:w-1/2'>
            <InputTextUnit onChange={handle_change} value={state.NumberOfEmployees} name='NumberOfEmployees'
              label='Number of employees' isError={false} />
          </div>
          <div className='w-full hidden mt-2 px-2 sm:px-4 sm:w-1/2'>
            <label className='required font-semibold'>Main material</label>
            <AutocompleteInput multiple options={main_material} id="main_material"
              selected={MainMaterials} onChange={(e) => { setSelected((prev) => ({ ...prev, MainMaterials: e })) }} />
            {/* className={(stateError.MainMaterial ? "border-red-600" : "")}  */}
            <ValidateMessage msgType="required" isShow={stateError.MainMaterial} />
          </div>
          <div className='w-full hidden mt-2 px-2 sm:px-4 sm:w-1/2'>
            <InputTextUnit onChange={handle_change} value={state.CapacityOfFactory} name='CapacityOfFactory'
              label='Capacity of factory (containers/month)' required={false} isError={false} />
          </div>
          <div className='w-full hidden mt-2 px-2 sm:px-4 sm:w-1/2'>
            <InputTextUnit onChange={handle_change} value={state.MOQ} name='MOQ'
              label='MOQ (minimum order quantity)' required={false} isError={false} />
          </div>
          <div className='w-full mt-2 px-2 sm:px-4'>
            <h3 className='text-2xl font-bold my-4 text-gray-800'>Thông tin người liên hệ</h3>
          </div>
          <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
            <InputTextUnit onChange={handle_change} value={state.ContactName} name='ContactName'
              label='Họ và tên/ Full name' required={true} isError={stateError.ContactName} />
          </div>
          <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
            <InputTextUnit onChange={handle_change} value={state.ContactPhone} name='ContactPhone'
              label='Số điện thoại/ Phone' required={true} isError={stateError.ContactPhone} />
          </div>
          <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
            <InputTextUnit onChange={handle_change} value={state.ContactEmail} name='ContactEmail'
              label='Địa chỉ email/ Email' required={true} isError={stateError.ContactEmail} />
          </div>
          <div className='w-full mt-2 px-2 sm:px-4'>
            <h3 className='text-2xl font-bold my-4 text-gray-800'>Các kênh liên hệ/ Social contacts:</h3>
            <span className='text-xs hidden font-semibold text-gray-800'>Please choose up to 3 channels and provide one contact detail for each one. This helps the visitor contact directly for prompt requests</span>
          </div>
          {state.SocialContact.map((item, i) => {
            return (
              <>
                <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2' key={i}>
                  <InputTextUnit onChange={handle_change} value={item.content} name={item.name}
                    label={item.label} isError={false} />
                </div>
              </>
            )
          })}
          <div className='w-full mt-2 px-2 sm:px-4'>
            <h3 className='text-2xl font-bold my-4 text-gray-800'>Logo Công ty/ Hình ảnh sản phẩm:</h3>
          </div>
          <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
            <label className='block w-full required font-semibold'>Logo</label>
            <div className='block lg:flex border border-dashed border-slate-500'>
              <div className='relative w-full lg:w-1/3'>
                <button type="button" className="bg-slate-300 w-32 h-32 text-center m-2 rounded-sm font-semibold box-border cursor-pointer" onClick={handleUpload}>
                  <FontAwesomeIcon icon={faPlus} />
                  <span className='mt-2 block'>Click để tải hình lên</span>
                </button>
                <input
                  type="file"
                  ref={fileUpload}
                  onChange={upload_logo}
                  className="absolute w-full opacity-0 inset-0 cursor-pointer"
                />
              </div>
              <div className='w-full lg:w-1/3 p-3'>
                <p>{`Loại hình PNG, loại bỏ nền và kích thước tối thiểu 300 x 300 (pixel). File hình < 2MB)`}</p>
              </div>
              <div className='w-full lg:w-1/3 px-3 relative'>
                {CommonHelper.is_not_empty(state.Logo) && (
                  <>
                    <div className='rounded-sm overflow-hidden w-full h-32 m-2 flex items-center flex-nowrap'>
                      <img src={process.env.REACT_APP_EXHIBITOR_IMAGE + state.Logo} className='w-full object-cover' />
                    </div>
                    <a className='absolute cursor-pointer top-1 right-1' onClick={remove_logo}>
                      <FontAwesomeIcon icon={faTimes} />
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
            <label className='block w-full required font-semibold'>Hình sản phẩm</label>
            <div className=' border border-dashed border-slate-500'>
              <div className='block lg:flex'>
                <div className='relative w-full lg:w-1/3'>
                  <button type="button" className="bg-slate-300 w-32 h-32 text-center m-2 rounded-sm font-semibold box-border cursor-pointer" onClick={handleUploadProduct}>
                    <FontAwesomeIcon icon={faPlus} />
                    <span className='mt-2 block'>Click để tải hình lên</span>
                  </button>
                  <input
                    type="file"
                    ref={uploadProductImage}
                    onChange={upload_product_image}
                    className="absolute w-full opacity-0 inset-0 cursor-pointer"
                  />
                </div>
                <div className='w-full lg:w-2/3 p-3'>
                  <p>{`Tối đa 05 hình sản phẩm. Kích thước khuyến nghị 1920 x 1080 (pixel)`}</p>
                </div>
              </div>
              {state.ExhibitorImages.length > 0 && (
                <div className='w-full mt-5 mb-2 px-2 flex flex-wrap'>
                  {state.ExhibitorImages.map((item, i) => {
                    return (
                      <div className='relative mt-2' key={i}>
                        <div className='rounded-lg overflow-hidden bg-slate-200 w-32 h-32 me-2 flex items-center flex-nowrap'>
                          <img src={process.env.REACT_APP_EXHIBITOR_IMAGE + item.url} className='w-full object-cover' />
                        </div>
                        <a className='absolute cursor-pointer top-1 right-1' onClick={() => { remove_product_image(item) }}>
                          <FontAwesomeIcon icon={faTimes} />
                        </a>
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
          <div className='w-full mt-10 px-2 sm:px-4'>
            <label className='required'>
              <input type='checkbox' checked={state.ArgeeTerm} onChange={(e) => { setState((prev) => ({ ...prev, ArgeeTerm: e.target.checked })) }} />
              <span className='ms-2 font-semibold'>Chúng tôi đồng ý các điều khoản bên dưới:</span>
            </label>
            <p className='mt-3 py-3'>Đơn vị triển lãm cam kết tất cả thông tin trên là chính xác để Ban tổ chức sử dụng trong các hoạt động truyền thông quảng bá cho VIBE 2024/ Exhibitors commit all the above information is correct for the Organizer to use for the promotional communication activities for VIBE 2024.</p>
            <div>
              <button type='button' onClick={handle_submit} disabled={!state.ArgeeTerm}
                className='inline-flex w-32 justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-color-primary shadow-sm ring-1 ring-inset bg-slate-300'>Submit</button>
            </div>
            {isError && (
              <span className='text-red-600 py-3 block'>{error_msg}</span>
            )}
            {isSuccess && (
              <span className='text-green-600 py-3 block'>{error_msg}</span>
            )}
          </div>
        </div>
      </div>
      {isLoading && (<Loading />)}
    </div>
  )
}

export default ExhibitorRegister