import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Authentication from "../api/authApi";
import CommonHelper from "../helpers/common";

const name = "exhibitor";
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

export const exhibitorActions = { ...slice.actions, ...extraActions };
export const exhibitorReducer = slice.reducer;
function createInitialState() {
    return {
        [name]: {}
    }
}

function createExtraActions() {

    return {
        get_exhibitor: get_exhibitor()
    }

    function get_exhibitor() {
        return createAsyncThunk(
            `${name}/get_exhibitor`,
            async () => await Authentication.exhibitor_user()
        )
    }
}

function createExtraReducers() {
    /*return {
        ...get_exhibitor()
    };

    function get_exhibitor() {
        console.log(extraActions)
        var { pending, fulfilled, rejected } = extraActions.get_exhibitor;
        return {
            [pending]: (state) => {
                state.users = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.users = action.payload;
            },
            [rejected]: (state, action) => {
                state.users = { error: action.error };
            }
        };
    }*/
}
