import './App.css';
import { useState, useEffect, useLayoutEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faChevronDown, faEnvelope, faLocationDot, faMapMarker, faPhone, faUser } from '@fortawesome/free-solid-svg-icons';
import { faClock, faHandPointer } from '@fortawesome/free-regular-svg-icons';
import Authentication from './api/authApi'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { faFacebook, faInstagram, faLinkedin, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import Menu from './components/menu';
import MobileMenu from './components/mobile-menu';
import Footer from './components/footer';
import CommonHelper from './helpers/common';
import Account from './components/account';
import { UseSelector, useDispatch } from 'react-redux';
import { authActions } from './_store';

const logo_desc = process.env.REACT_APP_EXHIBITOR_IMAGE + '/images/vibeep.png'
const img3 = process.env.REACT_APP_EXHIBITOR_IMAGE + '/images/v1/trang-chu-Vibe-04.png'

function App() {
  const dispatch = useDispatch();
  const [{ sticky, isAuth }, setExtension] = useState({
    sticky: "",
  })
  useLayoutEffect(() => {
    window.addEventListener('scroll', (e) => {
      if (window.pageYOffset >= 92) {
        setExtension((prev) => ({
          ...prev,
          sticky: "sticky-active"
        }))
      } else {
        setExtension((prev) => ({
          ...prev,
          sticky: ""
        }))
      }
    })
  }, [])
  useEffect(() => {
    dispatch(authActions.check_token());
    //Authentication.check_token_expired();
    //Authentication.get_app();
    var get_user = CommonHelper.get_user();
    get_user.then((data) => {
      setExtension((prev) => ({
        ...prev,
        isAuth: data != null
      }))
    })
  }, [])
  const [{ menu }, setClick] = useState({
    menu: false
  })
  const handle_logout = () => {
    var response = Authentication.exhibitor_logout();
    response.then((res) => {
      if (res != null && res.code == 506) {
        window.location.href = "/"
      }
    })
  }
  return (
    <>
      <div className='min-h-full'>
        <nav className="shadow-lg">
          <div>
            <div className='bg-primary hidden md:block'>
              <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
                <div className='flex h-4 text-white'>
                </div>
              </div>
            </div>
            <div id='header-top' className={'w-full bg-white ' + sticky}>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between">
                  <div className="block w-1/6 lg:hidden">
                    <button type="button" className="relative inline-flex items-center justify-center text-2xl rounded-md" aria-controls="mobile-menu" aria-expanded="false"
                      onClick={() => { setClick((prev) => ({ ...prev, menu: !menu })) }}>
                      <FontAwesomeIcon icon={faBars} className='text-gray-600' />
                    </button>
                  </div>
                  <div className="block w-2/3 sm:flex items-center sm:w-full">
                    <div className="w-full lg:w-1/5">
                      <a href='/' className='block w-full text-center lg:text-left'>
                        <img src={logo_desc} alt="VIBE" className='w-32 md:w-44 inline-block' />
                      </a>
                    </div>
                    <div className="hidden lg:block lg:w-full">
                      <div className='flex flex-wrap items-center'>
                        <span className='inline-block w-1/5 pl-10 box-border'>
                          <img src={img3} className='object-cover' />
                        </span>
                        <div className='w-4/5'>
                          <div className='float-right'>
                            <Menu />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='block w-1/6 lg:hidden'></div>
                </div>
              </div>
            </div>
          </div>
          {menu && (<MobileMenu onClose={() => { setClick((prev) => ({ ...prev, menu: false })) }} />)}
        </nav>
        <main>
          <Outlet />
        </main>
        <footer>
          <Footer />
        </footer>
        <div className="hidden hotline-phone-ring-wrap">
          <div className="hotline-phone-ring">
            <div className="hotline-phone-ring-circle"></div>
            <div className="hotline-phone-ring-circle-fill"></div>
            <div className="hotline-phone-ring-img-circle size-24 flex items-center">
              <a href='/register' className='mx-auto font-semibold block text-center text-white'>ĐĂNG KÝ THAM QUAN</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
