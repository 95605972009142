import React, { useState, useEffect } from 'react'
import img14 from '../../assets/image-14.jpg'
import CommonHelper from '../../helpers/common'
import InputTextUnit from '../../components/controls/input-text-unit'
import Authentication from '../../api/authApi'
import Loading from '../../components/controls/loading'

function ForgotForm() {
    const [state, setState] = useState({
        AccountId: ""
    })
    const [{ stateError, isLoading, isError, isSuccess, msg }, setCommonState] = useState({
        stateError: {
            AccountId: false,
        },
        isLoading: false,
        isError: false,
        isSuccess: false,
        msg: ""
    })
    useEffect(() => {

    }, [])
    const handle_change = (e) => {
        const { name, value } = e.target;
        setState((prev) => ({
            ...prev,
            [name]: value,
        }))
        validate_required(e);
    }
    const validate_required = (e) => {
        const { name, value } = e.target;
        var _arr = [];
        Object.keys({ ...stateError }).map((item) => {
            _arr.push(item);
        })
        var _idx = _arr.indexOf(name);
        if (_idx >= 0) {
            setCommonState((prev) => ({
                ...prev,
                stateError: { ...stateError, [name]: CommonHelper.is_empty(value) },
            }))
        }
    }
    const validate_form = () => {
        var flag = true;
        var _arr = [];
        Object.keys({ ...stateError }).map((item) => {
            _arr.push(item);
        })
        var _data = { ...state };
        var _objError = { ...stateError };
        _arr.forEach((item, i) => {
            if (CommonHelper.is_empty(_data[item])) {
                _objError[item] = true;
                flag = false;
            }
        })
        setCommonState((prev) => ({
            ...prev,
            stateError: _objError,
        }))
        return flag;
    }
    const handle_submit = () => {
        var flag = validate_form()
        if (flag) {
            setCommonState((prev) => ({ ...prev, isLoading: true, isError: false }))
            console.log({ ...state })
            var response = Authentication.exhibitor_reset_pwd({ ...state });
            response.then((res) => {
                if (res != null) {
                    if (res.code == 206) {
                        setCommonState((prev) => ({
                            ...prev,
                            msg: "New password has been sent to your email. Please check your email!",
                            isSuccess: true,
                            isLoading: false
                        }))
                    } else {
                        setCommonState((prev) => ({
                            ...prev,
                            msg: "Your email is not exist. Please check your email!",
                            isError: true,
                            isLoading: false
                        }))
                    }
                }
            })
        } else {
            setCommonState((prev) => ({ ...prev, msg: "Please check required fields!", isError: true }))
        }
    }
    return (
        <>
            <div className='w-full sm:w-1/2 p-3'>
                <h1 className='font-bold text-lg text-center p-5'>Password Recovery</h1>
                <p >Forgot your account's password or having trouble logging into your account? Enter your email address and we'll send you a new password.</p>
                <div className='mt-3'>
                    <InputTextUnit onChange={handle_change} value={state.AccountId} name='AccountId'
                        label='Email' required={true} isError={stateError.AccountId} />
                </div>
                <div className='mt-3'>
                    {isError && (
                        <span className='text-red-600 py-3 block'>{msg}</span>
                    )}
                    {isSuccess && (
                        <span className='text-green-600 py-3 block'>{msg}</span>
                    )}
                </div>
                <div className='mt-3 text-center'>
                    <button type='button' disabled={isSuccess} className='mt-5 w-2/3 rounded-2xl text-white py-1 px-2 box-border text-lg'
                        style={{ background: "#fa6736", }} onClick={handle_submit}>
                        SEND</button>
                </div>
                <div className='mt-2 text-center'>
                    <a href='/login'>Back to login</a>
                </div>
            </div>
            <div className='w-full sm:w-1/2 overflow-hidden '>
                <img className='w-full rounded-lg' src={img14} /></div>
            {isLoading && (<Loading />)}
        </>
    )
}

export default ForgotForm