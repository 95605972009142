import React, { useState, useEffect } from 'react'

function Brochure() {
  return (
    <div>

    </div>
  )
}

export default Brochure