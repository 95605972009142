import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { Link, redirect, useLoaderData } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faHome, faSearch, faPlus, faTimes, } from '@fortawesome/free-solid-svg-icons'
import CommonHelper from '../../helpers/common'
import SelectUnit from '../../components/controls/select-unit';
import TextAreaInput from '../../components/controls/textarea-input';
import InputTextUnit from '../../components/controls/input-text-unit'
import DateInput from '../../components/controls/date-input'
import AutocompleteInput from '../../components/controls/autocomplete-input'
import ValidateMessage from '../../components/controls/validate-msg'
import { organizerActions } from '../../_store/organizer.slice'
import AdminEventList from './list'
import eventApi from '../../api/eventApi'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor';

const initial = {
    "appSite": process.env.REACT_APP_CODE,
    //seasonId: 0,
    eventId: "",
    showTime: "",
    endTime: "",
    locationEn: "",
    locationVn: "",
    contactName: "",
    contactPhone: "",
    stage: "",
    //selfHold: false,
    organizer: "",
    eventType: "",
    eventNameEn: "",
    eventNameVn: "",
    responsiblePerson: "",
    rPBelongTo: "",
    rPPhone: "",
    mCPerson: "",
    mCPhone: "",
    //translator: false,
    //teaBreak: false,
    //buffet: false,
    //vIP: false,
    image: "",
    url: "",
    extraValue1: "",
    extraValue2: "",
    extraValue3: "",
    extraValue4: "",
    extraValue5: "",
    activated: "1",
    isDeleted: false,
    customOrder: "0",
    useCustomOrder: false,
    createdBy: "",
    createdDate: "",
    modifiedBy: "",
    modifiedDate: "",
}
function AdminEvent(props) {
    const dispatch = useDispatch();
    const [state, setState] = useState(initial);
    const [{ show_time, end_time, min_dt_end_time, max_dt_show_time, eventTypes }, setSelected] = useState({
        show_time: null,
        end_time: null,
        min_dt_end_time: null,
        max_dt_show_time: null,
        eventTypes: [
            { "code": "PRESS-CONFERENCE", "name": "PRESS CONFERENCE (Họp báo)" },
            { "code": "GALA-DINNER", "name": "GALA DINNER (dạ tiệc)" },
            { "code": "SEMINAR-OR-WORKSHOPS", "name": "SEMINAR OR WORKSHOPS (Hội thảo)" },
            { "code": "PRODUCT-OR-SOLUTION", "name": "PRODUCT OR SOLUTION (Trình bày sản phẩm hoặc giải pháp)" },
        ]
    })
    const [{ stateError, errorMsg, isLoading, isError, isSuccess, isEdit, onSearch }, setCommonState] = useState({
        stateError: {
            eventNameEn: false,
            eventNameVn: false,
            locationEn: false,
            locationVn: false,
            extraValue1: false,
        },
        errorMsg: {
            eventNameEn: "",
            eventNameVn: "",
            locationEn: "",
            locationVn: "",
            extraValue1: "",
        },
        isLoading: true,
        isError: false,
        isSuccess: false,
        isEdit: false,
        onSearch: true,
    })
    useEffect(() => {
        var showTime = "";
        var endTime = "";
        if (show_time != null) {
            showTime = CommonHelper.formatToYYYYMMDDHHMMSS(show_time);
        }
        if (end_time != null) {
            endTime = CommonHelper.formatToYYYYMMDDHHMMSS(end_time);
        }
        setState((prev) => ({
            ...prev, showTime, endTime
        }));
    }, [show_time, end_time])
    const fileUpload = useRef(null);
    function uploadAdapter(loader) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    loader.file.then((file) => {
                        var _data = {
                            file
                        };
                        var response = eventApi.upload_event_image_to_editor(_data);
                        response.then((res) => {
                            if (res.status === 200) {
                                if (res.data.code === 202 && res.data.data != null) {
                                    resolve({ default: `${process.env.REACT_APP_EXHIBITOR_IMAGE}${res.data.data.img_url}` })
                                }
                            }
                        }).catch((err) => {
                            reject(err);
                        })
                    })
                })
            }
        }
    }
    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        }
    }
    const handle_search = () => {
        setCommonState((prev) => ({ ...prev, onSearch: true }))
    }
    const handle_change = (e) => {
        const { name, value } = e.target;
        setState((prev) => ({
            ...prev,
            [name]: value,
        }))
        validate_required(e);
    }
    const handle_edit = (obj) => {
        console.log(obj);
        if (obj != null) {
            dispatch(organizerActions.set_loading());
            const response = eventApi.get_event(obj.eventId);
            response.then((res) => {
                if (res.code == 506) {
                    setCommonState((prev) => ({ ...prev, isLoading: true }));
                    alert("Session is expired. Please sign in again!")
                    const timer = setTimeout(() => {
                        window.location.href = "/admin/login"
                    }, 4000);
                    return () => clearTimeout(timer);
                } else {
                    dispatch(organizerActions.set_loading());
                    if (res.code == 200) {
                        set_data(res.data);
                        //setCommonState((prev) => ({ ...prev, isSuccess: true, isError: false }));
                        //setState((prev) => ({ ...prev, eventId: res.data.id }))
                    } else {
                        //setCommonState((prev) => ({ ...prev, isSuccess: false, isError: true }));
                    }
                }
            })
        }
        setCommonState((prev) => ({ ...prev, onSearch: false }));
    }
    const set_data = (data) => {
        if (data != null) {
            var show_time = null;
            var end_time = null;

            if (CommonHelper.is_not_empty(data.showTime)) {
                show_time = CommonHelper.parseStringToDate(data.showTime, "yyyyMMdd HHmmss")
            }
            if (CommonHelper.is_not_empty(data.endTime)) {
                end_time = CommonHelper.parseStringToDate(data.endTime, "yyyyMMdd HHmmss")
            }
            setSelected((prev) => ({
                ...prev,
                show_time,
                end_time,
                //min_dt_end_time: show_time,
                max_dt_show_time: end_time,
                //showInHome,
            }));
            setCommonState((prev) => ({
                ...prev,
                isEdit: false
            }))
            setState((prev) => ({
                ...prev,
                eventId: data.eventId,
                showTime: data.showTime,
                endTime: data.endTime,
                eventNameEn: data.eventNameEn,
                eventNameVn: data.eventNameVn,
                locationEn: data.locationEn,
                locationVn: data.locationVn,
                organizer: data.organizer,
                eventType: data.eventType,
                activated: data.activated ? "1" : "0",
                isDeleted: data.isDeleted,
                image: data.image,
                url: data.url,
                extraValue1: data.extraValue1,
                extraValue2: data.extraValue2,
                extraValue3: data.extraValue3,
                extraValue4: data.extraValue4,
                extraValue5: data.extraValue5,
                customOrder: data.customOrder,
                useCustomOrder: data.useCustomOrder,
            }))
        }
    }
    const validate_required = (e) => {
        const { name, value } = e.target;
        var _arr = [];
        Object.keys({ ...stateError }).map((item) => {
            _arr.push(item);
        })
        var _idx = _arr.indexOf(name);
        if (_idx >= 0) {
            setCommonState((prev) => ({
                ...prev,
                stateError: { ...stateError, [name]: CommonHelper.is_empty(value) },
            }))
        }
    }
    const handleUpload = () => {
        fileUpload.current.click()
    };
    const upload_event_image = (e) => {
        const { name, files } = e.target;
        if (files != null && files.length > 0) {
            var _data = {
                file: files[0]
            };
            var response = eventApi.upload_event_image(_data);
            response.then((res) => {
                console.log(res)
                if (res.status === 200) {
                    if (res.data.code === 202 && res.data.data != null) {
                        setState((prev) => ({ ...prev, image: res.data.data.img_url }))
                    }
                }
            })
        }
    };
    const remove_image = () => {
        setState((prev) => ({ ...prev, image: "" }))
    }
    const validate_form = () => {
        var flag = true;
        var _arr = [];
        Object.keys({ ...stateError }).map((item) => {
            _arr.push(item);
        })
        var _data = { ...state };
        //console.log(_data);
        var _objError = { ...stateError };
        _arr.forEach((item, i) => {
            if (CommonHelper.is_empty(_data[item])) {
                _objError[item] = true;
                flag = false;
            }
        })
        setCommonState((prev) => ({
            ...prev,
            stateError: _objError,
        }))
        return flag;
    }
    const handle_submit = () => {
        var _data = { ...state };
        _data.seasonId = _data.seasonId != 0 ? _data.seasonId : 0;
        _data.activated = _data.activated == "1";
        if (validate_form()) {
            dispatch(organizerActions.set_loading());
            const response = eventApi.post(_data);
            response.then((res) => {
                if (res.code == 506) {
                    setCommonState((prev) => ({ ...prev, isLoading: true }));
                    alert("Session is expired. Please sign in again!")
                    const timer = setTimeout(() => {
                        window.location.href = "/admin/login"
                    }, 4000);
                    return () => clearTimeout(timer);
                } else {
                    dispatch(organizerActions.set_loading());
                    if (res.code == 204) {
                        setCommonState((prev) => ({ ...prev, isSuccess: true, isError: false }));
                        setState((prev) => ({ ...prev, eventId: res.data.id }))
                    } else {
                        setCommonState((prev) => ({ ...prev, isSuccess: false, isError: true }));
                    }
                }
            })
        }
    }
    const handle_reset = () => {
        setSelected((prev) => ({
            ...prev,
            show_time: null,
            end_time: null,
            min_dt_end_time: new Date(),
            max_dt_show_time: null,
        }));
        setCommonState((prev) => ({
            ...prev,
            isEdit: false,
            onSearch: false,
        }))
        setState((prev) => ({
            ...prev,
            eventId: initial.eventId,
            //seasonId: initial.seasonId,
            showTime: initial.showTime,
            endTime: initial.endTime,
            eventNameEn: initial.eventNameEn,
            eventNameVn: initial.eventNameVn,
            locationEn: initial.locationEn,
            locationVn: initial.locationVn,
            organizer: initial.organizer,
            eventType: initial.eventType,
            activated: initial.activated,
            isDeleted: initial.isDeleted,
            image: initial.image,
            url: initial.url,
            extraValue1: initial.extraValue1,
            extraValue2: initial.extraValue2,
            extraValue3: initial.extraValue3,
            extraValue4: initial.extraValue4,
            extraValue5: initial.extraValue5,
            customOrder: initial.customOrder,
            useCustomOrder: initial.useCustomOrder,
        }))
    }
    return (
        <>
            <div className='p-3 bg-white'>
                <div className='breadscum w-full'>
                    <div className='w-1/2'>
                        <ul className='flex flex-wrap'>
                            <li className='text-sm '>
                                <Link to={"/"}><FontAwesomeIcon icon={faHome} /> </Link>
                            </li>
                            <li className='text-sm pl-2 leading-normal before:float-left before:pr-2 before:content-["/"]'>
                                <Link to={"/admin/event"}>Event </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='flex'>
                    <div className='w-2/3'>
                        <h6 className='text-color-primary font-semibold text-xl'>Event</h6>
                    </div>
                    <div className='w-1/3 text-right'>
                        <a className='cursor-pointer' onClick={handle_search}>
                            <FontAwesomeIcon icon={faSearch} />
                        </a>
                    </div>
                </div>
                <div className='block sm:flex sm:flex-wrap'>
                    <div className='w-full block lg:flex lg:flex-wrap sm:w-3/4 mt-4'>
                        <div className='w-full mt-2 px-2 sm:px-4'>
                            <InputTextUnit onChange={handle_change} value={state.eventNameEn} name='eventNameEn'
                                label='Event name (English)' required={true} isError={stateError.eventNameEn}
                                errorMsg={errorMsg.eventNameEn} />
                        </div>
                        <div className='w-full mt-2 px-2 sm:px-4'>
                            <InputTextUnit onChange={handle_change} value={state.eventNameVn} name='eventNameVn'
                                label='Event name (Vietnamese)' required={true} isError={stateError.eventNameVn}
                                errorMsg={errorMsg.eventNameVn} />
                        </div>
                        <div className='w-full mt-2 px-2 sm:px-4'>
                            <InputTextUnit onChange={handle_change} value={state.locationEn} name='locationEn'
                                label='Location (English)' required={true} isError={stateError.locationEn}
                                errorMsg={errorMsg.locationEn} />
                        </div>
                        <div className='w-full mt-2 px-2 sm:px-4'>
                            <InputTextUnit onChange={handle_change} value={state.locationVn} name='locationVn'
                                label='Location (Vietnamese)' required={true} isError={stateError.locationVn}
                                errorMsg={errorMsg.locationVn} />
                        </div>
                        <div className='w-full lg:w-2/3 mt-2 px-2 sm:px-4'>
                            <InputTextUnit onChange={handle_change} value={state.organizer} name='organizer'
                                label='Organizer' required={false} isError={false} />
                        </div>
                        <div className='w-full lg:w-1/3 mt-2 px-2 sm:px-4'>
                            <SelectUnit onChange={handle_change} value={state.eventType} name='eventType'
                                label='Event Type' required={false} isError={false} options={eventTypes} />
                        </div>
                        <div className='w-full mt-2 px-2 sm:px-4'>
                            <label className='font-semibold'>Content</label>
                            <div className='min-h-[500px]'>
                                <CKEditor
                                    config={{
                                        extraPlugins: [uploadPlugin],
                                        htmlSupport: {
                                            allow: [
                                                { attributes: true, classes: true, styles: true }
                                            ],
                                        },
                                        style: {
                                            definitions: [{
                                                name: '1.5 lines',
                                                element: 'p',
                                                classes: ["leading-6"]
                                            },
                                            ]
                                        },
                                    }}
                                    editor={Editor}
                                    data={state.extraValue1}
                                    onChange={(event, editor) => {
                                        console.log(editor.getData());
                                        setState((prev) => ({ ...prev, extraValue1: editor.getData() }))
                                    }}
                                />
                            </div>
                            <ValidateMessage msgType="required" isShow={stateError.extraValue1} />
                        </div>
                        <div className='w-full mt-5 px-2 sm:px-4'>
                            <label className='font-semibold'>Tag</label>
                            <TextAreaInput name="extraValue3" className={"h-32 resize-none"}
                                value={state.extraValue3} onChange={handle_change} />
                        </div>
                        <div className='w-full mt-2 px-2 sm:px-4'>
                            <label className='font-semibold'>Keyword</label>
                            <TextAreaInput name="extraValue4" className={"h-32 resize-none"}
                                value={state.extraValue4} onChange={handle_change} />
                        </div>
                    </div>
                    <div className='w-full sm:w-1/4 mt-4'>
                        <div className='w-full mt-2 px-2 sm:px-4'>
                            <label className='block required'>Status</label>
                            <select className='w-full h-8 py-1 px-3 rounded border-gray-400 border hover:border-cyan-900 active:border-cyan-900 focus:border-cyan-900 focus:outline-none'
                                value={state.activated} name='activated' onChange={handle_change}>
                                <option value="1">Active</option>
                                <option value="0">Disabled</option>
                            </select>
                        </div>
                        <div className='w-full mt-2 px-2 sm:px-4'>
                            <label className='block required'>Begin time</label>
                            <DateInput
                                dateFormat={"dd/MM/yyyy HH:mm"}
                                minDate={min_dt_end_time}
                                maxDate={max_dt_show_time}
                                selected={show_time}
                                showTimeSelect
                                onChange={(e) => { setSelected((prev) => ({ ...prev, show_time: e, min_dt_end_time: e })) }} />
                        </div>
                        <div className='w-full mt-2 px-2 sm:px-4'>
                            <label className='block required'>End time</label>
                            <DateInput
                                dateFormat={"dd/MM/yyyy HH:mm"}
                                minDate={min_dt_end_time}
                                selected={end_time}
                                showTimeSelect
                                onChange={(e) => { setSelected((prev) => ({ ...prev, end_time: e, max_dt_show_time: e })) }} />
                        </div>
                        <div className='w-full mt-2 px-2 sm:px-4'>
                            <label className=''>
                                <input type='checkbox' checked={state.useCustomOrder} onChange={(e) => { setState((prev) => ({ ...prev, useCustomOrder: e.target.checked })) }} />
                                <span className='ms-2 font-semibold'>Use priority order</span>
                            </label>
                            <div className='w-full mt-2'>
                                <InputTextUnit onChange={handle_change} value={state.customOrder} name='customOrder'
                                    label='' required={false} disabled={!state.useCustomOrder} />
                                <span className='text-gray-600 py-3 block'>Note: Order by Descending</span>
                            </div>
                        </div>
                        {/* <div className='w-full mt-2 px-2 sm:px-4'>
                            <label className=''>
                                <input type='checkbox' checked={showInHome} onChange={(e) => { setSelected((prev) => ({ ...prev, showInHome: e.target.checked })) }} />
                                <span className='ms-2 font-semibold'>Show in home page</span>
                            </label>
                        </div> */}
                        <div className='w-full mt-2 px-2 sm:px-4'>
                            <label className='block w-full font-semibold'>Image</label>
                            <div className='border border-dashed border-slate-500'>
                                <div className='flex'>
                                    <div className='relative w-1/2'>
                                        <button type="button" className="bg-slate-300 size-24 text-center m-2 rounded-sm font-semibold box-border cursor-pointer" onClick={handleUpload}>
                                            <FontAwesomeIcon icon={faPlus} />
                                            <span className='mt-2 block'>Click to upload</span>
                                        </button>
                                        <input
                                            type="file"
                                            ref={fileUpload}
                                            onChange={upload_event_image}
                                            className="absolute w-full opacity-0 inset-0 cursor-pointer"
                                        />
                                    </div>
                                    <div className='w-1/2 p-3'>
                                        <p>{`Attached image < 2MB`}</p>
                                    </div>
                                </div>
                                <div className='w-full px-3 relative'>
                                    {CommonHelper.is_not_empty(state.image) && (
                                        <>
                                            <div className='rounded-sm overflow-hidden w-full py-2 flex items-center flex-nowrap'>
                                                <img src={process.env.REACT_APP_EXHIBITOR_IMAGE + state.image} className='w-full object-cover' />
                                            </div>
                                            <a className='absolute cursor-pointer top-1 right-1' onClick={remove_image}>
                                                <FontAwesomeIcon icon={faTimes} />
                                            </a>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-full my-14 px-2 sm:px-4'>
                    <div>
                        <button type='button' onClick={handle_submit}
                            className='inline-flex w-32 justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset bg-cyan-900'>
                            Submit</button>

                        <button type='button' onClick={handle_reset}
                            className='inline-flex ms-5 w-32 justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-color-primary shadow-sm ring-1 ring-inset bg-slate-300'>
                            Reset form</button>
                    </div>
                    {isError && (
                        <span className='text-red-600 py-3 block'>Update failed</span>
                    )}
                    {isSuccess && (
                        <span className='text-green-600 py-3 block'>Update successful</span>
                    )}
                </div>
            </div>
            {onSearch && (
                <div className='absolute inset-0 p-2 bg-white z-20'>
                    <AdminEventList is_show={onSearch} onEdit={(e) => { handle_edit(e) }} onNew={handle_reset} />
                </div>
            )}
        </>
    )
}

AdminEvent.propTypes = {}

export default AdminEvent
