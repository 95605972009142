import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

// import required modules
import { Autoplay, Keyboard, Navigation, EffectFade, Pagination } from 'swiper/modules';

const logo1 = process.env.REACT_APP_EXHIBITOR_IMAGE + '/images/logo.kienviet.svg'
const logo3 = process.env.REACT_APP_EXHIBITOR_IMAGE + '/images/CIO-Vietnam-logo.png'
const logo5 = process.env.REACT_APP_EXHIBITOR_IMAGE + '/images/saca_logo_CHON_1.jpg'
const logo2 = process.env.REACT_APP_EXHIBITOR_IMAGE + '/images/ELLE_Decoration-logo.png'
const logo4 = process.env.REACT_APP_EXHIBITOR_IMAGE + '/images/logo-hawa.jpg'

function Partner() {
    return (
        <>
            <div>
                <Swiper
                    slidesPerView={1}
                    // autoHeight={true}
                    height={144}
                    spaceBetween={60}
                    keyboard={{
                        enabled: true,
                    }}
                    effect={'fade'}
                    autoplay={{
                        delay: 3500,
                        disableOnInteraction: false,
                    }}
                    navigation={true}
                    modules={[EffectFade, Autoplay, Keyboard, Navigation]}
                    className="mySwiper">
                    <SwiperSlide>
                        <div className='flex items-center h-40'>
                            <div className='w-1/2 lg:w-1/3 p-3'>
                                <img src={logo4} className='w-full object-cover' />
                            </div>
                            <div className='w-1/2 lg:w-1/3 p-3'>
                                <img src={logo5} className='w-full object-cover' />
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </>
    )
}

export default Partner