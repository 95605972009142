import api from "./api";
import axios from "axios";
import localforage from "localforage";
import CommonHelper from "../helpers/common";

const newsApi = {
    get: async (id) => {
        var session_id = CommonHelper.get_session_id();
        if (CommonHelper.is_not_empty(session_id)) {
            var _url = `${process.env.REACT_APP_CODE}`;
            _url += id + "&session_id=" + session_id;
            var callApi = await api.get("news/get/" + _url);
            if (callApi.code === 401) {
                console.log(callApi);
                return { code: 506 }
            }
            return callApi;
        }
        return { code: 506 }
    },
    post: async (req) => {
        //req["AppSite"] = process.env.REACT_APP_CODE;
        var session_id = CommonHelper.get_session_id();
        if (CommonHelper.is_not_empty(session_id)) {
            req = {
                ...req, session_id,
                AppSite: process.env.REACT_APP_CODE
            };
            var callApi = await api.post("news", req);
            if (callApi.code === 401) {
                console.log(callApi);
                return { code: 506 }
            }
            return callApi;
        }
        return { code: 506 }
    },
    get_list: async (req) => {
        var session_id = CommonHelper.get_session_id();
        if (CommonHelper.is_not_empty(session_id)) {
            var _url = `${process.env.REACT_APP_CODE}`
            if (req != null) {
                req = { ...req, session_id };
            }
            var callApi = await api.post("news/get_list/" + _url, req);
            if (callApi.code === 401) {
                console.log(callApi);
                return { code: 506 }
            }
            return callApi;
        }
        return { code: 506 }
    },
    get_news: async () => {
        var _url = `${process.env.REACT_APP_CODE}`;
        var callApi = await api.get("news/get_news/" + _url);
        if (callApi.code === 401) {
            console.log(callApi);
            return { code: 506 }
        }
        return callApi;
    },
    get_news_in_home: async () => {
        var _url = `${process.env.REACT_APP_CODE}`;
        var callApi = await api.get("news/get_news_in_home/" + _url);
        if (callApi.code === 401) {
            console.log(callApi);
            return { code: 506 }
        }
        return callApi;
    },
    get_news_by_url: async (val) => {
        var _url = `${process.env.REACT_APP_CODE}` + "?url=" + val;
        var callApi = await api.get("news/get_news_by_url/" + _url);
        if (callApi.code === 401) {
            console.log(callApi);
            return { code: 401 }
        }
        return callApi;
    },
    upload_news_image_to_editor: async (req) => {
        var token = await CommonHelper.get_token();
        var app = await CommonHelper.get_app();
        var session_id = CommonHelper.get_session_id();
        // create a new FormData object and append the file to it
        if (CommonHelper.is_not_empty(session_id)) {
            const formData = new FormData();
            formData.append("session_id", session_id);
            formData.append("app_site", process.env.REACT_APP_CODE);
            formData.append("news_image", req.file)
            var response = await axios.post(process.env.REACT_APP_API_ENDPOINT + "news/upload_news_image_to_editor", formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            })
            return response;
        }
        return null;
    },
    upload_news_image: async (req) => {
        var token = await CommonHelper.get_token();
        var app = await CommonHelper.get_app();
        var session_id = CommonHelper.get_session_id();
        // create a new FormData object and append the file to it
        if (CommonHelper.is_not_empty(session_id)) {
            const formData = new FormData();
            formData.append("session_id", session_id);
            formData.append("app_site", process.env.REACT_APP_CODE);
            formData.append("news_id", req.news_id);
            formData.append("news_image", req.file)
            var response = await axios.post(process.env.REACT_APP_API_ENDPOINT + "news/upload_news_image", formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            })
            return response;
        }
        return null;
    },
}

export default newsApi;