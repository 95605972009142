import React from 'react'
import { Typeahead } from 'react-bootstrap-typeahead'
// Import as a module in your JS
import 'react-bootstrap-typeahead/css/Typeahead.css';

function AutocompleteInput(props) {
    return (
        <Typeahead
            {...props}
            flip
            className={'w-full py-1 px-3 rounded border-gray-400 border hover:border-cyan-900 active:border-cyan-900 focus:border-cyan-900 focus:outline-none ' + props.className} />
    )
}

export default AutocompleteInput