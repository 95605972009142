import React, { useState, useEffect } from 'react'
import CommonHelper from '../helpers/common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from '../components/controls/loading';
import { faPlug, faPlus, faTimes, faUpload } from '@fortawesome/free-solid-svg-icons';
import InputTextUnit from '../components/controls/input-text-unit'
import img14 from '../assets/image-14.jpg'
import SelectUnit from '../components/controls/select-unit'
import AutocompleteInput from '../components/controls/autocomplete-input'
import ValidateMessage from '../components/controls/validate-msg'
import visitorApi from '../api/visitorApi';
import VisitorDeleteConfirmation from './components/delete-confirmation'

const initialState = {
  "AppSite": process.env.REACT_APP_CODE,
  "SeasonId": "0",
  "VisitorId": "",
  "VisitorType": "",
  "Sponsor": "",
  "RequestType": "",
  "Individual": true,
  "ShortName": "",
  "CompanyNameEn": "",
  "AddressEn": "",
  "CompanyNameVn": "",
  "AddressVn": "",
  "Country": "",
  "Website": "",
  "ContactName": "",
  "ContactTitle": "",
  "ContactPosition": "",
  "ContactEmail": "",
  "ContactPhone": "",
  "SearchProductCategories": "",
  "SearchProducts": "",
  "Purposes": "",
  "Channels": "",
  "Materials": "",
  "Transportation": "",
  "DistanceEstimation": "",
  "PeopleInTransportation": "",
  VisitorMembers: [
    /*{
      "FullName1": "",
      "Country1": "",
      "Phone1": "",
      "Email1": "",
    }*/
  ],
};
function WhyVisit() {
  const [state, setState] = useState(initialState);
  const [{ stateError, isLoading, isError, isSuccess, msg, qr_url, is_delete, data_delete,
    lst_transportations, lst_job_title, countries, lst_purpose, lst_channel }, setCommonState] = useState({
      stateError: {
        ContactName: false,
        Country: false,
        ContactEmail: false,
        ContactPhone: false,
        CompanyNameEn: false,
        ContactPosition: false,
        Purposes: false,
        //SearchProducts: false,
        //Materials: false,
        Channels: false,
        Transportation: false,
        DistanceEstimation: false,
        PeopleInTransportation: false,
      },
      isLoading: false,
      isError: false,
      isSuccess: false,
      msg: "",
      lst_transportations: [],
      lst_job_title: [],
      countries: [],
      lst_purpose: [],
      lst_channel: [],
      qr_url: "",
      is_delete: false,
      data_delete: null,
    })
  const [{ Purposes, SearchProducts, Materials, Channels, Transportation }, setSelected] = useState({
    Purposes: [],
    SearchProducts: [],
    Materials: [],
    Channels: [],
    Transportation: []
  })
  useEffect(() => {
    get_transportations();
    get_job_title();
    get_nation();
    get_pur_of_visit();
    get_channel();
  }, [])
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      Purposes: JSON.stringify(Purposes),
      //SearchProducts: JSON.stringify(SearchProducts),
      //Materials: JSON.stringify(Materials),
      Channels: JSON.stringify(Channels),
      //Transportation: JSON.stringify(Transportation),
    }))
    if (Purposes.length > 0) {
      setCommonState((prev) => ({
        ...prev,
        stateError: { ...stateError, Purposes: false },
      }))
    }
    if (Channels.length > 0) {
      setCommonState((prev) => ({
        ...prev,
        stateError: { ...stateError, Channels: false },
      }))
    }
  }, [Purposes, Channels])
  const get_transportations = () => {
    const response = visitorApi.get_common("TRANSPORTATION");
    response.then((res) => {
      if (res.code == 200) {
        var lst_transportations = res.data.map((e) => e.name);
        setCommonState((prev) => ({ ...prev, lst_transportations }));
      }
    })
  }
  const get_job_title = () => {
    const response = visitorApi.get_common("JOB_TITLE");
    response.then((res) => {
      if (res.code == 200) {
        var lst_job_title = res.data.map((e) => e.name);
        setCommonState((prev) => ({ ...prev, lst_job_title }));
      }
    })
  }
  const get_nation = () => {
    const response = visitorApi.get_common("NATION");
    response.then((res) => {
      if (res.code == 200) {
        var countries = res.data.map((e) => e.name);
        setCommonState((prev) => ({ ...prev, countries }));
      }
    })
  }
  const get_pur_of_visit = () => {
    const response = visitorApi.get_common("PUR_OF_VISIT");
    response.then((res) => {
      if (res.code == 200) {
        var lst_purpose = res.data.map((e) => e.name);
        setCommonState((prev) => ({ ...prev, lst_purpose }));
      }
    })
  }
  const get_channel = () => {
    const response = visitorApi.get_common("CHANNEL");
    response.then((res) => {
      if (res.code == 200) {
        var lst_channel = res.data.map((e) => e.name);
        setCommonState((prev) => ({ ...prev, lst_channel }));
      }
    })
  }
  const handle_change = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }))
    validate_required(e);
  }
  const validate_required = (e) => {
    const { name, value } = e.target;
    var _arr = [];
    Object.keys({ ...stateError }).map((item) => {
      _arr.push(item);
    })
    var _idx = _arr.indexOf(name);
    if (_idx >= 0) {
      setCommonState((prev) => ({
        ...prev,
        stateError: { ...stateError, [name]: CommonHelper.is_empty(value) },
      }))
    }
  }
  const validate_form = () => {
    var flag = true;
    var _arr = [];
    Object.keys({ ...stateError }).map((item) => {
      _arr.push(item);
    })
    var _data = { ...state };
    var _objError = { ...stateError };
    _arr.forEach((item, i) => {
      if (CommonHelper.is_empty(_data[item])) {
        _objError[item] = true;
        flag = false;
      }
      if (['Purposes', 'Channels'].indexOf(item) >= 0) {
        if (_data[item] === "[]") {
          _objError[item] = true;
        }
      }
    })
    var lst_member = _data.VisitorMembers;
    if (lst_member != null && lst_member.length > 0) {
      var _idx = lst_member.findIndex(x => CommonHelper.is_empty(x.MemberName.trim()));
      flag = _idx < 0;
    }
    setCommonState((prev) => ({
      ...prev,
      stateError: _objError,
    }))
    return flag;
  }
  const handle_change_sub = (e, id) => {
    const { name, value } = e.target;
    var _lst = [...state.VisitorMembers];
    var _idx = _lst.findIndex(x => x.Id == id);
    if (_idx >= 0) {
      _lst[_idx][name] = value.trim();
    }
    setState((prev) => ({ ...prev, VisitorMembers: _lst }));
  }
  const handle_add_person = () => {
    var max = 1
    var list = [...state.VisitorMembers];
    if (list.length > 0) {
      var maxObj = Math.max.apply(Math, list.map(o => o.Id));
      max = maxObj + 1;
    }
    var _model = {
      "Id": max,
      "MemberName": "",
      "Country": "",
      "Phone": "",
      "Email": "",
    }
    list.push(_model);
    setState((prev) => ({
      ...prev,
      VisitorMembers: list
    }))
  }
  const handle_submit = () => {
    var flag = validate_form();
    if (flag) {
      var data = { ...state };
      data.CompanyNameVn = data.CompanyNameEn;
      setCommonState((prev) => ({ ...prev, isLoading: true }));
      var response = visitorApi.register(data);
      response.then((res) => {
        if (res != null) {
          if (res.code == 205) {
            var qr_url = res.data.qr_code;
            setCommonState((prev) => ({
              ...prev,
              qr_url,
              isSuccess: true,
              isError: false,
              msg: "Cảm ơn bạn đã đăng ký. Chúng tôi sẽ gửi thông báo xác nhận qua email của bạn, vui lòng kiểm tra hộp thư đến hoặc spam."
            }));
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              'event': 'visitorRegistration'
            });
          } else if (res.code == 414) {
            setCommonState((prev) => ({
              ...prev,
              isError: true,
              isSuccess: false,
              msg: "Email đăng ký đã tồn tại. Vui lòng nhập email khác!"
            }));
          } else {
            setCommonState((prev) => ({
              ...prev,
              isError: true,
              isSuccess: false,
              msg: "Đăng ký thất bại"
            }));
          }
        } else {
          setCommonState((prev) => ({
            ...prev,
            isError: true,
            isSuccess: false,
            msg: "Đăng ký thất bại"
          }));
        }
        setCommonState((prev) => ({ ...prev, isLoading: false }));
      })
    } else {
      setCommonState((prev) => ({ ...prev, msg: "Vui lòng kiểm tra những trường bắt buộc!", isError: true }))
    }
  }
  const remove_member = (e, idx) => {
    var obj = [...state.VisitorMembers][idx];
    obj["idx"] = idx;
    confirm_delete(obj);
  }
  const confirm_delete = (model) => {
    if (model != null) {
      var data_delete = model;
      setCommonState((prev) => ({
        ...prev,
        is_delete: true,
        data_delete
      }));
    }
  }
  const handle_delete = (flag) => {
    if (flag) {
      var _obj = { ...data_delete };
      var list = [...state.VisitorMembers];
      var VisitorMembers = [];
      list.forEach((x, idx) => {
        if (idx != _obj.idx) {
          VisitorMembers.push(x)
        }
      })
      setState((prev) => ({ ...prev, VisitorMembers }));
    }
    setCommonState((prev) => ({
      ...prev,
      is_delete: false,
      data_delete: null
    }));
  }
  return (
    <div className='mx-auto max-w-7xl py-6 sm:px-6 lg:px-8'>
      <h1 className='text-center text-3xl font-bold alt-font text-color-primary'>ĐĂNG KÝ THAM GIA - <span className='fs-color-secondary'>VIBE 2024</span></h1>
      <div className='mt-5'>
        <div className='block sm:flex'>
          <div className='w-full sm:w-2/3'>
            <div className='block sm:flex sm:flex-wrap'>
              <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
                <InputTextUnit onChange={handle_change} value={state.ContactName} name='ContactName'
                  label='Họ và tên/Full name' required={true} isError={stateError.ContactName} />
              </div>
              <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
                <SelectUnit onChange={handle_change} value={state.Country} name='Country'
                  label='Quốc gia/Nation' required={true} isError={stateError.Country} options={countries} />
              </div>
              <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
                <InputTextUnit onChange={handle_change} value={state.ContactEmail} name='ContactEmail'
                  label='Email' required={true} isError={stateError.ContactEmail} />
              </div>
              <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
                <InputTextUnit onChange={handle_change} value={state.ContactPhone} name='ContactPhone'
                  label='Số điện thoại/Phone number' required={true} isError={stateError.ContactPhone} />
              </div>
              <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
                <InputTextUnit onChange={handle_change} value={state.CompanyNameEn} name='CompanyNameEn'
                  label='Tên công ty/Company Name' required={true} isError={stateError.CompanyNameEn} />
              </div>
              <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
                <SelectUnit onChange={handle_change} value={state.ContactPosition} name='ContactPosition'
                  label='Chức danh công việc' required={true} isError={stateError.ContactPosition} options={lst_job_title} />
              </div>
              <div className='w-full mt-2 px-2 sm:px-4'>
                <label className='required font-semibold'>Mục đích tham quan</label>
                <AutocompleteInput multiple options={lst_purpose} id="purpose_of_visit" className={(stateError.Purposes ? "border-red-600" : "")}
                  selected={Purposes} onChange={(e) => { setSelected((prev) => ({ ...prev, Purposes: e })) }} />
                <ValidateMessage msgType="required" isShow={stateError.Purposes} />
              </div>
              <>
                {/* <div className='w-full mt-2 px-2 sm:px-4 hidden'>
                  <label className='required font-semibold alt-font'>Main area of product interest</label>
                  <AutocompleteInput multiple options={lst_product} id="product_interest" className={(stateError.SearchProducts ? "border-red-600" : "")}
                    selected={SearchProducts} onChange={(e) => { setSelected((prev) => ({ ...prev, SearchProducts: e })) }} />
                  <ValidateMessage msgType="required" isShow={stateError.SearchProducts} />
                </div>
                <div className='w-full mt-2 px-2 sm:px-4 hidden'>
                  <label className='required font-semibold alt-font'>The material types interest</label>
                  <AutocompleteInput multiple options={lst_material} id="material_interest" className={(stateError.Materials ? "border-red-600" : "")}
                    selected={Materials} onChange={(e) => { setSelected((prev) => ({ ...prev, Materials: e })) }} />
                  <ValidateMessage msgType="required" isShow={stateError.Materials} />
                </div> */}
              </>
              <div className='w-full mt-2 px-2 sm:px-4'>
                <label className='required font-semibold'>Bạn biết triển lãm của chúng tôi qua đâu?</label>
                <AutocompleteInput multiple options={lst_channel} id="lst_channel" className={(stateError.Channels ? "border-red-600" : "")}
                  selected={Channels} onChange={(e) => { setSelected((prev) => ({ ...prev, Channels: e })) }} />
                <ValidateMessage msgType="required" isShow={stateError.Channels} />
              </div>
              <div className='w-full mt-2 px-2 sm:px-4'>
                <p className='font-semibold'>Ban Tổ chức Triển lãm VIBE đang tiến hành đo lường và đánh giá phát thải từ sự kiện, nhằm hướng tới tổ chức sự kiện bền vững – Netzero trong tương lai. Rất mong các Anh/Chị trả lời thêm một số câu hỏi sau:</p>
              </div>
              <div className='w-full mt-2 px-2 sm:px-4'>
                <SelectUnit onChange={handle_change} value={state.Transportation} name='Transportation'
                  label='Anh/Chị đã sử dụng loại phương tiện giao thông đường bộ nào để di chuyển từ nơi ở đến địa điểm diễn ra sự kiện?'
                  required={true} isError={stateError.Transportation} options={lst_transportations} />
              </div>
              <div className='w-full mt-2 px-2 sm:px-4'>
                <InputTextUnit onChange={handle_change} value={state.DistanceEstimation} name='DistanceEstimation'
                  label='Ước tính khoảng cách từ nơi anh/chị xuất phát đến địa điểm tổ chức - Km'
                  required={true} isError={stateError.DistanceEstimation} />
              </div>
              <div className='w-full mt-2 px-2 sm:px-4'>
                <SelectUnit onChange={handle_change} value={state.PeopleInTransportation} name='PeopleInTransportation'
                  label='Tổng cộng có bao nhiêu người trên phương tiện di chuyển của anh/chị tính cả tài xế?'
                  required={true} isError={stateError.PeopleInTransportation} options={["1", "2", "3", "4", "5", "Other (Khác) ..."]} />
              </div>
              <div className='w-full mt-2 px-2 sm:px-4'>
                <div className='flex items-center'>
                  <h3 className='w-2/3 text-2xl font-bold my-4 text-gray-800'>Người đi cùng</h3>
                  <div className='w-1/3 text-right'>
                    <button type='button' onClick={handle_add_person}>
                      <span className='inline-block p-5 fs-color-secondary text-2xl'><FontAwesomeIcon icon={faPlus} /></span>
                    </button>
                  </div>
                </div>
              </div>
              {state.VisitorMembers.length > 0 && (
                <>
                  {state.VisitorMembers.map((item, idx) => {
                    return (
                      <div key={idx} className='accompay-person w-full block sm:flex sm:flex-wrap'>
                        <div className='line-devide w-full px-2 sm:px-4'>
                          <div className='my-5 border-t border-solid border-gray-500'></div>
                          <FontAwesomeIcon icon={faTimes} name={"remove-" + item.Id} className='text-red-600 text-xl font-semibold cursor-pointer p-1 block'
                            onClick={(e) => { remove_member(e, idx) }} />
                        </div>
                        <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
                          <InputTextUnit onChange={(e) => { handle_change_sub(e, item.Id) }} value={item.MemberName} name={"MemberName"}
                            label='Họ và tên/Full name' required={true} isError={false} />
                        </div>
                        <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
                          <SelectUnit onChange={(e) => { handle_change_sub(e, item.Id) }} value={item.Country} name={"Country"}
                            label='Quốc gia/Nation' required={false} isError={false} options={countries} />
                        </div>
                        <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
                          <InputTextUnit onChange={(e) => { handle_change_sub(e, item.Id) }} value={item.Phone} name={"Phone"}
                            label='Số điện thoại/Phone number' required={false} isError={false} />
                        </div>
                        <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
                          <InputTextUnit onChange={(e) => { handle_change_sub(e, item.Id) }} value={item.Email} name={"Email"}
                            label='Email' required={false} isError={false} />
                        </div>
                        {/* <div className='w-full px-2 sm:px-4'>
                          <div className='my-5 border-t border-solid border-gray-500'></div>
                        </div> */}
                      </div>
                    )
                  })}
                </>
              )}
              <div className='w-full mt-2 px-2 sm:px-4'>
                <div className='my-5 border-t border-solid border-gray-500'></div>
                <div >
                  <button type='button' onClick={handle_submit} disabled={isSuccess}
                    className='inline-flex w-32 justify-center rounded-2xl text-white py-1 px-2 box-border text-lg bg-orange-500 hover:bg-orange-400 disabled:bg-slate-500'>Submit</button>
                </div>
                {isError && (
                  <span className='text-red-600 py-3 block'>{msg}</span>
                )}
                {isSuccess && (
                  <span className='text-green-600 py-3 block'>{msg}</span>
                )}
              </div>
              {CommonHelper.is_not_empty(qr_url) && (
                <div className='w-full mt-2 px-2 sm:px-4'>
                  <img src={qr_url} className='w-72 object-cover' />
                </div>
              )}
            </div>
          </div>
          <div className='hidden sm:w-1/3 sm:block'>
            <img className='w-full rounded-sm' src={img14} />
          </div>
        </div>
      </div>
      {isLoading && (<Loading />)}
      {is_delete && (<VisitorDeleteConfirmation data={data_delete} isShow={is_delete} onClose={(flag) => { handle_delete(flag) }} />)}
    </div>
  )
}

export default WhyVisit