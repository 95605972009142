import React from 'react'

function FloorPlan3D() {
    return (
        <div className='w-full p-4'>
            <iframe src='https://my.matterport.com/show/?m=q7v423iYBsr' style={{ height: "750px", width: "100%" }}></iframe>
        </div>
    )
}

export default FloorPlan3D