import React, { useState, useEffect } from 'react'
import { Outlet, redirect, useLoaderData } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Sidebar from './components/sidebar'
import CommonHelper from '../helpers/common'
import Authentication from '../api/authApi'
import AdminHome from './home'
import ErrorPage from '../error-page'
import Loading from '../components/controls/loading'
import { organizerActions } from '../_store/organizer.slice'

export async function loader() {
    var response = await Authentication.organizer_user();
    if (response != null && response.code == 200) {
        return { code: response.code }
    }
    return { data: null, code: 506 };
}


function Admin() {
    const dispatch = useDispatch();
    const authLoading = useSelector(x => x.organizer.isLoading);
    const authLogged = useSelector(x => x.organizer.isLogged)
    const userData = useLoaderData();
    const [{ rights, isLoading }, setCommonState] = useState({
        rights: [],
        isLoading: false,
    })
    useEffect(() => {
        //dispatch(organizerActions.check_session());
        if (userData != null) {
            if (userData.code == 200) {
                var get_user = CommonHelper.get_user();
                get_user.then((obj_user) => {
                    if (obj_user != null && obj_user.role != null
                        && obj_user.role.access_Rights != null
                        && obj_user.role.access_Rights.length > 0) {
                        setCommonState((prev) => ({ ...prev, rights: obj_user.role.access_Rights }));
                    }
                })
            } else {
                window.location.href = "/admin/login";
            }
        }
        const timer = setTimeout(() => {
            setCommonState((prev) => ({ ...prev, isLoading: false }));
        }, 2000);
        return () => clearTimeout(timer);
    }, [])
    useEffect(() => {
        setCommonState((prev) => ({ ...prev, isLoading: authLoading }))
    }, [authLoading])
    return (
        <>
            <Sidebar rights={rights} />
            <div className="relative md:ml-64 bg-blueGray-100 p-2">
                <Outlet />
            </div>
            {isLoading && (<Loading />)}
        </>

    )
}

export default Admin