import React from 'react'
import PropTypes from 'prop-types'

const logo_ios = process.env.REACT_APP_EXHIBITOR_IMAGE + '/images/ios.svg'
const logo_android = process.env.REACT_APP_EXHIBITOR_IMAGE + '/images/android.svg'

const DownloadApp = (s) => {
    return (
        <div className='mx-auto max-w-7xl py-6 px-5 sm:px-6 lg:px-8'>
            <div className='block sm:flex my-4 md:my-28 sm:mt-20 w-full md:w-2/3 lg:w-1/2 md:mx-auto'>
                <div className='w-full sm:w-1/2 mx-auto'>
                    <a href='https://apps.apple.com/us/app/vibe-expo/id6714471918'><img src={logo_ios} className='w-5/6 object-cover' /></a>
                </div>
                <div className='w-full sm:w-1/2 mx-auto'>
                    <a href='https://play.google.com/store/apps/details?id=vn.hawa.thevipexpo'><img src={logo_android} className='w-5/6 object-cover' /></a>
                </div>
            </div>
        </div>
    )
}

DownloadApp.propTypes = {}

export default DownloadApp