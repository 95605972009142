import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, redirect, useLoaderData } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faHome, faSearch, faPlus, faTimes, } from '@fortawesome/free-solid-svg-icons'
import Authentication from '../../api/authApi'
import exhibitorApi from '../../api/exhibitorApi'
import CommonHelper from '../../helpers/common'
import SelectUnit from '../../components/controls/select-unit';
import TextAreaInput from '../../components/controls/textarea-input';
import InputTextUnit from '../../components/controls/input-text-unit'
import AutocompleteInput from '../../components/controls/autocomplete-input'
import Loading from '../../components/controls/loading'
import ValidateMessage from '../../components/controls/validate-msg'
import AdminExhibitorSearch from './search'
import { organizerActions } from '../../_store/organizer.slice'


const certificates = ['FSC CoC', 'FSC FM', 'PEFC CoC', 'PEFC FM', 'ISO 9001', 'ISO 14001', 'IOS 45001', 'BSCI', 'SMETA', 'SA 8000',
  'CARB EPA P2', 'CTPAT', 'CITES', 'GREENGUARD', 'IAQ', 'ZWL', 'O%VOC', 'Fit for Food', 'Toys standard', 'Others'];
/*const product_type = ['Office Furniture', 'Living Room Furniture', 'Dining Room Furniture', 'Bedroom Furniture', 'Kitchen Furniture', 'Bathroom Furniture',
  'Outdoor Garden Furniture', 'Children’s Furniture', 'Hospitalities & Project Furniture', 'Interior Decoration', 'Handicraft',
  'Bamboo Rattan Product', 'Upholstery Leather', 'Carpet & Flooring', 'Hardware & Accessories', 'Gift', 'Wood material Panels', 'Sawn timber KD & Logs',
  'Oil-based Coating', 'Others']*/
const product_type = ['Nội thất phòng khách', 'Nội thất phòng ăn & nhà bếp', 'Nội thất phòng tắm', 'Nội thất văn phòng', 'Ngoại thất & sân vườn', 'Nội thất dành cho trẻ em',
  'Nội thất dành cho trẻ em', 'Nội thất khách sạn và dự án', 'Đồ thủ công mỹ nghệ', 'Phụ kiện trang trí nội thất',
  'Hệ thống & công nghệ xây dựng', 'Giải pháp và phần mềm công nghệ cao', 'Dịch vụ chứng chỉ chuyển đổi số',
  'Hệ thống an ninh', 'Nhà thông minh, Nhà máy thông minh', "Kính, cửa, cửa sổ", "Gạch lát nền, đá ốp lát", "Vật liệu mái, tường, trần, sàn", "Sơn nước", "Vật tư ngoại thất: Thép, sắt, gạch, gỗ, vữa, nhôm", "Thiết bị vệ sinh, thiết bị phòng tắm",
  "Thiết bị ngoài trời và hồ bơi", "Ống và phụ tùng, dây điện", "Hóa chất, phụ gia", "Công cụ, dụng cụ"]
const main_material = ['Acacia', 'Rubber Wood', 'Cedar', 'Teak', 'Walnut', 'Oak', 'Ash', 'Pine', 'Poplar', 'Birch', 'Beech', 'Hemlock',
  'Particle Boad', 'MFC', 'MDF', 'HDF', 'Plywood', 'Melamine', 'Laminate', 'Acrylic', 'Veener', 'Solid surface', 'Compact HPL', 'PVC',
  'Foam', 'Textile Fabric', 'Leather', 'Silicon fiber', 'Bamboo', 'Rattan, Seagrass', 'PE fiber', 'PP fiber', 'PVC fiber', 'HDPE fiber',
  'Rope', 'Fiber', 'Brass, Bronze, Copper', 'Steel', 'Chrome', 'Iron', 'Aluminum', 'Inox', 'Fiberglass', 'Stone', 'Metal', 'Eucalyptus',
  'Clay', 'Glaze', 'Cement', 'Poly', 'Oil-based Coating', 'Western Hemlock', 'Spruce-Pine-FIR', 'Douglas-Fir', 'Western Red Cedar', 'Yellow Cedar', 'Others'];
const markets = ['Vietnam', 'USA', 'Japan', 'China', 'South Korean', 'UK', 'Canada', 'Australia', 'France', 'Germany', 'Holland', 'Taiwan', 'Malaysia', 'Europe',
  'North America', 'Middle East', 'New Zealand', 'Others'];
const countries = ['Vietnam', 'Afghanistan', 'Albania', 'Algeria', 'American Samoa', 'Andorra', 'Angola', 'Anguilla', 'Antarctica', 'Antigua and Barbuda', 'Argentina',
  'Armenia', 'Aruba', 'Australia', 'Austria', 'Azerbaijan', 'Bahamas', 'Bahrain', 'Bangladesh', 'Barbados', 'Belarus', 'Belgium', 'Belize', 'Benin',
  'Bermuda', 'Bhutan', 'Bolivia (Plurinational State of)', 'Bonaire, Saint Eustatius and Saba', 'Bosnia and Herzegovina', 'Botswana', 'Bouvet Island',
  'Brazil', 'British Indian Ocean Territory', 'Brunei Darussalam', 'Bulgaria', 'Burkina Faso', 'Burundi', 'Cabo Verde', 'Cambodia', 'Cameroon',
  'Canada', 'Cayman Islands', 'Central African Republic', 'Chad', 'Chile', 'China', 'Christmas Island', 'Cocos (Keeling) Islands', 'Colombia',
  'Comoros', 'Congo', 'Congo (Democratic Republic of the)', 'Cook Islands', 'Costa Rica', 'Croatia', 'Cuba', 'Curaçao', 'Cyprus', 'Czech Republic',
  "Côte d'Ivoire", 'Denmark', 'Djibouti', 'Dominica', 'Dominican Republic', 'Ecuador', 'Egypt', 'El Salvador', 'Equatorial Guinea', 'Eritrea',
  'Estonia', 'Eswatini (Kingdom of)', 'Ethiopia', 'Falkland Islands (Malvinas)', 'Faroe Islands', 'Fiji', 'Finland', 'France', 'French Guiana',
  'French Polynesia', 'French Southern Territories', 'Gabon', 'Gambia', 'Georgia', 'Germany', 'Ghana', 'Gibraltar', 'Greece', 'Greenland', 'Grenada',
  'Guadeloupe', 'Guam', 'Guatemala', 'Guernsey', 'Guinea', 'Guinea-Bissau', 'Guyana', 'Haiti', 'Heard Island and McDonald Islands', 'Honduras', 'Hong Kong',
  'Hungary', 'Iceland', 'India', 'Indonesia', 'Iran (Islamic Republic of)', 'Iraq', 'Ireland (Republic of)', 'Isle of Man', 'Israel', 'Italy', 'Jamaica',
  'Japan', 'Jersey', 'Jordan', 'Kazakhstan', 'Kenya', 'Kiribati', "Korea (Democratic People's Republic of)", 'Korea (Republic of)', 'Kosovo', 'Kuwait',
  'Kyrgyzstan', "Lao People's Democratic Republic", 'Latvia', 'Lebanon', 'Lesotho', 'Liberia', 'Libya', 'Liechtenstein', 'Lithuania', 'Luxembourg',
  'Macao', 'Madagascar', 'Malawi', 'Malaysia', 'Maldives', 'Mali', 'Malta', 'Marshall Islands', 'Martinique', 'Mauritania', 'Mauritius', 'Mayotte',
  'Mexico', 'Micronesia (Federated States of)', 'Moldova (Republic of)', 'Monaco', 'Mongolia', 'Montenegro', 'Montserrat', 'Morocco', 'Mozambique',
  'Myanmar', 'Namibia', 'Nauru', 'Nepal', 'Netherlands', 'New Caledonia', 'New Zealand', 'Nicaragua', 'Niger', 'Nigeria', 'Niue', 'Norfolk Island',
  'North Macedonia (Republic of)', 'Northern Mariana Islands', 'Norway', 'Oman', 'Pakistan', 'Palau', 'Palestine (State of)', 'Panama', 'Papua New Guinea',
  'Paraguay', 'Peru', 'Philippines', 'Pitcairn', 'Poland', 'Portugal', 'Puerto Rico', 'Qatar', 'Romania', 'Russian Federation', 'Rwanda', 'Réunion',
  'Saint Barthélemy', 'Saint Helena, Ascension and Tristan da Cunha', 'Saint Kitts and Nevis', 'Saint Lucia', 'Saint Martin (French part)',
  'Saint Pierre and Miquelon', 'Saint Vincent and the Grenadines', 'Samoa', 'San Marino', 'Sao Tome and Principe', 'Saudi Arabia', 'Senegal',
  'Serbia', 'Seychelles', 'Sierra Leone', 'Singapore', 'Sint Maarten (Dutch part)', 'Slovakia', 'Slovenia', 'Solomon Islands', 'Somalia',
  'South Africa', 'South Georgia and the South Sandwich Islands', 'South Sudan', 'Spain', 'Sri Lanka', 'Sudan', 'Suriname', 'Svalbard and Jan Mayen',
  'Sweden', 'Switzerland', 'Syrian Arab Republic', 'Taiwan, Republic of China', 'Tajikistan', 'Tanzania (United Republic of)', 'Thailand', 'Timor-Leste',
  'Togo', 'Tokelau', 'Tonga', 'Trinidad and Tobago', 'Tunisia', 'Turkmenistan', 'Turks and Caicos Islands', 'Tuvalu', 'Türkiye', 'Uganda', 'Ukraine',
  'United Arab Emirates', 'United Kingdom of Great Britain and Northern Ireland', 'United States Minor Outlying Islands', 'United States of America',
  'Uruguay', 'Uzbekistan', 'Vanuatu', 'Vatican City State', 'Venezuela (Bolivarian Republic of)', 'Virgin Islands (British)',
  'Virgin Islands (U.S.)', 'Wallis and Futuna', 'Western Sahara', 'Yemen', 'Zambia', 'Zimbabwe', 'Åland Islands',];
const type_of_business = ['Manufacturer', 'Trader', 'Hospitality Contractors', 'Supplier (Materials & Hardware)', 'Service Provider', 'Other',];
const halls = ['Hall A1', 'Hall A2', 'Hall B1', 'Hall B2', 'Tent C1', 'Tent C2', 'Tent C3', 'Tent C4'];
const initial = {
  "AppSite": process.env.REACT_APP_CODE,
  SeasonId: "",
  ExhibitorId: "",
  CompanyNameEn: "",
  ContactPhone: "",
  AddressEn: "",
  FactoryAddressEn: "",
  Website: "",
  Country: "",
  ShortName: "",
  CompanyNameVn: "",
  CompanyPhone: "",
  CompanyEmail: "",
  AddressVn: "",
  Logo: "",
  ContactName: "",
  ContactTitle: "",
  ContactPosition: "",
  ContactEmail: "",
  NumberOfEmployees: 0,
  TypeOfBusiness: "",
  ProductTypes: "",
  KeyProducts: "",
  Certificates: "",
  ProductionFacilities: "",
  MainMaterial: "",
  ExportMarkets: "",
  CapacityOfFactory: "",
  MOQ: "",
  Description: "",
  PromotionAtFair: "0",
  SocialNetwork: "",
  CustomOrder: 0,
  UseCustomOrder: false,
  Activated: "1",
  AccountId: "",
  Password: "",
  //QRCode: "",
  "Area": 0,
  "Avenue": "",
  "BoothNo": "",
  "Hall": "",
  "Pavilion": "",
  "VenueId": "",
  "CompanyFax": "",
  "Properties": 0,
  "ExtraValue1": "",
  "ExtraValue2": "",
  "ExtraValue3": "",
  "ExtraValue4": "",
  "ExtraValue5": "",
  SocialContact: [
    { label: "Facebook fanpage address", name: "Facebook", content: "" },
    { label: "Viber company number", name: "Viber", content: "" },
    { label: "Whatsapp company number", name: "Whatsapp", content: "" },
    { label: "Linkedin profile address", name: "Linkedin", content: "" },
    { label: "Wechat ID/ Number", name: "Wechat", content: "" },
  ],
  ExhibitorImages: [],
  ArgeeTerm: false
}
function AdminExhibitor() {
  const dispatch = useDispatch();
  const [state, setState] = useState(initial);
  const fileUpload = useRef(null);
  const uploadProductImage = useRef(null);
  const [{ ProductTypes, Certificates, MainMaterials, ExportMarkets }, setSelected] = useState({
    ProductTypes: [],
    Certificates: [],
    MainMaterials: [],
    ExportMarkets: []
  })
  const [{ stateError, errorMsg, isLoading, isError, isSuccess, isEdit,
    onKeyin, onSearch, lst_venues, err_msg }, setCommonState] = useState({
      stateError: {
        //ContactName: false,
        //ContactEmail: false,
        //ContactPhone: false,
        CompanyNameEn: false,
        CompanyNameVn: false,
        AddressEn: false,
        //Website: false,
        KeyProducts: false,
        VenueId: false,
        ProductTypes: false,
        BoothNo: false,
        //Hall: false,
        //Password: false,
        //AccountId: false,
      },
      errorMsg: {
        CompanyNameEn: "",
        CompanyNameVn: "",
        AccountId: "",
      },
      isLoading: true,
      isError: false,
      isSuccess: false,
      isEdit: false,
      onKeyin: false,
      onSearch: false,
      lst_venues: [],
      err_msg: ""
    })
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      ProductTypes: JSON.stringify(ProductTypes),
      // Certificates: JSON.stringify(Certificates),
      // MainMaterial: JSON.stringify(MainMaterials),
      // ExportMarkets: JSON.stringify(ExportMarkets),
    }))
    /*setCommonState((prev) => ({
      ...prev,
      stateError: {
        ...stateError,
        //ProductTypes: ProductTypes.length == 0,
        // Certificates: Certificates.length == 0,
        // MainMaterial: MainMaterials.length == 0,
        // ExportMarkets: ExportMarkets.length == 0
      }
    }))*/
  }, [ProductTypes,])
  useEffect(() => {
    var get_app = CommonHelper.get_app();
    get_app.then((app) => {
      setState((prev) => ({
        ...prev,
        SeasonId: app.seasonId,
      }))
      get_venues(app.seasonId);
    })
    const timer = setTimeout(() => {
      setCommonState((prev) => ({ ...prev, isLoading: false }));
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  const handle_search = () => {
    setCommonState((prev) => ({ ...prev, onSearch: true }))
  }
  const get_venues = (seasonId) => {
    setCommonState((prev) => ({ ...prev, isLoading: true }));
    var response = exhibitorApi.get_venues(seasonId);
    response.then((res) => {
      var _lst = [...lst_venues]
      if (res != null && res.code == 200) {
        res.data.forEach((item) => {
          _lst.push({ code: item.venueId, name: item.locationAbbr });
        })
      }
      setCommonState((prev) => ({
        ...prev,
        isLoading: false,
        lst_venues: _lst,
      }));
    })
  }
  const handle_change = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }))
    validate_required(e);
  }
  const validate_required = (e) => {
    const { name, value } = e.target;
    var _arr = [];
    Object.keys({ ...stateError }).map((item) => {
      _arr.push(item);
    })
    var _idx = _arr.indexOf(name);
    if (_idx >= 0) {
      setCommonState((prev) => ({
        ...prev,
        stateError: { ...stateError, [name]: CommonHelper.is_empty(value) },
      }))
    }
  }
  const validate_form = () => {
    var flag = true;
    var _arr = [];
    Object.keys({ ...stateError }).map((item) => {
      _arr.push(item);
    })
    var _data = { ...state };
    var _objError = { ...stateError };
    _arr.forEach((item, i) => {
      if (CommonHelper.is_empty(_data[item])) {
        _objError[item] = true;
        flag = false;
      }
      if (['ProductTypes'].indexOf(item) >= 0) {
        if (_data[item] === "[]") {
          _objError[item] = true;
          flag = false;
        }
      }
    })
    setCommonState((prev) => ({
      ...prev,
      stateError: _objError,
    }))
    return flag;
  }
  const handle_submit = () => {
    var _data = { ...state };
    if (validate_form()) {
      var booths = [{
        "seasonId": _data.SeasonId,
        "exhibitorId": "",
        "venueId": CommonHelper.convertToInt(_data.VenueId),
        "boothNo": _data.BoothNo,
        "hall": _data.Hall,
      }];
      _data = { ..._data, booths };
      _data.Activated = _data.Activated == "1";
      setCommonState((prev) => ({
        ...prev,
        isLoading: true,
        isError: false,
        isSuccess: false,
      }));
      var response = exhibitorApi.create_exhibitor(_data);
      response.then((res) => {
        setCommonState((prev) => ({ ...prev, isLoading: false }));
        if (res != null) {
          //console.log(res)
          if (res.code == 202) {
            setState((prev) => ({ ...prev, ExhibitorId: res.data }))
            setCommonState((prev) => ({
              ...prev,
              isSuccess: true, isError: false, onKeyin: false,
              err_msg: "Update successfully!",
            }));
          } else if (res.code == 506) {
            setCommonState((prev) => ({ ...prev, isLoading: true }));
            alert("Session is expired. Please sign in again!")
            const timer = setTimeout(() => {
              window.location.href = "/admin/login"
            }, 4000);
            return () => clearTimeout(timer);
          } else {
            setCommonState((prev) => ({
              ...prev, isError: true,
              isSuccess: false,
              err_msg: "Update failed!",
            }));
          }
        }
      })
    } else {
      setCommonState((prev) => ({
        ...prev, isError: true,
        isSuccess: false,
        err_msg: "Please, check required field!",
      }));
    }
  }
  const handle_edit = (e) => {
    if (e != null) {
      setCommonState((prev) => ({
        ...prev,
        onSearch: false,
        onKeyin: true,
        isError: false,
        isSuccess: false,
        isLoading: true,
        isEdit: true,
      }))
      var _model = {
        season_id: e.seasonId,
        exhibitorId: e.exhibitorId,
      }
      var response = exhibitorApi.edit(_model);
      response.then((res) => {
        if (res != null && res.code == 200) {
          var _data = res.data;
          var venueId = "";
          var hall = "";
          var booth = "";
          if (_data.booths != null && _data.booths.length > 0) {
            venueId = _data.booths[0].venueId;
            hall = _data.booths[0].hall;
            booth = _data.booths[0].boothNo;
          }
          if (CommonHelper.is_not_empty(_data.productTypes)) {
            setSelected((prev) => ({ ...prev, ProductTypes: JSON.parse(_data.productTypes) }))
          }
          setState((prev) => ({
            ...prev,
            ContactName: _data.contactName,
            ContactEmail: _data.contactEmail,
            ContactPhone: _data.contactPhone,
            CompanyNameEn: _data.companyNameEn,
            CompanyNameVn: _data.companyNameVn,
            AddressEn: _data.addressEn,
            Website: _data.website,
            Activated: _data.activated ? "1" : "0",
            KeyProducts: _data.keyProducts,
            VenueId: venueId,
            Hall: hall,
            BoothNo: booth,
            AccountId: _data.accountId,
            ExhibitorId: _data.exhibitorId,
            Logo: _data.logo,
            ExhibitorImages: _data.exhibitorImages ?? [],
            Description: _data.description,
            CompanyFax: _data.companyFax,
            CustomOrder: _data.customOrder,
            UseCustomOrder: _data.useCustomOrder,
            Properties: _data.properties,
            ExtraValue1: _data.extraValue1,
            ExtraValue2: _data.extraValue2,
          }))
        }
        setCommonState((prev) => ({
          ...prev,
          isLoading: false,
        }))
      })
    } else {
      setCommonState((prev) => ({
        ...prev,
        onSearch: false,
      }))
      handle_reset();
    }
  }
  const handle_reset = () => {
    setSelected((prev) => ({ ...prev, ProductTypes: [] }));
    setCommonState((prev) => ({
      ...prev,
      isEdit: false,
      isError: false,
      isSuccess: false,
    }))
    setState((prev) => ({
      ...prev,
      ContactName: initial.ContactName,
      ContactEmail: initial.ContactEmail,
      ContactPhone: initial.ContactPhone,
      CompanyNameVn: initial.CompanyNameVn,
      CompanyNameEn: initial.CompanyNameEn,
      CompanyFax: initial.CompanyFax,
      AddressEn: initial.AddressEn,
      Website: initial.Website,
      KeyProducts: initial.KeyProducts,
      VenueId: initial.VenueId,
      Hall: initial.Hall,
      BoothNo: initial.BoothNo,
      AccountId: initial.AccountId,
      ExhibitorId: "",
      ExhibitorImages: initial.ExhibitorImages,
      Logo: initial.Logo,
      Activated: "1",
      SeasonId: 0,
      Description: initial.Description,
      UseCustomOrder: initial.UseCustomOrder,
      CustomOrder: initial.CustomOrder,
      Properties: initial.Properties,
      ExtraValue1: initial.ExtraValue1,
      ExtraValue2: initial.ExtraValue2,
    }))
  }
  const upload_logo = (e) => {
    const { name, files } = e.target;
    if (files != null && files.length > 0) {
      var _data = {
        exhibitor_id: state.ExhibitorId,
        file: files[0]
      };
      var response = exhibitorApi.upload_logo(_data);
      response.then((res) => {
        if (res.status === 200) {
          if (res.data.code === 202 && res.data.data != null) {
            setState((prev) => ({ ...prev, Logo: res.data.data.img_url }))
          }
        }
      })
    }
  };
  const handleUpload = () => {
    fileUpload.current.click()
  };
  const remove_logo = () => {
    setState((prev) => ({ ...prev, Logo: "" }))
  }
  const upload_product_image = (e) => {
    const { name, files } = e.target;
    if (files != null && files.length > 0) {
      var _lst = [...state.ExhibitorImages];
      var is_present = _lst.length > 0 ? 0 : 1;
      var _data = {
        is_present,
        exhibitor_id: state.ExhibitorId,
        file: files[0]
      };
      var response = exhibitorApi.upload_image(_data);
      response.then((res) => {
        if (res.status === 200) {
          if (res.data.code === 202 && res.data.data != null) {
            _lst.push({ id: res.data.data.img_id, seasonId: 0, url: res.data.data.img_url, order: 0, representImage: is_present > 0 });
            setState((prev) => ({ ...prev, ExhibitorImages: _lst }));
          }
        }
      })
    }
  };
  const handleUploadProduct = () => {
    uploadProductImage.current.click()
  };
  const remove_product_image = (e) => {
    if (e != null) {
      var response = exhibitorApi.del_product_image(e.id);
      response.then((res) => {
        if (res != null && res.code == 203) {
          var _lst = [...state.ExhibitorImages].filter((item) => item.id != e.id);
          setState((prev) => ({ ...prev, ExhibitorImages: _lst }));
        }
      })
    }
  }
  const check_existing_name = (e) => {
    const { name, value } = e.target;
    var companyNameEn = state.CompanyNameEn;
    var companyNameVn = state.CompanyNameVn;
    if (name == "CompanyNameEn") {
      companyNameEn = value;
    } else if (name == "CompanyNameVn") {
      companyNameVn = value;
    }
    dispatch(organizerActions.set_loading());
    var response = exhibitorApi.check_existing_exhibitor_name({ companyNameEn, companyNameVn });
    response.then((res) => {
      dispatch(organizerActions.set_loading());
      if (res != null) {
        if (res.code == 405) {
          setCommonState((prev) => ({
            ...prev,
            stateError: { ...stateError, [name]: true },
            errorMsg: { ...errorMsg, [name]: "This name is existing." }
          }))
        } else {
          setCommonState((prev) => ({
            ...prev,
            stateError: { ...stateError, [name]: false },
            errorMsg: { ...errorMsg, [name]: "" }
          }))
        }
      }
    })
  }
  const check_existing_account = (e) => {
    const { name, value } = e.target;
    var accountId = state.AccountId;
    dispatch(organizerActions.set_loading());
    var response = exhibitorApi.check_existing_login_account({ accountId });
    response.then((res) => {
      dispatch(organizerActions.set_loading());
      if (res != null) {
        if (res.code == 405) {
          setCommonState((prev) => ({
            ...prev,
            stateError: { ...stateError, AccountId: true },
            errorMsg: { ...errorMsg, AccountId: "This name is existing." }
          }))
        } else {
          setCommonState((prev) => ({
            ...prev,
            stateError: { ...stateError, AccountId: false },
            errorMsg: { ...errorMsg, AccountId: "" }
          }))
        }
      }
    })
  }
  return (
    <>
      <div className='p-3 bg-white'>
        <div className='breadscum w-full'>
          <div className='w-1/2'>
            <ul className='flex flex-wrap'>
              <li className='text-sm '>
                <Link to={"/"}><FontAwesomeIcon icon={faHome} /> </Link>
              </li>
              <li className='text-sm pl-2 leading-normal before:float-left before:pr-2 before:content-["/"]'>
                <Link to={"/admin/exhibitor"}>Exhibitor </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className='flex'>
          <div className='w-2/3'>
            <h6 className='text-color-primary font-semibold text-xl'>Exhibitor</h6>
          </div>
          <div className='w-1/3 text-right'>
            <a className='cursor-pointer' onClick={handle_search}>
              <FontAwesomeIcon icon={faSearch} />
            </a>
          </div>
        </div>
        <div className='mt-5'>
          <div className='block sm:flex sm:flex-wrap'>
            {/* Contact */}
            <div className='w-full mt-2 px-2 sm:px-4'>
              <h3 className='text-2xl font-bold my-4 text-gray-800'>Contact</h3>
            </div>
            <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
              <InputTextUnit onChange={handle_change} value={state.ContactName} name='ContactName'
                label='Full Name' required={false} />
            </div>
            <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
              <InputTextUnit onChange={handle_change} value={state.ContactEmail} name='ContactEmail'
                label='Email Address' required={false} />
            </div>
            <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
              <InputTextUnit onChange={handle_change} value={state.ContactPhone} name='ContactPhone'
                label='Phone' required={false} />
            </div>
            <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
              <InputTextUnit onChange={handle_change} value={state.CompanyFax} name='CompanyFax'
                label='Fax' required={false} />
            </div>
            {/* Company */}
            <div className='w-full mt-2 px-2 sm:px-4'>
              <h3 className='text-2xl font-bold my-4 text-gray-800'>Company</h3>
            </div>
            <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
              <InputTextUnit onChange={handle_change} value={state.CompanyNameVn} name='CompanyNameVn'
                label='Vietnamese company name' required={true} isError={stateError.CompanyNameVn}
                onBlur={check_existing_name} errorMsg={errorMsg.CompanyNameVn} />
            </div>
            <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
              <InputTextUnit onChange={handle_change} value={state.CompanyNameEn} name='CompanyNameEn'
                label='English company name' required={true} isError={stateError.CompanyNameEn}
                onBlur={check_existing_name} errorMsg={errorMsg.CompanyNameEn} />
            </div>
            <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
              <InputTextUnit onChange={handle_change} value={state.AddressEn} name='AddressEn'
                label='Address' required={true} isError={stateError.AddressEn} />
            </div>
            <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
              <InputTextUnit onChange={handle_change} value={state.Website} name='Website'
                label='Website' required={false} placeholder="http://website.com" />
            </div>
            <div className='w-full mt-2 px-2 lg:px-4 lg:w-1/2'>
              <InputTextUnit onChange={handle_change} value={state.KeyProducts} name='KeyProducts'
                label='Products will be displayed at the exhibition' required={true} isError={stateError.KeyProducts} />
            </div>
            <div className='w-full mt-2 px-2 lg:px-4 lg:w-1/2'>
              <label className='required font-semibold'> Industry info</label>
              <AutocompleteInput multiple options={product_type} id="product_types" className={(stateError.ProductTypes ? "border-red-600" : "")}
                selected={ProductTypes} onChange={(e) => { setSelected((prev) => ({ ...prev, ProductTypes: e })) }} />
              <ValidateMessage msgType="required" isShow={stateError.ProductTypes} />
            </div>
            <div className='w-full mt-2 px-2 sm:px-4'>
              <label className='required font-semibold'>Company description</label>
              <TextAreaInput name="Description" className={"h-32 resize-none " + (stateError.Description ? "border-red-600" : "")}
                value={state.Description} onChange={handle_change} />
              <ValidateMessage msgType="required" isShow={stateError.Description} />
              <span className='block text-gray-500 text-xs font-semibold'>(maximum 100 words)</span>
              <span className='block text-gray-500 text-xs font-semibold'>0 of 100 max words.</span>
            </div>
            {/* Logo/Image product */}
            <div className='w-full mt-2 px-2 sm:px-4'>
              <h3 className='text-2xl font-bold my-4 text-gray-800'>Logo Company/ Image Product</h3>
            </div>
            <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
              <label className='block w-full font-semibold'>Logo</label>
              <div className='flex border border-dashed border-slate-500'>
                <div className='relative w-1/3'>
                  <button type="button" className="bg-slate-300 w-32 h-32 text-center m-2 rounded-sm font-semibold box-border cursor-pointer" onClick={handleUpload}>
                    <FontAwesomeIcon icon={faPlus} />
                    <span className='mt-2 block'>Click to upload</span>
                  </button>
                  <input
                    type="file"
                    ref={fileUpload}
                    onChange={upload_logo}
                    className="absolute w-full opacity-0 inset-0 cursor-pointer"
                  />
                </div>
                <div className='w-1/3 p-3'>
                  <p>{`Attached link Logo (PNG file removal background with a minimum size of 300x300 pixels, < 2MB)`}</p>
                </div>
                <div className='w-1/3 px-3 relative'>
                  {CommonHelper.is_not_empty(state.Logo) && (
                    <>
                      <div className='rounded-sm overflow-hidden w-full h-32 m-2 flex items-center flex-nowrap'>
                        <img src={process.env.REACT_APP_EXHIBITOR_IMAGE + state.Logo} className='w-full object-cover' />
                      </div>
                      <a className='absolute cursor-pointer top-1 right-1' onClick={remove_logo}>
                        <FontAwesomeIcon icon={faTimes} />
                      </a>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
              <label className='block w-full font-semibold'>Image Product</label>
              <div className=' border border-dashed border-slate-500'>
                <div className='flex '>
                  <div className='relative w-1/3'>
                    <button type="button" className="bg-slate-300 w-32 h-32 text-center m-2 rounded-sm font-semibold box-border cursor-pointer" onClick={handleUploadProduct}>
                      <FontAwesomeIcon icon={faPlus} />
                      <span className='mt-2 block'>Click to upload</span>
                    </button>
                    <input
                      type="file"
                      ref={uploadProductImage}
                      onChange={upload_product_image}
                      className="absolute w-full opacity-0 inset-0 cursor-pointer"
                    />
                  </div>
                  <div className='w-2/3 p-3'>
                    <p>{`Attached link 5 images product (Resolution full HD with a minimum size of 1920x1080 pixels)`}</p>
                  </div>
                </div>
                {state.ExhibitorImages != null && state.ExhibitorImages.length > 0 && (
                  <div className='w-full mt-5 mb-2 px-2 flex flex-wrap'>
                    {state.ExhibitorImages.map((item, i) => {
                      return (
                        <div className='relative mt-2' key={i}>
                          <div className='rounded-lg overflow-hidden bg-slate-200 w-32 h-32 me-2 flex items-center flex-nowrap'>
                            <img src={process.env.REACT_APP_EXHIBITOR_IMAGE + item.url} className='w-full object-cover' />
                          </div>
                          <a className='absolute cursor-pointer top-1 right-1' onClick={() => { remove_product_image(item) }}>
                            <FontAwesomeIcon icon={faTimes} />
                          </a>
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            </div>
            {/* Booth info */}
            <div className='w-full mt-2 px-2 sm:px-4'>
              <h3 className='text-2xl font-bold my-4 text-gray-800 '>Booths:</h3>
            </div>
            <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
              <SelectUnit onChange={handle_change} value={state.VenueId} name='VenueId'
                label='Venues' required={true} isError={stateError.VenueId} options={lst_venues} />
            </div>
            <div className='w-full hidden mt-2 px-2 sm:px-4 sm:w-1/2'>
              <SelectUnit onChange={handle_change} value={state.Hall} name='Hall'
                label='Hall' required={true} isError={stateError.Hall} options={halls} />
            </div>
            <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
              <InputTextUnit onChange={handle_change} value={state.BoothNo} name='BoothNo'
                label='Booth No.' required={true} isError={stateError.BoothNo} />
            </div>
            {/* Login Account */}
            <>
              <div className='w-full mt-2 px-2 sm:px-4'>
                <h3 className='text-2xl font-bold my-4 text-gray-800'>Login account</h3>
                {/* <span className='text-xs font-semibold text-gray-800'>Please choose up to 3 channels and provide one contact detail for each one. This helps the visitor contact directly for prompt requests</span> */}
              </div>

              <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
                <InputTextUnit onChange={handle_change} value={state.AccountId} name='AccountId'
                  label='Account' required={false} disabled={isEdit}
                  errorMsg={errorMsg.AccountId} onBlur={check_existing_account} />
              </div>
              <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
                <InputTextUnit onChange={handle_change} value={state.Password} name='Password'
                  label='Password' required={false} isError={false} />
              </div>
            </>
            {/* Properties */}
            <>
              <div className='w-full mt-2 px-2 sm:px-4'>
                <h3 className='text-2xl font-bold my-4 text-gray-800'>Properties</h3>
              </div>
              <div className='w-full mt-2 px-2 sm:px-4 sm:w-1/2'>
                <label className=''>
                  <input type='checkbox' checked={state.UseCustomOrder} onChange={(e) => { setState((prev) => ({ ...prev, UseCustomOrder: e.target.checked })) }} />
                  <span className='ms-2 font-semibold'>Use priority order</span>
                </label>
                <div className='w-full mt-2 sm:w-1/2'>
                  <InputTextUnit onChange={handle_change} value={state.CustomOrder} name='CustomOrder'
                    label='' required={false} disabled={!state.UseCustomOrder} />
                  <span className='text-gray-600 py-3 block'>Note: Order by Descending</span>
                </div>
              </div>
              <div className='w-full px-2 sm:px-4 sm:w-1/2 block md:flex'>
                <label className='block mt-2 w-full md:w-1/2'>
                  <input type='checkbox' checked={state.Properties == 1} onChange={(e) => { setState((prev) => ({ ...prev, Properties: e.target.checked ? 1 : 0 })) }} />
                  <span className='ms-2 font-semibold'>Show in home page</span>
                </label>
                <div className='w-full mt-2 px-2 sm:px-4 md:w-1/2'>
                  <label className='block required'>Status</label>
                  <select className='w-full h-8 py-1 px-3 rounded border-gray-400 border hover:border-cyan-900 active:border-cyan-900 focus:border-cyan-900 focus:outline-none'
                    value={state.Activated} name='Activated' onChange={handle_change}>
                    <option value="1">Active</option>
                    <option value="0">Disabled</option>
                  </select>
                </div>
              </div>
            </>
            {/* SEO */}
            <div className='w-full mt-2 px-2 sm:px-4'>
              <label className='font-semibold'>Tag</label>
              <TextAreaInput name="ExtraValue1" className={"h-32 resize-none"}
                value={state.ExtraValue1} onChange={handle_change} />
            </div>
            <div className='w-full mt-2 px-2 sm:px-4'>
              <label className='font-semibold'>Keyword</label>
              <TextAreaInput name="ExtraValue2" className={"h-32 resize-none"}
                value={state.ExtraValue2} onChange={handle_change} />
            </div>
            <div className='w-full my-14 px-2 sm:px-4'>
              <div>
                <button type='button' onClick={handle_submit}
                  className='inline-flex w-32 justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset bg-cyan-900'>
                  Submit</button>

                <button type='button' onClick={handle_reset} disabled={onKeyin}
                  className='inline-flex ms-5 justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-color-primary shadow-sm ring-1 ring-inset bg-slate-300'>
                  Create New Exhibitor</button>
              </div>
              {isError && (
                <span className='text-red-600 py-3 block'>{err_msg}</span>
              )}
              {isSuccess && (
                <span className='text-green-600 py-3 block'>{err_msg}</span>
              )}
            </div>
          </div>
        </div>
      </div>
      {isLoading && (<Loading />)}
      {onSearch && (
        <div className='absolute inset-0 p-2 bg-white'>
          <AdminExhibitorSearch onEdit={handle_edit}
            is_show={onSearch} venues={lst_venues} halls={halls} />
        </div>
      )}
    </>
  )
}

export default AdminExhibitor