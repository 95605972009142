import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCheck, faHome, faPencil, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types'
import InputTextUnit from '../../../components/controls/input-text-unit';
import SelectUnit from '../../../components/controls/select-unit';
import Loading from '../../../components/controls/loading';
import exhibitorApi from '../../../api/exhibitorApi';
import CommonHelper from '../../../helpers/common';
import ExhibitorDeleteConfirmation from '../components/delete-confirmation';

function AdminExhibitorSearch(props) {
    const { onEdit, is_show, venues, halls, ...other } = props;
    const [state, setState] = useState({
        season_id: 0,
        company_en: "",
        venue_id: "",
        hall: "",
        booth_no: "",
        is_loading: false,
        page_no: 1,
        page_size: 100,
    });
    const [{ lst_data }, setList] = useState({
        lst_data: []
    });
    const [{ is_delete, data_delete }, setCommonState] = useState({
        is_delete: false,
        data_delete: null,
    })
    const handle_edit = (e) => {
        onEdit(e);
    }
    const handle_new = () => {
        onEdit(null);
    }
    const handle_change = (e) => {
        const { name, value } = e.target;
        setState((prev) => ({ ...prev, [name]: value }));
    }
    useEffect(() => {

    }, [is_show])
    const handle_submit = () => {
        var _data = { ...state };
        _data.venue_id = CommonHelper.convertToInt(_data.venue_id);
        setState((prev) => ({ ...prev, is_loading: true }));
        var response = exhibitorApi.search(_data);
        response.then((res) => {
            var lst_data = null;
            if (res != null && res.code == 200) {
                lst_data = res.data;
            }
            setList((prev) => ({
                ...prev,
                lst_data
            }))
            setState((prev) => ({ ...prev, is_loading: false }));
        })
    }
    const confirm_delete = (model) => {
        console.log(model)
        if (model != null) {
            var data_delete = {
                exhibitorId: model.exhibitorId,
                companyNameEn: model.companyNameEn
            }
            console.log(data_delete)
            setCommonState((prev) => ({
                ...prev,
                is_delete: true,
                data_delete
            }));
        }
    }
    const handle_delete = (flag) => {
        setCommonState((prev) => ({ ...prev, is_delete: false }));
        if (flag) {
            setState((prev) => ({ ...prev, is_loading: true }));
            var _data = { ...data_delete };
            const response = exhibitorApi.del_exhibitor(_data.exhibitorId)
            response.then((res) => {
                setState((prev) => ({ ...prev, is_loading: false }));
                if (res.code == 203) {
                    handle_submit()
                } else {
                    alert("Delete failed");
                }
            })
        }
    }

    const handle_export = () => {
        var data = { ...state };
        setState((prev) => ({ ...prev, is_loading: true }));
        console.log(data)
        const response = exhibitorApi.get_exhibitors_file(data);
        response.then((res) => {
            setState((prev) => ({ ...prev, is_loading: false }));
        })
    }
    return (
        <>
            <div className='p-3 bg-white'>
                <div className='breadscum w-full flex'>
                    <div className='w-1/2'>
                        <ul className='flex flex-wrap'>
                            <li className='text-sm '>
                                <Link to={"/"}><FontAwesomeIcon icon={faHome} /> </Link>
                            </li>
                            <li className='text-sm pl-2 leading-normal before:float-left before:pr-2 before:content-["/"]'>
                                <Link to={"/admin/exhibitor"}>Exhibitor </Link>
                            </li>
                            <li className='text-sm pl-2 leading-normal before:float-left before:pr-2 before:content-["/"]'>
                                <Link >Search </Link>
                            </li>
                        </ul>
                    </div>
                    <div className='w-1/2 text-right'>
                        <a onClick={() => { handle_edit(null) }} className='cursor-pointer'>
                            <FontAwesomeIcon icon={faTimes} />
                        </a>
                    </div>
                </div>
                <div className='mt-5'>
                    <div className='w-full block sm:flex'>
                        <div className='w-full mt-2 pe-2 sm:w-2/5'>
                            <SelectUnit onChange={handle_change} value={state.venue_id} name='venue_id'
                                label='Venues' required={false} isError={false} options={venues} />
                        </div>
                        {/* <div className='w-full mt-2 px-2 sm:w-1/5'>
                            <SelectUnit onChange={handle_change} value={state.hall} name='hall'
                                label='Hall' required={false} isError={false} options={halls} />
                        </div> */}
                        <div className='w-full mt-2 px-2 sm:w-1/5'>
                            <InputTextUnit onChange={handle_change} value={state.booth_no} name='booth_no'
                                label='Booth No.' required={false} isError={false} />
                        </div>
                        <div className='w-full mt-2 ps-2  sm:w-2/5'>
                            <InputTextUnit onChange={handle_change} value={state.company_en} name='company_en'
                                label='Company Name.' required={false} isError={false} />
                        </div>
                    </div>
                    <div className='w-full'>
                        <button type='button' className='mt-5 w-32 rounded-2xl text-white py-1 px-2 box-border text-lg'
                            style={{ background: "#fa6736", }} onClick={handle_submit}>
                            Search</button>
                        <button type='button' className='mt-5 ml-5 rounded-2xl text-white py-1 px-2 box-border text-lg bg-cyan-600'
                            onClick={handle_new}>
                            Create new exhibitor </button>
                        <button type='button' className='mt-5 w-32 rounded-2xl text-white py-1 px-2 ml-5 box-border text-lg bg-green-600'
                            onClick={handle_export}>
                            Excel</button>
                    </div>
                    <div className='mt-3'>
                        <table className='border-collapse border border-slate-400 w-full'>
                            <thead>
                                <tr>
                                    <th className='border border-slate-300 w-1/12 ps-2 text-center'>#</th>
                                    <th className='border border-slate-300 ps-2 text-center'>Image</th>
                                    <th className='border border-slate-300 w-1/3 ps-2 text-center'>Company Name</th>
                                    <th className='border border-slate-300 w-1/5 ps-2 text-center'>Venue Name</th>
                                    {/* <th className='border border-slate-300 w-1/12 ps-2 text-center'>Hall</th> */}
                                    <th className='border border-slate-300 w-1/12 ps-2 text-center'>Booth No</th>
                                    <th className='border border-slate-300 w-1/12 ps-2 text-center'>Status</th>
                                    <th className='border border-slate-300 w-1/12'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {lst_data.map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td className='border border-slate-300 w-8 pe-2 text-end'>
                                                <div className='w-4/5 mx-auto'>{i + 1}</div>
                                            </td>
                                            <td className='border border-slate-300 ps-2'>
                                                <img src={process.env.REACT_APP_EXHIBITOR_IMAGE + item.imageUrl} className='w-40 rounded-md my-2' />
                                            </td>
                                            <td className='border border-slate-300 ps-2'>{item.companyNameVn}</td>
                                            <td className='border border-slate-300 ps-2'>{item.venueName}</td>
                                            {/* <td className='border border-slate-300 ps-2'>{item.hall}</td> */}
                                            <td className='border border-slate-300 ps-2'>{item.boothNo}</td>
                                            <td className='border border-slate-300 ps-2 text-center'>
                                                {item.activated && (
                                                    <FontAwesomeIcon icon={faCheck} className='text-green-700' />
                                                )}
                                                {!item.activated && (
                                                    <FontAwesomeIcon icon={faBan} className='text-red-700' />
                                                )}
                                            </td>
                                            <td className='border border-slate-300 ps-2'>
                                                <div className='flex'>
                                                    <a className='block flex-auto cursor-pointer text-center' onClick={() => { handle_edit(item) }}>
                                                        <FontAwesomeIcon icon={faPencil} />
                                                    </a>
                                                    <a className='block flex-auto cursor-pointer text-center text-red-700'
                                                        onClick={() => { confirm_delete(item) }}>
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {state.is_loading && (<Loading />)}
            {is_delete && (<ExhibitorDeleteConfirmation data={data_delete} isShow={is_delete} onClose={(flag) => { handle_delete(flag) }} />)}
        </>
    )
}

AdminExhibitorSearch.propTypes = {
    onEdit: PropTypes.func.isRequired,
}

export default AdminExhibitorSearch
