import { faCalendar, faCheck, faChevronDown, faClock, faGrip, faList, faLocationDot, faMarker } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Fragment, useState, useEffect } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import eventApi from '../api/eventApi'
import CommonHelper from '../helpers/common'

const img1 = 'https://www.hawaexpo.com/wp-content/uploads/2023/02/opening-ceremony.png'
const img2 = 'https://www.hawaexpo.com/wp-content/uploads/2023/09/visitor2-1200x800.jpg'
const img3 = 'https://www.hawaexpo.com/wp-content/uploads/2023/03/TNK08434-1199x800.jpg'

const data = [
  /*{
    "date": "06th Mar 2024",
    "time": "09:00am-10:00am",
    "location": "CONNECT HALL - Secc 799 Nguyen Van Linh, Tan Phu Ward, Dist. 7, HCMC, Vietnam",
    "title": "Vibe 2024 Opening Ceremony",
    "imageUrl": img1,
    "cate": ["Opening Ceremony", "All"],
  },
  {
    "date": "06th Mar 2024",
    "time": "01:30pm-04:00pm",
    "location": "CONNECT HALL - Secc 799 Nguyen Van Linh, Tan Phu Ward, Dist. 7, HCMC, Vietnam",
    "title": "[FORUM] Vietnam Furniture Industry Outlook 2024",
    "imageUrl": "https://www.hawaexpo.com/wp-content/uploads/2023/02/forum-vietnam-furniture-industry-outlook.jpg",
    "cate": ["Furniture", "Opening", "All"],
  },
  {
    "date": "06th Mar 2024",
    "time": "06:00pm-09:00pm",
    "location": "CREATE HALL - White Palace 588 Pham Van Dong Street, Thu Duc City, Ho Chi Minh City",
    "title": "Vibe Gala Dinner – Opening Ceremony 2024",
    "imageUrl": "https://www.hawaexpo.com/wp-content/uploads/2023/02/gala-dinner.png",
    "cate": ["Opening Ceremony", "Gala Dinner", "All"],
  }*/
]

const people = [
  /*{ name: 'All' },
  { name: 'Opening' },
  { name: 'Opening Ceremony' },
  { name: 'Gala Dinner' },*/
  { "code": "", "name": 'All' },
  { "code": "PRESS-CONFERENCE", "name": "PRESS CONFERENCE (Họp báo)" },
  { "code": "GALA-DINNER", "name": "GALA DINNER (dạ tiệc)" },
  { "code": "SEMINAR-OR-WORKSHOPS", "name": "SEMINAR OR WORKSHOPS (Hội thảo)" },
  { "code": "PRODUCT-OR-SOLUTION", "name": "PRODUCT OR SOLUTION (Trình bày sản phẩm hoặc giải pháp)" },
]


const GridView = (props) => {
  const { list, ...other } = props
  const [state, setState] = useState([]);
  useEffect(() => {
    setState(list);
  }, [list])

  const lst = state.map((item, i) => {
    return (
      <li className="w-1/2 px-10 pb-4" key={i}>
        <div className='section-event mb-10'>
          <div className='w-full'>
            <img src={process.env.REACT_APP_EXHIBITOR_IMAGE + item.image} title={item.event_name_vn} className='object-cover w-full' />
          </div>
          <div className='text-xl text-gray-700 box-border'>
            <a href={process.env.REACT_APP_NEWS_DOMAIN_NAME + "/event/" + item.url}>
              <h3 className='text-3xl mb-5 font-bold text-color-primary'>
                {item.event_name_vn}
              </h3>
            </a>
            <div className='my-3 flex items-center'>
              <span>
                <FontAwesomeIcon icon={faCalendar} className='mr-2' />
                <span>{item.show_dt}</span>
              </span>
              <span className='ml-5'>
                <FontAwesomeIcon icon={faClock} className='mr-2' />
                <span>{item.show_time} - {item.end_time}</span>
              </span>
            </div>
            <div className='mb-5'>
              <FontAwesomeIcon icon={faLocationDot} className='mr-2' />
              <span>{item.location_vn}</span>
            </div>
          </div>
        </div>
      </li>
    );
  })
  return (<ul className='flex flex-wrap -mx-10'>{lst}</ul>)
}

const ListView = (props) => {
  const { list, ...other } = props
  const [state, setState] = useState([]);
  useEffect(() => {
    setState(list);
  }, [list])

  const lst = state.map((item, i) => {
    return (
      <div className='section-event mb-10 block sm:flex' key={i}>
        <div className='w-full sm:w-1/2'>
          <img src={process.env.REACT_APP_EXHIBITOR_IMAGE + item.image} title={item.event_name_vn} className='object-cover w-full' />
        </div>
        <div className='w-full sm:w-1/2 text-xl text-gray-700 mt-2 sm:mt-0 sm:pl-10 box-border'>
          <a href={process.env.REACT_APP_NEWS_DOMAIN_NAME + "/event/" + item.url}>
            <h3 className='text-3xl mb-5 font-bold text-color-primary'>
              {item.event_name_vn}
            </h3>
          </a>
          <div className='my-3 flex items-center'>
            <span>
              <FontAwesomeIcon icon={faCalendar} className='mr-2' />
              <span>{item.show_dt}</span>
            </span>
            <span className='ml-5'>
              <FontAwesomeIcon icon={faClock} className='mr-2' />
              <span>{item.show_time} - {item.end_time}</span>
            </span>
          </div>
          <div className='mb-5'>
            <FontAwesomeIcon icon={faLocationDot} className='mr-2' />
            <span>{item.location_vn}</span>
          </div>
        </div>
      </div>
    );
  })
  return (<>{lst}</>)
}

function Event() {
  const [selected, setSelected] = useState(people[0]);
  const [{ lst_data, lst_result, view }, setCommonState] = useState({
    lst_data: [],
    lst_result: [],
    view: "list"
  })
  useEffect(() => {
    var lst_result = lst_data;
    if (CommonHelper.is_not_empty(selected.code)) {
      var lst_result = [...lst_data].filter(x => x.event_type.indexOf(selected.code) >= 0);
    }
    setCommonState((prev) => ({ ...prev, lst_result }));
  }, [selected])
  useEffect(() => {
    get_event();
  }, [])
  const get_event = () => {
    const response = eventApi.get();
    response.then((res) => {
      if (res.code == 200) {
        var lst_data = [];
        for (var i = 0; i < res.data.length; i++) {
          var item = res.data[i];
          item["show_dt"] = CommonHelper.convertToDDMMYYYY(item.show_time);
          item.show_time = CommonHelper.convertToHHMM(item.show_time);
          item.end_time = CommonHelper.convertToHHMM(item.end_time);
          lst_data.push(item);
        }
        console.log(lst_data)
        setCommonState((prev) => ({ ...prev, lst_data, lst_result: lst_data }));
      }
    })
  }
  return (
    <div>
      <div className='h-60 bg-header'>
        <div className='h-full flex items-center' style={{ backgroundColor: "#0000005f" }}>
          <div className='w-full'>
            <h1 className='block text-4xl alt-font text-white text-center font-bold'>EVENTS</h1>
          </div>
        </div>
      </div>
      <div className='mx-auto max-w-7xl py-6 px-5 sm:px-6 lg:px-8'>
        <div className='mb-3 flex'>
          <div className='w-1/2'>
            <div className='relative'>
              <div className='text-xl w-full sm:w-3/5'>
                <Listbox value={selected} onChange={setSelected}>
                  <div className="relative mt-1">
                    <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300">
                      <span className="block truncate">{selected.name}</span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <FontAwesomeIcon icon={faChevronDown}
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none">
                        {people.map((person, personIdx) => (
                          <Listbox.Option
                            key={personIdx}
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                              }`
                            }
                            value={person}
                          >
                            {({ selected }) => (
                              <>
                                <span
                                  className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                    }`}
                                >
                                  {person.name}
                                </span>
                                {selected ? (
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                    <FontAwesomeIcon icon={faCheck} className="h-5 w-5" aria-hidden="true" />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              </div>
            </div>
          </div>
          <div className='w-1/2 hidden sm:block text-right'>
            <a onClick={() => { setCommonState((prev) => ({ ...prev, view: "grid" })) }} className='inline-block p-3 text-3xl cursor-pointer'>
              <FontAwesomeIcon icon={faGrip} />
            </a>
            <a onClick={() => { setCommonState((prev) => ({ ...prev, view: "list" })) }} className='inline-block p-3 text-3xl cursor-pointer'>
              <FontAwesomeIcon icon={faList} />
            </a>
          </div>
        </div>

        <div className=''>
          {view == "list" && <ListView list={lst_result} />}
          {view == "grid" && <GridView list={lst_result} />}
        </div>
      </div>
    </div>
  )
}

export default Event