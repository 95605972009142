import api from "./api";
import localforage from "localforage";
import CommonHelper from "../helpers/common";

const Authentication = {
    get_token: async () => {
        var _token = await localforage.getItem("vibe.online.token");
        if (!CommonHelper.is_not_empty(_token)) {
            var _url = "authentication/get_access_token?"
                + "user=" + process.env.REACT_APP_AUTH_USER
                + "&password=" + process.env.REACT_APP_AUTH_PWD;
            var res = await api.get(_url);
            if (res != null) {
                localforage.setItem('vibe.online.token', res.token);
                Authentication.get_app();
            }
        }
    },
    reset_token: async () => {
        localforage.setItem('vibe.online.token', "");
        Authentication.get_token();
    },
    check_token_expired: async () => {
        var _token = await localforage.getItem("vibe.online.token");
        if (CommonHelper.is_not_empty(_token)) {
            var _url = "authentication/check_token_expired";
            var callApi = await api.get(_url);
            if (callApi == null || callApi.status !== 200) {
                Authentication.reset_token();
            }
            Authentication.get_app();
        } else {
            Authentication.get_token();
        }
    },
    get_app: async () => {
        var callApi = await api.get("exposeason/get_app/" + process.env.REACT_APP_CODE);
        if (callApi.code == 200) {
            localforage.setItem('vibe.app', callApi.data);
            return { code: callApi.code, msg: callApi.msg, data: callApi.data };
        } else if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    save_exposeason: async (data) => {
        data["AppSite"] = process.env.REACT_APP_CODE;
        var callApi = await api.post("exposeason/save_exposeason", data);
        if (callApi.code === 401) {
            console.log(callApi);
            return { code: 506 };
        }
        return callApi;
    },
    exhibitor_login: async (data) => {
        data["AppSite"] = process.env.REACT_APP_CODE;
        var callApi = await api.post("exhibitor/login", data);
        if (callApi.code == 208) {
            localStorage.setItem('vibe.online.session', callApi.data.session_id);
            localforage.setItem('vibe.online.user', callApi.data.data);
            return { code: callApi.code, data: callApi.data.data };
        } else if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    exhibitor_reset_pwd: async (data) => {
        data["AppSite"] = process.env.REACT_APP_CODE;
        var callApi = await api.post("exhibitor/reset_pwd", data);
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    exhibitor_user: async () => {
        //Authentication.get_token();
        var session_id = CommonHelper.get_session_id();
        if (CommonHelper.is_not_empty(session_id)) {
            var callApi = await api.get("exhibitor/check_session?session_id=" + session_id);
            if (callApi.code == 200) {
                var _objUser = await CommonHelper.get_user();
                return { code: 200, data: _objUser };
            } else if (callApi.code === 401) {
                console.log(callApi);
            }
            CommonHelper.clear_storage_user();
            CommonHelper.clear_session();
            return { code: 506 };
        }
        return { code: 506 };
    },
    exhibitor_logout: async () => {
        var session_id = CommonHelper.get_session_id();
        if (CommonHelper.is_not_empty(session_id)) {
            var callApi = await api.put("exhibitor/logout?session_id=" + session_id);
            if (callApi.code === 704) {
                return { code: callApi.code };
            } else if (callApi.code === 401) {
                console.log(callApi);
            }
            CommonHelper.clear_storage_user();
            CommonHelper.clear_session();
            return { code: 506 };
        }
        return { code: 506 };
    },
    organizer_login: async (data) => {
        data["AppSite"] = process.env.REACT_APP_CODE;
        var callApi = await api.post("online/login", data);
        if (callApi.code == 208) {
            localStorage.setItem('vibe.online.session', callApi.data.session_id);
            localforage.setItem('vibe.online.user', callApi.data.data);
            return { code: callApi.code, data: callApi.data.data };
        } else if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    organizer_user: async () => {
        //Authentication.get_token();
        var session_id = CommonHelper.get_session_id();
        if (CommonHelper.is_not_empty(session_id)) {
            var callApi = await api.get("online/check_session?session_id=" + session_id);
            if (callApi.code == 200) {
                return { code: 200 };
            } else if (callApi.code === 401) {
                console.log(callApi);
            }
            CommonHelper.clear_storage_user();
            CommonHelper.clear_session();
            return { code: 506 };
        }
        return { code: 506 };
    },
    organizer_logout: async () => {
        var session_id = CommonHelper.get_session_id();
        if (CommonHelper.is_not_empty(session_id)) {
            var callApi = await api.put("online/logout?session_id=" + session_id);
            if (callApi.code === 704) {
                return { code: callApi.code };
            } else if (callApi.code === 401) {
                console.log(callApi);
            }
            CommonHelper.clear_storage_user();
            CommonHelper.clear_session();
            return { code: 506 };
        }
        return { code: 506 };
    },
};

export default Authentication;