import React from 'react'

function BookASpace() {
  return (
    <div className='w-full p-4'>
      <iframe src='https://vme.hawaexpo.com/floorplan/5/' style={{ height: "750px", width: "100%" }}></iframe>
    </div>
  )
}

export default BookASpace