import React, { useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// import required modules
import { Autoplay, Keyboard, Navigation, Pagination, EffectCards } from 'swiper/modules';

function ExhibitorGallery(props) {
    const { list, ...other } = props;
    const [state] = useState(list);
    return (
        <>
            <Swiper
                effect={'cards'}
                navigation={true}
                grabCursor={true}
                modules={[EffectCards, Autoplay, Keyboard, Pagination, Navigation]}
                className="mySwiper"
            >
                {state.length > 0 && (
                    <>
                        {state.map((item, i) => {
                            return (
                                <SwiperSlide key={i}>
                                    <img src={process.env.REACT_APP_EXHIBITOR_IMAGE + item.url} className='rounded-md' />
                                </SwiperSlide>
                            )
                        })}
                    </>
                )}
            </Swiper>
        </>
    )
}

export default ExhibitorGallery