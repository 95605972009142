import React, { useState, useEffect } from 'react'
import { redirect, useLoaderData } from 'react-router-dom';
import Authentication from '../api/authApi';
import Loading from '../components/controls/loading';
import LoginForm from './form'
import CommonHelper from '../helpers/common';
import { useSelector, useDispatch } from 'react-redux';
import { authActions } from '../_store';

/*export async function loader() {
    Authentication.check_token_expired();
    return null;
}*/

function Login() {
    const dispatch = useDispatch();
    const authLoading = useSelector(x => x.auth.isLoading)
    const authLogged = useSelector(x => x.auth.isLogged)
    const [{ show_loading }, setSelected] = useState({
        show_loading: true,
    });
    useEffect(() => {
        dispatch(authActions.check_token());
        dispatch(authActions.check_session())
        const timer = setTimeout(() => {
            setSelected((prev) => ({ ...prev, show_loading: false }));
        }, 1000);
        return () => clearTimeout(timer);
    }, [])
    useEffect(() => {
        setSelected((prev) => ({ ...prev, show_loading: authLoading }))
    }, [authLoading])
    useEffect(() => {
        console.log(authLogged);
        if (authLogged) {
            window.location.href = "/exhibitor-profile"
        } 
    }, [authLogged])
    const handle_submit = (data) => {
        if (data != null) {
            dispatch(authActions.login(data))
        }
    }
    return (
        <div className='inset-0 fixed'>
            <div className='mx-auto md:w-6/12 sm:w-full shadow-2xl md:mt-10 rounded-lg'>
                <div className='hidden md:block'>
                    <div className='flex'>
                        <LoginForm onSubmit={handle_submit} />
                    </div>
                </div>
                <div className='md:hidden'>
                    <div className='flex flex-col mx-3'>
                        <LoginForm onSubmit={handle_submit} />
                    </div>
                </div>
            </div>
            {show_loading && (<Loading />)}
        </div>
    )
}

export default Login