import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
// import required modules
import { Autoplay, Keyboard, Navigation, EffectFade, Pagination } from 'swiper/modules';

const logo1 = process.env.REACT_APP_EXHIBITOR_IMAGE + '/images/logo.kienviet.svg'
const logo3 = process.env.REACT_APP_EXHIBITOR_IMAGE + '/images/CIO-Vietnam-logo.png'
const logo5 = process.env.REACT_APP_EXHIBITOR_IMAGE + '/images/saca_logo_CHON_1.jpg'
const logo2 = process.env.REACT_APP_EXHIBITOR_IMAGE + '/images/ELLE_Decoration-logo.png'
const logo4 = process.env.REACT_APP_EXHIBITOR_IMAGE + '/images/logo-hawa.jpg'

const lst_image = [logo1, logo2, logo3, logo4, logo5]

const lst_logo = [
    "/images/v1/logo-09.jpg",
    "/images/v1/logo-10.jpg",
    "/images/v1/logo-11.jpg",
    "/images/v1/logo-12.jpg",
    "/images/v1/logo-13.jpg",
    "/images/v1/logo-14.jpg",
    "/images/v1/logo-15.jpg",
    "/images/v1/logo-16.jpg",
    "/images/v1/logo-17.jpg",
    "/images/v1/logo-18.jpg",
]

function EndorsedBy() {
    return (
        <>
            <div className='hidden md:block'>
                <Swiper
                    watchSlidesProgress={true} slidesPerView={4}
                    pagination={true}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    modules={[EffectFade, Autoplay, Keyboard, Pagination]}
                    className="mySwiper"
                >
                    {lst_logo.map((item, i) => {
                        return (
                            <SwiperSlide key={i}>
                                <div className='size-52 flex items-center mr-5'>
                                    <div className='mx-auto p-3 overflow-hidden'>
                                        <img className='w-full' src={process.env.REACT_APP_EXHIBITOR_IMAGE + item} style={{ height: "auto" }} />
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>
            <div className='md:hidden'>
                <Swiper
                    watchSlidesProgress={true} slidesPerView={2}
                    pagination={true}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    modules={[EffectFade, Autoplay, Keyboard, Pagination]}
                    className="mySwiper"
                >
                    {lst_logo.map((item, i) => {
                        return (
                            <SwiperSlide key={i} className='mx-auto'>
                                <div className='size-52 mx-auto flex items-center'>
                                    <div className='mx-auto w-2/3 p-3 overflow-hidden'>
                                        <img className='w-full h-auto' src={process.env.REACT_APP_EXHIBITOR_IMAGE + item} />
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>
        </>
    )
}

export default EndorsedBy