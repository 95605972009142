import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Authentication from "../api/authApi";

const name = "organizer";
const initialState = {
    user: {},
    token: null,
    isLoading: false,
    isLogged: false,
    error: null
}
const extraActions = createExtraActions();

function createExtraActions() {
    const AppSite = `${process.env.REACT_APP_CODE}`

    return {
        login: login(),
        check_session: check_session()
    };

    function login(action) {
        return createAsyncThunk(
            `${name}/login`,
            async ({ AccountId, Password }) => await Authentication.organizer_login({ AppSite, AccountId, Password })
        );
    }
    function check_session() {
        return createAsyncThunk(
            `${name}/check_session`,
            async () => await Authentication.organizer_user()
        );
    }
}

const slice = createSlice({
    name,
    initialState,
    reducers: {
        check_token: () => {
            Authentication.check_token_expired();
        },
        set_loading: (state) => {
            state.isLoading = !state.isLoading;
        }
    },
    extraReducers: (builder) => {
        const { login, check_session } = extraActions
        builder
            .addCase(login.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(check_session.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(login.fulfilled, (state, action) => {
                const res = action.payload
                if (res.code == 208) {
                    state.user = res.data
                    window.location.href = "/admin"
                }
                state.isLoading = false;
            })
            .addCase(check_session.fulfilled, (state, action) => {
                const res = action.payload
                console.log(res)
                if (res.code == 200) {
                    state.isLogged = true;
                    if (state.user == null) {
                        state.user = res.data;
                    }
                }
                state.isLoading = false;
            })
            .addCase(login.rejected, (state, action) => {
                state.error = action.error.message
            })
            .addCase(check_session.rejected, (state, action) => {
                state.error = action.error.message
            })
    }
})


export const organizerActions = { ...slice.actions, ...extraActions };
export const organizerReducer = slice.reducer;

