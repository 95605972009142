import api from "./api";
import axios from "axios";
import localforage from "localforage";
import CommonHelper from "../helpers/common";

const onlineApi = {
    get_banner: async () => {
        var callApi = await api.get("online/get_banner/" + `${process.env.REACT_APP_CODE}`);
        if (callApi.code === 401) {
            console.log(callApi);
            return { code: 506 }
        }
        return callApi;
    },
    get_commons: async (type) => {
        var callApi = await api.get("common/" + type);
        if (callApi.code === 401) {
            console.log(callApi);
            return { code: 506 }
        }
        return callApi;
    },
    get_landing_page_config: async () => {
        var callApi = await api.get("online/get_landing_page_config/`${process.env.REACT_APP_CODE}`?type=HOME_CONFIG");
        if (callApi.code === 401) {
            console.log(callApi);
            return { code: 506 }
        }
        return callApi;
    }
}

export default onlineApi;