import React, { useState, useEffect } from 'react'
import { redirect, useLoaderData } from 'react-router-dom';
import Authentication from '../../api/authApi';
import Loading from '../../components/controls/loading';
import LoginForm from './form';
import CommonHelper from '../../helpers/common';
import { useSelector, useDispatch } from 'react-redux';
import { authActions } from '../../_store';

export async function loader() {
  await Authentication.check_token_expired();
  var check_session = await Authentication.organizer_user();
  if (check_session != null && check_session.code == 200) {
    var obj_user = await CommonHelper.get_user();
    if (obj_user != null && obj_user.role != null
      && obj_user.role.access_Rights != null && obj_user.role.access_Rights.length > 0
      && obj_user.role.access_Rights[0].page != null) {
      var _page = obj_user.role.access_Rights[0].page;
      //console.log(_page);
      window.location.href = _page.uiText;
    }
  }
  return null;
}



function AdminLogin() {
  const authLoading = useSelector(x => x.auth.isLoading)
  const [{ show_loading, season_id }, setSelected] = useState({
    show_loading: true,
    season_id: 0,
  });
  useEffect(() => {
    /*var get_app = CommonHelper.get_app();
    get_app.then((res) => {
      if (res != null) {
        setSelected((prev) => ({ ...prev, season_id: res.seasonId }));
      }
    })*/
    const timer = setTimeout(() => {
      setSelected((prev) => ({ ...prev, show_loading: false }));
    }, 1000);
    return () => clearTimeout(timer);
  }, [])
  useEffect(() => {
    setSelected((prev) => ({ ...prev, show_loading: authLoading }))
  }, [authLoading])
  const handle_submit = (data) => {
    if (data != null) {
      //data.SeasonId = season_id;
      setSelected((prev) => ({ ...prev, show_loading: true }));
      var response = Authentication.organizer_login(data);
      response.then((res) => {
        //console.log(res)
        setSelected((prev) => ({ ...prev, show_loading: false }));
        if (res.code == 208) {
          var obj_user = res.data;
          if (obj_user != null && obj_user.role != null
            && obj_user.role.access_Rights != null && obj_user.role.access_Rights.length > 0
            && obj_user.role.access_Rights[0].page != null) {
            var _page = obj_user.role.access_Rights[0].page;
            window.location.href = _page.uiText;
          }
        }
      })
    }
  }
  return (
    <div className='inset-0 fixed'>
      <div className='mx-auto md:w-6/12 sm:w-full shadow-2xl md:mt-10 rounded-lg'>
        <div className='hidden md:block'>
          <div className='flex'>
            <LoginForm onSubmit={handle_submit} />
          </div>
        </div>
        <div className='md:hidden'>
          <div className='flex flex-col mx-3'>
            <LoginForm onSubmit={handle_submit} />
          </div>
        </div>
      </div>
      {show_loading && (<Loading />)}
    </div>
  )
}

export default AdminLogin