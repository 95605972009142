import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ValidateMessage from './validate-msg';

function SelectUnit(props) {
    const { onChange, value, name, label, required, options, ...other } = props;
    const [state, setState] = useState([]);
    const [{ msg, isShow }, setSelected] = useState({
        msg: "required",
        isShow: other.isError
    })
    const handle_change = (e) => {
        const { name, value } = e.target;
        setState(value);
        onChange(e);
    }
    useEffect(() => {
        var flag = other.isError
        setSelected((prev) => ({
            ...prev,
            isShow: flag,
        }))
    }, [other.isError])
    useEffect(() => {
        setState(value);
    }, [value])
    return (
        <>
            <label className={'block font-semibold alt-font' + (required ? " required" : "")}>{label}</label>
            {/* <TextInput name={name} value={state} onChange={handle_change} className={(isShow ? "border-red-600" : "")} /> */}
            <select name={name} value={state} onChange={handle_change} className={'w-full h-8 py-1 px-3 rounded border-gray-400 border hover:border-cyan-900 active:border-cyan-900 focus:border-cyan-900 focus:outline-none ' + (isShow ? "border-red-600" : "")}>
                <option value=""></option>
                {options != null && options.length > 0 && (
                    <>
                        {options.map((item, i) => {
                            if (typeof item === 'object') {
                                return (
                                    <option value={item.code} key={i}>{item.name}</option>
                                )
                            } else {
                                return (
                                    <option value={item} key={i}>{item}</option>
                                )
                            }

                        })}
                    </>
                )}
            </select>
            {required && (
                <ValidateMessage msgType={msg} isShow={isShow} />
            )}
        </>
    )
}

SelectUnit.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    options: PropTypes.array.isRequired
}

export default SelectUnit
