import React, { useState, useEffect } from 'react'
import { Link, resolvePath, useLoaderData } from 'react-router-dom'
import newsApi from '../../api/newsApi'
import CommonHelper from '../../helpers/common'
import Loading from '../../components/controls/loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { Helmet } from 'react-helmet-async'

export async function loader({ params }) {
    const res = await newsApi.get_news_by_url(params.url);
    if (res != null && res.code == 200) {
        res.data.url = params.url
        return res.data
    }
    return {
        "title": "",
        "shortContent": "",
        "publishDate": "20240621 000000",
        "publisher": "",
        "image": "",
        "content": "",
        "extraValue1": "",
        "extraValue2": "",
        "extraValue3": "",
        "extraValue4": "",
        "extraValue5": "",
        "url": params.url
    };
}


function NewsDetail() {
    const news = useLoaderData();
    const [{ is_loading }, setSelected] = useState({
        is_loading: false
    })
    const set_loading = (is_loading) => {
        setSelected((prev) => ({ ...prev, is_loading }));
    }

    return (
        <>
            <Helmet>
                <title>{`thevibexpo - ${news.title}`}</title>
                <meta name="description" content={`${news.title}`} />
                <meta name="keywords" content={news.extraValue2} />
                <meta name="author" content="thevibexpo.com" />
                <meta property="og:locale" content="vi_VN" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={`${news.title}`} />
                <meta property="og:description" content={`${news.shortContent}`} />
                <meta property="og:url" content={"https://thevibexpo.com/news/" + news.url} />
                <meta property="og:site_name" content="Thevibexpo" />
                <meta property="article:publisher" content="https://www.facebook.com/Vibexpo" />
                <meta property="article:section" content="Chưa phân loại" />
                <meta property="og:image" content={process.env.REACT_APP_DOMAIN_NAME + process.env.REACT_APP_EXHIBITOR_IMAGE + news.image} />
                <meta property="og:image:secure_url" content={process.env.REACT_APP_DOMAIN_NAME + process.env.REACT_APP_EXHIBITOR_IMAGE + news.image} />
                <meta property="og:image:width" content="1496" />
                <meta property="og:image:height" content="1566" />
                <meta property="og:image:alt" content={`${news.title}`} />
                <meta property="og:image:type" content="image/png" />
                <meta property="article:published_time" content="2024-03-26T16:59:19+07:00" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={`${news.title}`} />
                <meta name="twitter:description" content={`${news.shortContent}`} />
                <meta name="twitter:image" content={process.env.REACT_APP_DOMAIN_NAME + process.env.REACT_APP_EXHIBITOR_IMAGE + news.image} />
                <meta name="twitter:label1" content="Written by" />
                <meta name="twitter:data1" content="admin" />
                <meta name="twitter:label2" content="Time to read" />
                <meta name="twitter:data2" content="4 minutes" />
                <link rel="canonical" href={news.url} />
            </Helmet>
            <div className='mx-auto max-w-7xl py-5 px-4 sm:px-6 lg:px-8'>
                <div className='breadscum w-full'>
                    <ul className='block md:flex md:flex-wrap'>
                        <li className='text-sm '>
                            <a href={"/"}><FontAwesomeIcon icon={faHome} /> </a>
                        </li>
                        <li className='text-sm pl-2 leading-normal before:float-left before:pr-2 before:content-["/"]'>
                            <a href={"/news"}>Tin tức </a>
                        </li>
                        {news != null && (
                            <li className='text-sm pl-2 leading-normal before:float-left before:pr-2 before:content-["/"]'>
                                <a href={"/news"}>{news.title} </a>
                            </li>
                        )}
                    </ul>
                </div>
                {news != null && (
                    <>
                        <div className='py-5 border-b border-solid border-black mt-5'>
                            <h1 className='font-semibold text-2xl text-color-primary'>{news.title}</h1>
                        </div>
                        {CommonHelper.is_not_empty(news.extraValue1) && (
                            <p className='py-3'>{news.extraValue1}</p>
                        )}
                        {CommonHelper.is_not_empty(news.shortContent) && (
                            <p className='py-3'>{news.shortContent}</p>
                        )}
                        {CommonHelper.is_not_empty(news.content) && (
                            <div className='py-3'>{CommonHelper.convertStringToHTML(news.content)}</div>
                        )}
                    </>
                )}

            </div>
        </>
    )
}

export default NewsDetail
