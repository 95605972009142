import api from "./api";
import axios from "axios";
import localforage from "localforage";
import CommonHelper from "../helpers/common";

const visitorApi = {
    get: async (id) => {
        var session_id = CommonHelper.get_session_id();
        if (CommonHelper.is_not_empty(session_id)) {
            var _url = id;
            _url += "?session_id=" + session_id;
            _url += "&app_site=" + `${process.env.REACT_APP_CODE}`;
            var callApi = await api.get("visitor/" + _url);
            if (callApi.code === 401) {
                console.log(callApi);
                return { code: 506 }
            }
            return callApi;
        }
        return { code: 506 }
    },
    register: async (req) => {
        req["AppSite"] = process.env.REACT_APP_CODE;
        var callApi = await api.post("visitor/register", req);
        if (callApi.code === 401) {
            console.log(callApi);
            return { code: 506 }
        }
        return callApi;
    },
    update_visitor: async (req) => {
        req["AppSite"] = process.env.REACT_APP_CODE;
        var session_id = CommonHelper.get_session_id();
        if (CommonHelper.is_not_empty(session_id)) {
            var callApi = await api.put("visitor/update_visitor?session_id=" + session_id, req);
            if (callApi.code === 401) {
                console.log(callApi);
                return { code: 506 }
            }
            return callApi;
        }
        return { code: 506 }
    },
    get_visitors: async (req) => {
        var session_id = CommonHelper.get_session_id();
        if (CommonHelper.is_not_empty(session_id)) {
            var _url = ""
            if (req != null) {
                _url += "?session_id=" + session_id;
                _url += "&app_site=" + `${process.env.REACT_APP_CODE}`;
                _url += "&company_name=" + req.company_name;
                _url += "&contact_name=" + req.contact_name;
                _url += "&vip=" + req.vip;
                _url += "&visitor_type=" + req.visitor_type;
            }
            var callApi = await api.get("visitor/get_visitors" + _url);
            if (callApi.code === 401) {
                console.log(callApi);
                return { code: 506 }
            }
            return callApi;
        }
    },
    get_visitors_file: async (req) => {
        var token = await CommonHelper.get_token();
        var session_id = CommonHelper.get_session_id();
        var _url = process.env.REACT_APP_API_ENDPOINT + "visitor/get_visitors_file"
        if (req != null) {
            _url += "?session_id=" + session_id;
            _url += "&app_site=" + `${process.env.REACT_APP_CODE}`;
            _url += "&company_name=" + req.company_name;
            _url += "&contact_name=" + req.contact_name;
            _url += "&vip=" + req.vip;
            _url += "&visitor_type=" + req.visitor_type;
        }
        axios({
            url: _url,
            method: 'GET',
            headers: { Authorization: `Bearer ${token}`, },
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'visitor.xlsx');
            document.body.appendChild(link);
            link.click();
        });
    },
    get_common: async (code) => {
        //req["AppSite"] = process.env.REACT_APP_CODE;
        var callApi = await api.get("common/" + code);
        if (callApi.code === 401) {
            console.log(callApi);
            return { code: 506 }
        }
        return callApi;
    },
}

export default visitorApi;