import { configureStore } from "@reduxjs/toolkit";

import { authReducer } from "./auth.slice";
import { exhibitorReducer } from "./exhibitor.slice";
import { organizerReducer } from "./organizer.slice";

export * from './auth.slice';
export * from './exhibitor.slice'
export * from './organizer.slice'

export const store = configureStore({
    reducer: {
        auth: authReducer,
        exhibitor: exhibitorReducer,
        organizer: organizerReducer,
    }
})