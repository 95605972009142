import React from 'react'

function WhyExhibit() {
  return (
    <div className='w-full'>
      <iframe src='https://vme.hawaexpo.com/floorplan/5/' style={{ height: "750px", width: "100%" }}></iframe>
    </div>
  )
}

export default WhyExhibit