import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faHome, faGrip, faList } from '@fortawesome/free-solid-svg-icons';
import { Disclosure } from '@headlessui/react';
import CommonHelper from '../helpers/common';
import newsApi from '../api/newsApi'
import NewsPagination from './components/pagination'
import Loading from '../components/controls/loading';


const GridView = (props) => {
  const { list, ...other } = props
  const [state, setState] = useState([]);
  useEffect(() => {
    setState(list);
  }, [list])

  const lst = state.map((item, i) => {
    return (
      <li className="w-1/2 px-2 pb-4" key={i}>
        <a className='block w-full' href={process.env.REACT_APP_NEWS_DOMAIN_NAME + "/news/" + item.url}>
          <div className='flex'>
            <div className='flex-auto align-middle h-60 w-full overflow-hidden rounded-md'>
              <img className='object-cover' src={process.env.REACT_APP_EXHIBITOR_IMAGE + item.image} title={item.title} />
            </div>
          </div>
        </a>
        <a className="block p-1 text-color-primary hover:fs-color-secondary" href={process.env.REACT_APP_NEWS_DOMAIN_NAME + "/news/" + item.url}>
          <h3 className='font-semibold text-xl py-2'>{item.title}</h3>
        </a>
      </li>
    );
  })
  return (<ul className='flex flex-wrap'>{lst}</ul>)
}
const ListView = (props) => {
  const { list, ...other } = props
  const [state, setState] = useState([]);
  useEffect(() => {
    setState(list);
  }, [list])

  const lst = state.map((item, i) => {
    return (
      <li className="w-full sm:w-1/2 px-2 pb-4" key={i}>
        <div className='w-full block sm:flex'>
          <div className='w-full sm:w-1/3'>
            <a className='block w-full' href={process.env.REACT_APP_NEWS_DOMAIN_NAME + "/news/" + item.url}>
              <div className='flex'>
                <div className='flex-auto align-middle md:h-32 w-full overflow-hidden'>
                  <img className='object-cover rounded-md' src={process.env.REACT_APP_EXHIBITOR_IMAGE + item.image} title={item.title} />
                </div>
              </div>
            </a>
          </div>
          <div className='w-full px-3 pb-3 sm:w-2/3'>
            <a className="block text-color-primary hover:fs-color-secondary" href={process.env.REACT_APP_NEWS_DOMAIN_NAME + "/news/" + item.url}>
              <h3 className='font-semibold text-xl pb-2'>{item.title}</h3>
            </a>
          </div>
        </div>

      </li>
    );
  })
  return (<ul className='flex flex-wrap'>{lst}</ul>)
}
function News() {
  const [{ view, search_text, ex_data, lst_result, lst_data, sel_exhibitor,
    lst_proType, lst_material, lst_cert, is_loading, pagination }, setSelected] = useState({
      view: "list",
      lst_result: [],
      lst_data: [],
      pagination: {
        curr_page: 1,
        page_size: 6,
        total_records: 0,
      },
    })
  useEffect(() => {
    get_news()
  }, [])
  const set_loading = (is_loading) => {
    setSelected((prev) => ({ ...prev, is_loading }));
  }
  const get_news = () => {
    set_loading(true);
    const response = newsApi.get_news();
    response.then((res) => {
      set_loading(false);
      if (res != null && res.code == 200) {
        var obj_page = { ...pagination };
        obj_page.curr_page = 1;
        obj_page.total_records = res.totalRecords;
        setSelected((prev) => ({
          ...prev,
          lst_result: res.data,
          pagination: obj_page,
          lst_data: res.data
        }))
        set_data(res.data, 1)
      }
    })
  }
  const goto_page = (e) => {
    var obj_page = { ...pagination };
    obj_page.curr_page = e;
    setSelected((prev) => ({
      ...prev,
      pagination: obj_page,
    }))
    const data = [...lst_data]
    set_data(data, e);
  }
  const set_data = (data, curr_page) => {
    if (data != null && data.length > 0) {
      var lst_result = [];
      var _idx = (curr_page - 1) * pagination.page_size;
      data.forEach((item, i) => {
        if (i >= _idx && i < (curr_page * pagination.page_size)) {
          lst_result.push(item);
        }
      })
      setSelected((prev) => ({ ...prev, lst_result }));
    }
  }
  return (
    <>
      <div className='mx-auto max-w-7xl py-5 px-4 sm:px-6 lg:px-8'>
        <div className='breadscum w-full'>
          <ul className='block md:flex md:flex-wrap'>
            <li className='text-sm '>
              <a href={"/"}><FontAwesomeIcon icon={faHome} /> </a>
            </li>
            <li className='text-sm pl-2 leading-normal before:float-left before:pr-2 before:content-["/"]'>
              <a >Tin tức </a>
            </li>
          </ul>
        </div>
        <div className='py-6 border-b border-solid border-black mt-5'>
          <h1 className='font-semibold text-2xl text-color-primary'>Tin tức</h1>
        </div>
        <div className='mt-5'>
          <div>
            {view === "grid" && (
              <>
                {lst_result.length > 0 && (<GridView list={lst_result} />)}
              </>
            )}
            {view === "list" && (<>
              {lst_result.length > 0 && (<ListView list={lst_result} />)}
            </>
            )}
          </div>
          <div>
            <NewsPagination data={pagination} onGoTo={goto_page} />
          </div>
        </div>
      </div>
      {is_loading && (<Loading />)}
    </>
  )
}

export default News