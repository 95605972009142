import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faPencil, faTimes } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types'
import InputTextUnit from '../../../components/controls/input-text-unit';
import SelectUnit from '../../../components/controls/select-unit';
import Loading from '../../../components/controls/loading';
import exhibitorApi from '../../../api/exhibitorApi';
import CommonHelper from '../../../helpers/common';
import { organizerActions } from '../../../_store/organizer.slice'
import newsApi from '../../../api/newsApi';

function AdminNewsSearch(props) {
    const dispatch = useDispatch();
    const { onEdit, is_show, ...other } = props;
    const [state, setState] = useState({
        season_id: 0,
        title: "",
        is_loading: false,
        page_no: 1,
        page_size: 10,
    });
    const [{ lst_data }, setList] = useState({
        lst_data: []
    });
    const handle_edit = (e) => {
        onEdit(e);
    }
    const handle_change = (e) => {
        const { name, value } = e.target;
        setState((prev) => ({ ...prev, [name]: value }));
    }
    useEffect(() => {

    }, [is_show])
    const handle_submit = () => {
        dispatch(organizerActions.set_loading());
        var _data = { ...state };
        var response = newsApi.get_list(_data);
        response.then((res) => {
            if (res != null && res.code == 200) {
                setList((prev) => ({
                    ...prev,
                    lst_data: res.data
                }))
            }
            dispatch(organizerActions.set_loading());
        })
    }
    return (
        <>
            <div className='p-3 bg-white z-50 min-h-full relative'>
                <div className='breadscum w-full flex'>
                    <div className='w-1/2'>
                        <ul className='flex flex-wrap'>
                            <li className='text-sm '>
                                <Link to={"/"}><FontAwesomeIcon icon={faHome} /> </Link>
                            </li>
                            <li className='text-sm pl-2 leading-normal before:float-left before:pr-2 before:content-["/"]'>
                                <Link to={"/admin/news"}>News </Link>
                            </li>
                            <li className='text-sm pl-2 leading-normal before:float-left before:pr-2 before:content-["/"]'>
                                <Link >Search </Link>
                            </li>
                        </ul>
                    </div>
                    <div className='w-1/2 text-right'>
                        <a onClick={() => { handle_edit(null) }} className='cursor-pointer'>
                            <FontAwesomeIcon icon={faTimes} />
                        </a>
                    </div>
                </div>
                <div className='mt-5'>
                    <div className='w-full block sm:flex'>
                        <div className='w-full mt-2 px-2 sm:w-1/3'>
                            <InputTextUnit onChange={handle_change} value={state.title} name='title'
                                label='Title' required={false} isError={false} />
                        </div>
                    </div>
                    <div className='w-full'>
                        <button type='button' className='mt-5 w-32 rounded-2xl text-white py-1 px-2 box-border text-lg'
                            style={{ background: "#fa6736", }} onClick={handle_submit}>
                            Search</button>
                    </div>
                </div>
                <div className='mt-3'>
                    <table className='border-collapse border border-slate-400 w-full'>
                        <thead>
                            <tr>
                                <th className='border border-slate-300 w-1/12 ps-2 text-center'>#</th>
                                <th className='border border-slate-300 w-2/3 ps-2 text-center'>Title</th>
                                <th className='border border-slate-300 w-1/6 ps-2 text-center'>Publish Date</th>
                                <th className='border border-slate-300 w-1/6 ps-2 text-center'>Status</th>
                                <th className='border border-slate-300'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {lst_data.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td className='border border-slate-300 w-8 pe-2 text-end'>{i + 1}</td>
                                        <td className='border border-slate-300 ps-2'>{item.title}</td>
                                        <td className='border border-slate-300 ps-2'>{CommonHelper.convertToDDMMYYYYHHMM(item.publishDate)}</td>
                                        <td className='border border-slate-300 ps-2'>{(item.activated ? "Active" : "Disabled")}</td>
                                        <td className='border border-slate-300 ps-2'>
                                            <div className='flex'>
                                                <a className='block flex-auto cursor-pointer text-center' href={`/admin/news?id=${item.id}`}>
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </a>
                                                {/* <a className='block flex-auto cursor-pointer text-center text-red-700'>
                                                        <FontAwesomeIcon icon={faTimes} />
                                                    </a> */}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

AdminNewsSearch.propTypes = {}

export default AdminNewsSearch
