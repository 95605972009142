import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faPencil, faTimes } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types'

const data = [
    {
        "SeasonId": 1, "Year": 2024,
        "SeasonNameEN": "The largest nationally endorsed export furniture fair In Vietnam",
        "SeasonNameVN": "The largest nationally endorsed export furniture fair In Vietnam",
        "SloganEN": "", "SloganVN": "", "DescriptionEN": "",
        "DescriptionVN": "", "DateFrom": "2024-03-06", "DateTo": "2024-03-20",
        "Activated": true
    }
];
function DashboardSearch(props) {
    const { onEdit, is_show, ...other } = props;
    const [state, setState] = useState({
        year: new Date().getFullYear,
    });
    const [{ lst_data }, setList] = useState({
        lst_data: data
    });
    const handle_edit = (e) => {
        console.log(e)
        onEdit(e);
    }
    useEffect(() => {

    }, [is_show])
    return (
        <>
            <div className='p-3 bg-white'>
                <div className='breadscum w-full flex'>
                    <div className='w-1/2'>
                        <ul className='flex flex-wrap'>
                            <li className='text-sm '>
                                <Link to={"/"}><FontAwesomeIcon icon={faHome} /> </Link>
                            </li>
                            <li className='text-sm pl-2 leading-normal before:float-left before:pr-2 before:content-["/"]'>
                                <Link to={"/admin/dashboard"}>Dashboard </Link>
                            </li>
                            <li className='text-sm pl-2 leading-normal before:float-left before:pr-2 before:content-["/"]'>
                                <Link >Search </Link>
                            </li>
                        </ul>
                    </div>
                    <div className='w-1/2 text-right'>
                        <a onClick={() => { handle_edit(null) }} className='cursor-pointer'>
                            <FontAwesomeIcon icon={faTimes} />
                        </a>
                    </div>
                </div>
                <div className='mt-5'>
                    <div className='w-full block sm:flex'>
                        <div className='w-full sm:w-1/5'>
                            <label className='block'>Year</label>
                            <select className='w-full py-1 px-3 rounded border-gray-400 border hover:border-cyan-900 active:border-cyan-900 focus:border-cyan-900 focus:outline-none '>
                                <option value={2024}>2024</option>
                            </select>
                        </div>
                    </div>
                    <div className='mt-3'>
                        <table className='table-auto w-full'>
                            <thead>
                                <tr>
                                    <th className='w-1/12 text-left'>#</th>
                                    <th className='text-left'>Season name (VN)</th>
                                    <th className='w-1/3 text-left'>Time</th>
                                    <th className='w-1/12'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {lst_data.map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{item.SeasonNameVN}</td>
                                            <td>06/03/2024 - 09/03/2024</td>
                                            <td>
                                                <div className='flex'>
                                                    <a className='block flex-auto cursor-pointer text-center' onClick={() => { handle_edit(item) }}>
                                                        <FontAwesomeIcon icon={faPencil} />
                                                    </a>
                                                    <a className='block flex-auto cursor-pointer text-center text-red-700'>
                                                        <FontAwesomeIcon icon={faTimes} />
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashboardSearch

DashboardSearch.propTypes = {
    onEdit: PropTypes.func.isRequired,
}
