import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './_store';
import './index.css';
import App from './App';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './home';
import Login from './login';
import Forgot from './forgot';
import ErrorPage from './error-page';
import Admin, { loader as adminLoader } from './admin';
import AdminHome from './admin/home';
import AdminAccountManager from './admin/account-manager';
import AdminMasterData from './admin/master-data';
import AdminExhibitor from './admin/exhibitor';
import AdminBuyerVisitor from './admin/buyer-visitor';
import AdminLandingPage from './admin/landing-page';
import AdminSetting from './admin/setting'
import Exhibitor from './exhibitor';
import BookASpace from './exhibitor/pages/book-a-space';
import ExhibitorAbout from './exhibitor/pages/about';
import ExhibitorProfile from './exhibitor/pages/profile';
import ExhibitorHome from './exhibitor/pages/home';
import WhyExhibit from './why-exhibit'
import Brochure from './brochure';
import FaqExhibitor from './faq/exhibitor';
import Accommodation from './accommodation';
import ShuttleBus from './shuttle-bus';
import FaqVisitor from './faq/visitor';
import WhyVisit from './why-visit';
import Event from './event';
import Media from './media';
import News from './news';
import PhotoGallery from './photo-gallery';
import MediaDownload from './media-download';
import PostShowReport from './post-show-report';
import ExhibitorDetail, { loader as exhibitorDetailLoader } from './exhibitor/pages/detail';
import ExhibitorRegister from './exhibitor/pages/register';
import AdminLogin, { loader as adminLoginLoader } from './admin/login';
import Visitor from './visitor';
import AdminNewsPage from './admin/news';
import NewsDetail, { loader as newsDetailLoader } from './news/detail';
import AdminEvent from './admin/event';
import { HelmetProvider } from 'react-helmet-async';
import DownloadApp from './download-app';
import FloorPlan from './exhibitor/pages/floorplan';
import FloorPlan3D from './exhibitor/pages/floorplan-3d';
import AdminPageContent,
  { loader as adminPageContentLoader } from './admin/landing-page/components/page-content';


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        element: <Home />,
        index: true
      },
      {
        path: "/why-exhibit",
        element: <WhyExhibit />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/register",
        element: <WhyVisit />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/about",
        element: <ExhibitorAbout />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/media",
        element: <Media />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/news",
        element: <News />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/news/:url",
        element: <NewsDetail />,
        loader: newsDetailLoader,
        errorElement: <ErrorPage />,
      },
      {
        path: "/photo-gallery",
        element: <PhotoGallery />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/media-download",
        element: <MediaDownload />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/post-show-report",
        element: <PostShowReport />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/events",
        element: <Event />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/faq-exhibitor",
        element: <FaqExhibitor />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/faq-visitor",
        element: <FaqVisitor />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/brochure-price-list",
        element: <Brochure />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/accommodation",
        element: <Accommodation />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/shuttle-bus",
        element: <ShuttleBus />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/floorplan",
        element: <BookASpace />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/floorplan-2025",
        element: <FloorPlan />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/3d-floorplan",
        element: <FloorPlan3D />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/download-app",
        element: <DownloadApp />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/book-a-space",
        element: <BookASpace />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/exhibitor-list",
        element: <ExhibitorHome />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/exhibitor-profile",
        element: <ExhibitorProfile />,
        //loader: exhibitorRegisterLoader,
        errorElement: <ErrorPage />,
      },
      {
        path: "/exhibitor-form",
        element: <ExhibitorRegister />,
        //loader: exhibitorRegisterLoader,
        errorElement: <ErrorPage />,
      },
      /*{
        path: "/exhibitor-form/:id",
        element: <ExhibitorRegister />,
        loader: exhibitorRegisterLoader,
        errorElement: <ErrorPage />,
      },*/
      {
        path: "/exhibitor",
        element: <ExhibitorDetail />,
        loader: exhibitorDetailLoader,
        errorElement: <ErrorPage />,
      },
      {
        path: "/exhibitor/:id",
        element: <ExhibitorDetail />,
        loader: exhibitorDetailLoader,
        errorElement: <ErrorPage />,
      },
      {
        path: "/visitor",
        element: <Visitor />,
        errorElement: <ErrorPage />
      }
    ]
  },
  {
    path: "/login",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/forgot",
    element: <Forgot />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/admin",
    element: <Admin />,
    loader: adminLoader,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/admin",
        element: <AdminHome />,
        index: true,
        errorElement: <ErrorPage />
      },
      {
        path: "/admin/dashboard",
        element: <AdminHome />,
        errorElement: <ErrorPage />
      },
      {
        path: "/admin/account-manager",
        element: <AdminAccountManager />,
        errorElement: <ErrorPage />
      },
      {
        path: "/admin/master-data",
        element: <AdminMasterData />,
        errorElement: <ErrorPage />
      },
      {
        path: "/admin/exhibitor",
        element: <AdminExhibitor />,
        errorElement: <ErrorPage />
      },
      {
        path: "/admin/visitor",
        element: <AdminBuyerVisitor />,
        errorElement: <ErrorPage />
      },
      {
        path: "/admin/landing-page",
        element: <AdminLandingPage />,
        errorElement: <ErrorPage />
      },
      {
        path: "/admin/landing-page/content",
        element: <AdminPageContent />,
        errorElement: <ErrorPage />
      },
      {
        path: "/admin/landing-page/content/:id",
        element: <AdminPageContent />,
        loader: adminPageContentLoader,
        errorElement: <ErrorPage />
      },
      {
        path: "/admin/news",
        element: <AdminNewsPage />,
        errorElement: <ErrorPage />
      },
      {
        path: "/admin/setting",
        element: <AdminSetting />,
        errorElement: <ErrorPage />
      },
      {
        path: "/admin/event",
        element: <AdminEvent />,
        errorElement: <ErrorPage />
      },
    ]
  },
  {
    path: "/admin/login",
    element: <AdminLogin />,
    loader: adminLoginLoader,
    errorElement: <ErrorPage />,
  },
  /*{
    path: "/exhibitor-list",
    element: <Exhibitor />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/exhibitor-list",
        element: <ExhibitorHome />,
        errorElement: <ErrorPage />,
        index: true,
      },
      {
        path: "/exhibitor-list/book-a-space",
        element: <BookASpace />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/exhibitor-list/about",
        element: <ExhibitorAbout />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/exhibitor-list/visit",
        element: <ExhibitorVisit />,
        errorElement: <ErrorPage />,
      }
    ]
  },*/
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HelmetProvider>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </HelmetProvider>

);

