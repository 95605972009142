import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faClock, faEnvelope, faLocationDot, faPhone, faReceipt } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faLine, faLinkedin, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
//import logo_desc from '../../assets/vibeep.png'

const logo_desc = process.env.REACT_APP_EXHIBITOR_IMAGE + '/images/vibeep.png'
const img3 = process.env.REACT_APP_EXHIBITOR_IMAGE + '/images/v1/trang-chu-Vibe-04.png'
const logo_ios = process.env.REACT_APP_EXHIBITOR_IMAGE + '/images/ios.svg'
const logo_android = process.env.REACT_APP_EXHIBITOR_IMAGE + '/images/android.svg'
const qr_apk = process.env.REACT_APP_EXHIBITOR_IMAGE + '/images/qr-apk.svg'

function Footer() {
    return (
        <div className='bg-gray-100'>
            <div className='h-2 bg-secondary'></div>
            <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
                <div className='mt-10 block sm:flex '>
                    <div className='w-full sm:w-1/4 sm:pe-5'>
                        <div className='w-4/5'>
                            <img src={logo_desc} alt="VIBE" />
                        </div>
                        <div className='mt-10 flex items-center'>
                            <div className='w-2/5'>
                                <a href='https://apps.apple.com/us/app/vibe-expo/id6714471918'><img src={logo_ios} className='w-5/6 object-cover' /></a>
                            </div>
                            <div className='w-2/5'>
                                <a href='https://play.google.com/store/apps/details?id=vn.hawa.thevipexpo'><img src={logo_android} className='w-5/6 object-cover' /></a>
                            </div>
                            <div className='w-1/5'>
                                <img src={qr_apk} className='w-full object-cover' />
                            </div>
                        </div>
                    </div>
                    <div className='w-full md:w-1/2 px-10'>
                        <p className='text-2xl font-semibold'>TRIỂN LÃM NỘI THẤT VÀ XÂY DỰNG VIỆT NAM - VIBE 2025</p>
                        <h2 className='mt-3'><FontAwesomeIcon icon={faClock} /> 01-04/10/2025</h2>
                        <h2 className='mt-3'><FontAwesomeIcon icon={faLocationDot} /> SECC - 799 Nguyễn Văn Linh, P. Tân Phú, Quận 7, TP. HCM</h2>
                        <h2 className='mt-3'><FontAwesomeIcon icon={faPhone} /> <a href='tel:0966965747'>+84 96 696 5747</a></h2>
                        <h2 className='mt-3'><FontAwesomeIcon icon={faEnvelope} /> info@thevibexpo.com</h2>
                        <h2 className='mt-3 hidden'><FontAwesomeIcon icon={faLocationDot} /> 41-45 Đường số 7, Khu đô thị Vạn Phúc, Phường Hiệp Bình Phước, Thành phố Thủ Đức, Thành phố Hồ Chí Minh, Việt Nam</h2>
                        <h2 className='mt-3 hidden'><FontAwesomeIcon icon={faReceipt} /> 0317075533</h2>
                    </div>
                    <div className='w-full md:w-1/4 text-color-primary text-right'>
                        <img src={img3} className='w-full object-cover' />
                    </div>
                </div>
                <div className='hidden mx-auto max-w-7xl py-10 mt-10 text-color-primary font-semibold border-t-2 border-black border-solid' >
                    <div className='block sm:flex'>
                        <div className='block w-full sm:w-1/5'></div>
                        <div className='block w-full sm:w-3/5'>
                            <div className='w-full text-center'>
                                <p>Website được dựng bởi: <span className='font-normal'>CÔNG TY CỔ PHẦN PHÁT TRIỂN HAWA</span> </p>
                            </div>
                        </div>
                        <div className='block w-full sm:w-1/5'>
                            <div className='text-right text-color-primary'>
                                <a className="inline-block mx-3">
                                    <span>Terms</span>
                                </a>
                                <a className="inline-block mx-3">
                                    <span>Privacy</span>
                                </a>
                                <a className="inline-block mx-3">
                                    <span>Cookies</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='h-10 mt-5 bg-secondary'></div>
        </div>
    )
}

export default Footer