import React from 'react'

function Media() {
  return (
    <div className='mx-auto max-w-7xl py-6 px-5 sm:px-6 lg:px-8'>
      <div className='block lg:flex mt-5 lg:mt-20'>
        <div className='w-full lg:w-1/4'>
          <div className='box-border md:pr-5 pb-5'>
            <img src='https://www.hawaexpo.com/wp-content/uploads/2023/03/best-moment.jpg' className='h-72 w-full object-cover' />
          </div>
          <div className='box-border md:pr-5 pb-5'>
            <img src='https://www.hawaexpo.com/wp-content/uploads/2023/03/3-1200x800.jpg' className='h-72 w-full object-cover' />
          </div>
          <div className='box-border md:pr-5 pb-5'>
            <img src='https://www.hawaexpo.com/wp-content/uploads/2023/03/7-533x800.jpg' className='w-full lg:w-72 object-cover' style={{ height: "580px" }} />
          </div>
        </div>
        <div className='w-full lg:w-3/4'>
          <div className='block lg:flex'>
            <div className='w-full lg:w-2/3'>
              <div className='box-border md:pr-5 pb-5'>
                <img src='https://www.hawaexpo.com/wp-content/uploads/2023/03/2-2.png' className='h-72 w-full object-cover' />
              </div>
              <div className='box-border md:pr-5 pb-5'>
                <video className="w-full object-cover" preload playsInline autoPlay muted loop style={{ height: "580px" }}>
                  <source src="https://www.hawaexpo.com/wp-content/uploads/2023/03/video-1.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
            <div className='w-full lg:w-1/3'>
              <div className='box-border pb-5'>
                <img src='https://www.hawaexpo.com/wp-content/uploads/2023/03/2-1-533x800.jpg' className='w-full object-cover' style={{ height: "580px" }} />
              </div>
              <div className='box-border pb-5'>
                <img src='https://www.hawaexpo.com/wp-content/uploads/2023/03/register_visitor.jpg' className='h-72 w-full object-cover' />
              </div>
            </div>
          </div>
          <div className='h-72'>
            <img src='https://www.hawaexpo.com/wp-content/uploads/2023/03/8-1200x800.jpg' className='w-full h-full object-cover' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Media