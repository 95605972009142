import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCheck, faHome, faPencil, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types'
import InputTextUnit from '../../../components/controls/input-text-unit';
import SelectUnit from '../../../components/controls/select-unit';
import eventApi from '../../../api/eventApi';
import CommonHelper from '../../../helpers/common';
import DateInput from '../../../components/controls/date-input'
import { organizerActions } from '../../../_store/organizer.slice'

function AdminEventList(props) {
    const dispatch = useDispatch();
    const { onEdit, onNew, is_show, ...other } = props;
    const [state, setState] = useState({
        season_id: 0,
        eventType: "",
        eventName: "",
        fromDate: "",
        toDate: "",
        status: "",
        is_loading: false,
        page_no: 1,
        page_size: 20,
    });
    const [{ lst_data, lst_result, lst_status, from_dt, to_dt, eventTypes }, setList] = useState({
        lst_data: [],
        lst_result: [],
        lst_status: [
            { "code": "1", "name": "Active" },
            { "code": "0", "name": "Disabled" },
        ],
        from_dt: null,
        to_dt: null,
        eventTypes: [
            { "code": "PRESS-CONFERENCE", "name": "PRESS CONFERENCE (Họp báo)" },
            { "code": "GALA-DINNER", "name": "GALA DINNER (dạ tiệc)" },
            { "code": "SEMINAR-OR-WORKSHOPS", "name": "SEMINAR OR WORKSHOPS (Hội thảo)" },
            { "code": "PRODUCT-OR-SOLUTION", "name": "PRODUCT OR SOLUTION (Trình bày sản phẩm hoặc giải pháp)" },
        ]
    });
    const handle_edit = (e) => {
        onEdit(e);
    }
    const handle_change = (e) => {
        const { name, value } = e.target;
        setState((prev) => ({ ...prev, [name]: value }));
    }
    useEffect(() => {
        var fromDate = "", toDate = "";
        if (from_dt != null) {
            fromDate = CommonHelper.formatToYYYYMMDD(from_dt);
        }
        if (to_dt != null) {
            toDate = CommonHelper.formatToYYYYMMDD(to_dt);
        }
        setState((prev) => ({
            ...prev,
            fromDate, toDate
        }))
    }, [from_dt, to_dt])
    useEffect(() => {
        if (is_show) {
            handle_submit()
        }
    }, [is_show])
    const handle_new = () => {
        onNew(null);
    }
    const handle_submit = () => {
        var request = { ...state };
        if (CommonHelper.is_empty(request.status)) {
            request.status = 2
        } else {
            request.status = CommonHelper.convertToInt(request.status);
        }
        dispatch(organizerActions.set_loading());
        console.log(request);
        var response = eventApi.get_list(request);
        response.then((res) => {
            if (res.code == 506) {
                //setCommonState((prev) => ({ ...prev, isLoading: true }));
                alert("Session is expired. Please sign in again!")
                const timer = setTimeout(() => {
                    window.location.href = "/admin/login"
                }, 4000);
                return () => clearTimeout(timer);
            } else {
                dispatch(organizerActions.set_loading());
                var lst_data = [];
                if (res.code == 200) {
                    for (var i = 0; i < res.data.length; i++) {
                        var item = res.data[i];
                        var _filter_type = eventTypes.filter(x => x.code == item.event_type)
                        if (_filter_type.length > 0) {
                            item["type_name"] = _filter_type[0].name
                        }
                        lst_data.push(item);
                    }
                    console.log(lst_data)
                }

                setList((prev) => ({
                    ...prev,
                    lst_result: lst_data,
                    lst_data
                }))
                /*if (res.code == 204) {
                    setCommonState((prev) => ({ ...prev, isSuccess: true, isError: false }));
                    setState((prev) => ({ ...prev, eventId: res.data.id }))
                } else {
                    setCommonState((prev) => ({ ...prev, isSuccess: false, isError: true }));
                }*/
            }
            /*if (res != null && res.code == 200) {
                var lst_data = [];
                for (var i = 0; i < res.data.length; i++) {
                    var item = res.data[i];
                    var _filter_type = eventTypes.filter(x => x.code == item.event_type)
                    if (_filter_type.length > 0) {
                        item["type_name"] = _filter_type[0].name
                    }
                    lst_data.push(item);
                }
                console.log(lst_data)
                setList((prev) => ({
                    ...prev,
                    lst_result: lst_data,
                    lst_data
                }))
            }
            dispatch(organizerActions.set_loading());*/
        })
    }
    return (
        <>
            <div className='p-3 min-h-96 bg-white'>
                <div className='breadscum w-full flex'>
                    <div className='w-1/2'>
                        <ul className='flex flex-wrap'>
                            <li className='text-sm '>
                                <Link to={"/"}><FontAwesomeIcon icon={faHome} /> </Link>
                            </li>
                            <li className='text-sm pl-2 leading-normal before:float-left before:pr-2 before:content-["/"]'>
                                <Link to={"/admin/event"}>Events </Link>
                            </li>
                            <li className='text-sm pl-2 leading-normal before:float-left before:pr-2 before:content-["/"]'>
                                <Link >Search </Link>
                            </li>
                        </ul>
                    </div>
                    <div className='w-1/2 text-right'>
                        <a onClick={() => { handle_edit(null) }} className='cursor-pointer'>
                            <FontAwesomeIcon icon={faTimes} />
                        </a>
                    </div>
                </div>
                <div className='mt-5'>
                    <div className='w-full block sm:flex'>
                        <div className='w-full mt-2 pe-2 sm:w-1/5'>
                            <SelectUnit onChange={handle_change} value={state.eventType} name='eventType'
                                label='Event type' required={false} isError={false} options={eventTypes} />
                        </div>
                        <div className='w-full mt-2 px-2 sm:w-1/5'>
                            <InputTextUnit onChange={handle_change} value={state.eventName} name='eventName'
                                label='Event name (en/vn)' required={false} isError={false} />
                        </div>
                        <div className='w-full mt-2 pe-2 sm:w-1/5'>
                            <SelectUnit onChange={handle_change} value={state.status} name='status'
                                label='Status' required={false} isError={false} options={lst_status} />
                        </div>
                        <div className='w-full mt-2 pe-2 sm:w-1/5'>
                            <label className='block font-semibold'>From date</label>
                            <DateInput
                                dateFormat={"dd/MM/yyyy"}
                                selected={from_dt}
                                onChange={(e) => { setList((prev) => ({ ...prev, from_dt: e, })) }} />
                        </div>
                        <div className='w-full mt-2 pe-2 sm:w-1/5'>
                            <label className='block font-semibold'>To date</label>
                            <DateInput
                                dateFormat={"dd/MM/yyyy"}
                                selected={to_dt}
                                onChange={(e) => { setList((prev) => ({ ...prev, to_dt: e })) }} />
                        </div>
                    </div>
                </div>
                <div className='w-full mt-3'>
                    <button type='button' className='mt-5 w-32 rounded-2xl text-white py-1 px-2 box-border text-lg'
                        style={{ background: "#fa6736", }} onClick={handle_submit}>
                        Search</button>
                    <button type='button' className='mt-5 ml-5 rounded-2xl text-white py-1 px-4 box-border text-lg bg-cyan-600'
                        onClick={handle_new}>
                        New Event </button>
                </div>
                <div className='mt-3 min-h-96'>
                    {lst_result.length > 0 && (
                        <>
                            <table className='border-collapse border border-slate-400 w-full'>
                                <thead>
                                    <tr>
                                        <th className='border border-slate-300 w-1/12 ps-2 text-center'>#</th>
                                        <th className='border border-slate-300 w-1/4 ps-2 text-center'>Event Name (VN)</th>
                                        <th className='border border-slate-300 w-1/6 ps-2 text-center'>Event Type</th>
                                        <th className='border border-slate-300 w-1/6 ps-2 text-center'>Location (VN)</th>
                                        <th className='border border-slate-300 w-1/12 ps-2 text-center'>Status</th>
                                        <th className='border border-slate-300 w-1/12'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {lst_result.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td className='border border-slate-300 w-8 pe-2 text-end'>
                                                    <div className='w-4/5 mx-auto'>{i + 1}</div>
                                                </td>
                                                <td className='border border-slate-300 ps-2'>{item.event_name_vn}</td>
                                                <td className='border border-slate-300 ps-2'>{item.type_name}</td>
                                                <td className='border border-slate-300 ps-2'>{item.location_vn}</td>
                                                <td className='border border-slate-300 ps-2 text-center'>
                                                    {item.activated && (
                                                        <FontAwesomeIcon icon={faCheck} className='text-green-700' />
                                                    )}
                                                    {!item.activated && (
                                                        <FontAwesomeIcon icon={faBan} className='text-red-700' />
                                                    )}
                                                </td>
                                                <td className='border border-slate-300 ps-2'>
                                                    <div className='flex'>
                                                        <a className='block flex-auto cursor-pointer text-center' onClick={() => { handle_edit(item) }}>
                                                            <FontAwesomeIcon icon={faPencil} />
                                                        </a>
                                                        {/* <a className='block flex-auto cursor-pointer text-center text-red-700'
                                                    onClick={() => { confirm_delete(item) }}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </a> */}
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </>
                    )}
                    {lst_result.length == 0 && (
                        <div className='mt-10 border py-10'>
                            <h2 className='text-red-500 font-semibold text-xl text-center'>NO DATA</h2>
                        </div>
                    )}

                </div>
            </div>
        </>
    )
}

AdminEventList.propTypes = {}

export default AdminEventList
